import { ITab, ITabItem } from "./tabs.interface";

const Tabs = ({
  items,
  onClick,
  activeIndex,
  className,
  opacity,
  tabFontSize,
}: ITab) => {
  return (
    <>
      <div className={`${className}`}>
        <div className="flex justify-start gap-4 py-2 overflow-x-auto sm:justify-center whitespace-nowrap">
          {items.map((item: ITabItem, index: number) => {
            return (
              <div
                key={index}
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  onClick(e, index)
                }
                className={`flex items-center gap-2 justify-between p-2 cursor-pointer ${
                  activeIndex === index
                    ? "border-b-green border-b-2 "
                    : `${opacity && "opacity-20"}`
                }`}
              >
                {item.icon && (
                  <img
                    alt="lazy"
                    loading="lazy"
                    src={item.icon}
                    className="object-contain object-center w-6 max-w-full aspect-square shrink-0"
                  />
                )}

                <div>
                  <span
                    className={` ${
                      tabFontSize ? tabFontSize : "text-base"
                    } self-center my-auto  font-semibold leading-4 tracking-wide text-center capitalize ${
                      activeIndex === index ? "text-black" : "text-gray"
                    }`}
                  >
                    {item.name}
                  </span>
                </div>
              </div>
            );
          })}

          {/* <div className="flex items-center justify-between gap-2 px-3 py-2 pr-10 opacity-20">
            <img
              alt="lazy"
              loading="lazy"
              src="/assets/icons/gift_pack.svg"
              className="object-contain object-center w-6 max-w-full overflow-hidden aspect-square shrink-0"
            />
            <div className="">
              <span className="self-center my-auto text-base font-semibold leading-4 tracking-wide text-center capitalize text-neutral-800">
                For Someone Else
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between gap-2 px-3 py-2 pr-10 opacity-20">
            <img
              alt="lazy"
              loading="lazy"
              src="/assets/icons/green_tick_full.svg"
              className="object-contain object-center w-6 max-w-full overflow-hidden aspect-square shrink-0"
            />
            <div>
              <span className="self-center my-auto text-base font-semibold leading-4 tracking-wide text-center capitalize text-neutral-800">
                Reedme A Code
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Tabs;
