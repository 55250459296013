import GlimpseCard from "../../components/card/card.component";
import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getPreviewSideData,
  getReprioritizeCareer,
} from "services/deeper-dive.service";
import CareerItem from "components/career-item/career-item.component";
import { useNavigate } from "react-router-dom";
import { unlockNextGlimpse } from "services/on-board";
import { displayError, displayWarning } from "components/Toast/toast.component";
import { useSelector } from "react-redux";
import { IShareCareerCount } from "interfaces/ShareCareerCount.interface";
import { updateSharedCareerCount } from "services/share_careers.service";

const UserPrioritize: React.FC = () => {
  const [enableShare, setEnableShare] = useState(false);
  const { userInfo } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  const { data: reprioritizeCareer } = useQuery({
    queryKey: ["reprioritizeCareer"],
    queryFn: () => getReprioritizeCareer(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const unlockOnBoard = useMutation({
    mutationKey: ["UnlockNextEight"],
    mutationFn: () => unlockNextGlimpse(),
    retry: 0,
    onSuccess: async (res) => {
      if (res.unlock_status) {
        navigate("/prioritize/unlock-next/start");
      } else {
        displayError(res.unlock_message);
      }
    },
    onError: (err: Error) => {
      console.error(err);
      displayWarning("Unable to unlock careers");
    },
  });

  const shareCareer = (isTopThree: boolean) => {
    let htmlContent = `${userInfo.user.full_name} just shared their Top ${isTopThree ? "3" : "10"
      } Glimpse Careers with you! \n \n`;
    let htmlTitle = "";
    if (isTopThree) {
      htmlTitle = `${userInfo.user.full_name} Shared Top 3 Glimpse`;
      htmlContent += "Top 3: \n ";
      reprioritizeCareer
        ?.filter((items) => items.category === "top_three")
        .forEach((x) => {
          htmlContent += `\n ${x.name}`;
        });
    } else {
      htmlTitle = `${userInfo.user.full_name} Shared Top 10 Glimpse`;
      htmlContent = "Top 10: \n ";
      reprioritizeCareer
        ?.filter(
          (items) =>
            items.category === "watch_list" || items.category === "top_three"
        )
        .forEach((x) => {
          htmlContent += `\n ${x.name}`;
        });
    }

    var encodedTitle = encodeURIComponent(htmlTitle);
    var encodedContent = encodeURIComponent(htmlContent);

    var mailtoLink =
      "mailto:?subject=" + encodedTitle + "&body=" + encodedContent;

    window.location.href = mailtoLink;
  };

  const shareCareerSubmit = useMutation({
    mutationKey: ["sharecareercount"],
    mutationFn: (data: IShareCareerCount) => updateSharedCareerCount(data),
  });

  const handleSubmitSharedCareer = (
    shared_top_three: boolean,
    shared_top_ten: boolean
  ) => {
    const payload = {
      shared_top_ten: shared_top_ten,
      shared_top_three: shared_top_three,
    };
    shareCareerSubmit.mutateAsync(payload);
    shared_top_ten ? shareCareer(false) : shareCareer(true);
  };

  const { data: previewData } = useQuery({
    queryKey: ["previewData"],
    queryFn: () => getPreviewSideData(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  return (
    <>
      <div className="block max-w-[610px] lg:flex lg:justify-between lg:pb-4">
        <div className="flex py-2 lg:py-0">
          <button
            type="button"
            onClick={() => {
              unlockOnBoard.mutateAsync();
            }}
            disabled={!previewData?.is_available_careers}
            className={`text-white text-center bg-green gap-3 w-full lg:w-[200px] h-[40px] rounded-full text-[16px] font-semibold leading-5 ${!previewData?.is_available_careers ? "opacity-60" : null
              }`}
          >
            <div className="first-letter:uppercase"><span>{previewData?.is_available_careers ? `${previewData?.is_available_careers} Next 8` : 'Unlock Next 8'}</span></div>
          </button>
        </div>

        <div className="flex justify-between pb-4 lg:pb-0 lg:gap-1">
          <button
            type="button"
            onClick={() => setEnableShare(!enableShare)}
            className="text-btn_outline text-center  gap-1  w-[168px] h-[40px] rounded-full text-[16px] font-normal leading-5 flex items-center justify-center border border-btn_outline"
          >
            <svg
              width={19}
              height={15}
              viewBox="0 0 19 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_488_69457)">
                <path
                  d="M18.6413 2.86435L14.3166 0.108318C13.8739 -0.173871 13.2795 0.128187 13.2795 0.634966V1.2719C13.249 1.27659 13.2183 1.28128 13.1876 1.28619C12.8183 1.34423 12.4494 1.41054 12.0811 1.48778C11.3448 1.64183 10.6113 1.83382 9.89031 2.07962C9.16976 2.32609 8.46095 2.62569 7.78521 2.99339C7.44734 3.17712 7.11816 3.37783 6.80188 3.59639C6.48583 3.81562 6.18269 4.05271 5.89738 4.307C5.32629 4.81578 4.83028 5.39758 4.43727 6.02268C4.0445 6.6489 3.75755 7.31642 3.56867 7.98974C3.3812 8.66463 3.28993 9.34443 3.26881 10.0128C3.25826 10.3473 3.26483 10.679 3.28594 11.0079C3.28618 11.0119 3.28641 11.0159 3.28688 11.0201C3.30471 11.2476 3.64563 11.2702 3.69795 11.0474C3.69866 11.0443 3.69936 11.0411 3.70006 11.038C3.77233 10.727 3.8575 10.4207 3.95745 10.122C4.15689 9.52459 4.41616 8.95686 4.7383 8.44294C5.0581 7.92812 5.43938 7.46756 5.8718 7.07776C6.3054 6.68863 6.78662 6.36849 7.30117 6.11555C7.55879 5.98919 7.82416 5.87913 8.09586 5.78358C8.36709 5.68736 8.64466 5.60565 8.92716 5.53733C9.49191 5.40048 10.0759 5.31654 10.6691 5.27345C11.2622 5.22991 11.8647 5.22612 12.4705 5.25358C12.7394 5.26563 13.0095 5.28483 13.28 5.30917V5.92579C13.28 6.43279 13.8743 6.73462 14.3171 6.45266L18.6418 3.69707C18.9534 3.4986 18.9534 3.0626 18.6418 2.86413L18.6413 2.86435Z"
                  fill="#56686F"
                />
                <path
                  d="M13.7861 7.66205C13.3265 7.66205 12.9536 8.01657 12.9536 8.45414V12.5376C12.9536 13.0219 12.5395 13.4159 12.0306 13.4159H2.71274C2.20382 13.4159 1.7897 13.0219 1.7897 12.5376V3.67144C1.7897 3.18721 2.20382 2.79317 2.71274 2.79317H5.89456C6.3542 2.79317 6.72702 2.43865 6.72702 2.00108C6.72702 1.56351 6.35443 1.20898 5.89456 1.20898H2.71274C1.28595 1.20921 0.125 2.31385 0.125 3.67144V12.5376C0.125 13.8952 1.28595 14.9999 2.71274 14.9999H12.0308C13.4576 14.9999 14.6186 13.8952 14.6186 12.5376V8.45414C14.6186 8.0168 14.246 7.66205 13.7861 7.66205Z"
                  fill="#56686F"
                />
              </g>
              <defs>
                <clipPath id="clip0_488_69457">
                  <rect
                    width="18.75"
                    height={15}
                    fill="white"
                    transform="translate(0.125)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span className="font-semibold">Share</span>
          </button>

          <button
            type="button"
            onClick={() => {
              navigate("/prioritize/reprioritize");
            }}
            className="text-btn_outline text-center gap-3 w-[168px] h-[40px] rounded-full text-[16px] font-semibold leading-5 flex items-center justify-center border border-btn_outline lg:bg-green lg:text-white lg:border-0"
          >
            Reprioritize
          </button>
        </div>
      </div>

      {enableShare && (
        <div className="absolute p-1 z-10 top-[170px] left-[40px] sm:left-[300px] sm:top-[200px] lg:left-[594px] lg:top-[140px] bg-white rounded-lg shadow-card w-[130px] ">
          <div
            className="px-4 py-2 hover:bg-overlay_hover  text-[12px]  cursor-pointer h-[40px]"
            onClick={() => {
              // setShareCareerCountTopThree(true);
              handleSubmitSharedCareer(true, false);
              // shareCareer(true);
            }}
          >
            Share Top 3
          </div>

          <div
            className="px-4 py-2 hover:bg-overlay_hover  text-[12px]  cursor-pointer  h-[40px]"
            onClick={() => {
              handleSubmitSharedCareer(false, true);

              // shareCareer(false);
            }}
          >
            Share Top 10
          </div>
        </div>
      )}
      <div className=" max-w-[610px] p-1">
        <GlimpseCard
          title="Top 3"
          subtitle="Highest potential for my future"
          subtitleClass="text-[12px]"
          className="min-w-full h-[215px] gap-3 "
        >
          <></>
          <div className="flex flex-wrap items-center justify-center gap-3 sm:gap-5 lg:gap-7 ">
            {reprioritizeCareer?.filter(
              (items) => items.category === "top_three"
            ).length === 0 ? (
              "This category is empty."
            ) : (
              <>
                {reprioritizeCareer
                  ?.filter((items) => items.category === "top_three")
                  .map((item: any) => {
                    return (
                      <div key={item.id}>
                        <CareerItem
                          id={item.id}
                          icon_url={item.icon_url}
                          status={item.status}
                          name={item.name}
                          showRanking={true}
                          rank={item.order}
                          pressed={() => { }}
                        />
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </GlimpseCard>
      </div>
      <div className="flex flex-row gap-5 mt-5 max-w-[615px] overflow-auto p-1 h-[361px]">
        <GlimpseCard
          title="Watch List"
          subtitle="Have some potential for my future"
          subtitleClass="text-[12px]"
          className="min-w-[292px] h-[361px] overflow-auto"
        >
          <></>
          <div className="flex flex-wrap items-center justify-center gap-2">
            {reprioritizeCareer?.filter(
              (items) => items.category === "watch_list"
            ).length === 0 ? (
              "There are no watch list careers."
            ) : (
              <>
                {reprioritizeCareer
                  ?.filter((items) => items.category === "watch_list")
                  .map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="flex relative bg-widgetBlue rounded-lg min-w-full h-[33px] p-1 text-white"
                      >
                        <span className="absolute -top-1 -left-1 rounded-full bg-red w-[15px] h-[15px] text-center text-white font-normal text-[10px]">
                          {item.order}
                        </span>
                        <div className="flex items-center w-[262px] gap-2">
                          <img
                            src={item.icon_url}
                            alt="career_icon"
                            className="w-[24px] h-[25px]"
                          />
                          <span>{item.name}</span>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </GlimpseCard>
        <GlimpseCard
          title="Discard"
          subtitle="Not in my future"
          subtitleClass="text-[12px]"
          className="min-w-[292px] h-[361px] overflow-auto"
        >
          <></>
          <div className="flex flex-wrap items-center justify-center gap-2">
            {reprioritizeCareer?.filter((items) => items.category === "discard")
              .length === 0 ? (
              "There are no discarded careers."
            ) : (
              <>
                {reprioritizeCareer
                  ?.filter((items) => items.category === "discard")
                  .map((item) => {
                    return (
                      <div
                        key={item.id}
                        className="flex relative bg-widgetBlue rounded-lg min-w-full h-[33px] p-1 text-white"
                      >
                        <div className="flex items-center w-[262px] gap-2">
                          <img
                            src={item.icon_url}
                            alt="career_icon"
                            className="w-[24px] h-[25px]"
                          />
                          <span>{item.name}</span>
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        </GlimpseCard>
      </div>
    </>
  );
};

export default UserPrioritize;
