import GlimpseInputBox from "components/input/formik-input";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  IResetPasswordMember,
  IResetPasswordMemberPayload,
  IResetPasswordMemberProps,
} from "interfaces/resetPassword.interface";

import {
  displayError,
  displaySuccess,
  displayWarning,
} from "components/Toast/toast.component";
import { useMutation } from "@tanstack/react-query";
import { resetMemberPassword } from "services/resetPassword.service";
import { passwordRegex } from "constants/regex";

const initialValues = {
  admin_password: "",
  password: "",
  password_confirmation: "",
};

const ResetPasswordMember: React.FC<IResetPasswordMemberProps> = ({
  memberInfo,
  onClose,
}) => {
  const passwordValidationSchema = Yup.object({
    admin_password: Yup.string().required("Admin Password is required..!"),
    password: Yup.string()
      .matches(
        passwordRegex,
        "Password should be minimum 8 characters with at least 1 number and 1 letter."
      )
      .required("New Password is required..!"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm New Password is required..!"),
  });
  const handleChangePasswordMember = useMutation({
    mutationFn: (data: IResetPasswordMemberPayload) =>
      resetMemberPassword(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Password changed successfully.");
      onClose();
    },
    onError: () => {
      displayError("Password change failed");
    },
  });

  const handlePasswordSubmit = (values: IResetPasswordMember) => {
    if (values.password === values.password_confirmation) {
      const payload = { id: memberInfo.id, data: values };
      handleChangePasswordMember.mutateAsync(payload);
    } else {
      displayWarning("Password must be same");
    }
  };

  return (
    <div className="">
      <div className="py-4">
        If you reset
        <span className="px-1 font-bold ">
          {`${memberInfo.member ? memberInfo.member.name : "Member"}`}
          's{" "}
        </span>
        password, they will be automatically logged out on all devices.
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={passwordValidationSchema}
        onSubmit={handlePasswordSubmit}
      >
        {({ isSubmitting, setFieldValue, values, errors }) => (
          <Form>
            <GlimpseInputBox
              type="password"
              id="admin_password"
              label="Your Password"
              as="input"
              name="admin_password"
              placeholder="Your Password"
              isPassword={true}
              maxLength={50}
            />
            <GlimpseInputBox
              type="password"
              id="password"
              label="New Password"
              as="input"
              name="password"
              placeholder="New Password"
              isPassword={true}
              maxLength={50}
            />
            <GlimpseInputBox
              type="password"
              id="password_confirmation"
              label="Confirm New Password"
              as="input"
              name="password_confirmation"
              placeholder="Confirm New Password"
              isPassword={true}
              maxLength={50}
            />

            <div className="flex justify-between mt-5">
              <button
                type="button"
                onClick={onClose}
                className="text-headerBlue text-center bg-white gap-3 w-[147px] h-[32px] rounded-full text-sm font-normal leading-5 border border-headerBlue"
              >
                Close
              </button>
              <button
                type="submit"
                className="text-white text-center bg-green gap-3 w-[147px] h-[32px] rounded-full text-sm font-normal leading-5"
              >
                Confirm
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordMember;
