import { useSelector } from "react-redux";
import GlimpseCard from "../../components/card/card.component";
import GlimpseCheckbox from "../../components/toggle/toggle.component";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { changePassword } from "services/auth.service";
import {
  displayError,
  displaySuccess,
  displayWarning,
} from "components/Toast/toast.component";
import { useEffect, useState } from "react";
import { IChangePassword, IModuleQuiz } from "interfaces/user.interface";
import { Formik, Form, FormikHelpers } from "formik";
import GlimpseInputBox from "components/input/formik-input";
import * as Yup from "yup";

import EndUserData from "./userData.setting";
import PartnerDataSetting from "./PartnerData.setting";
import { getPartnerData, updateModuleQuiz } from "services/setting.service";
import { passwordRegex } from "constants/regex";

const initialValues = {
  old_password: "",
  new_password: "",
  confirm_password: "",
};

const SettingsScreen: React.FC = () => {
  const { userInfo } = useSelector((state: any) => state.auth);
  const [passwords, setPasswords] = useState<IChangePassword>(initialValues);
  const [moduleQuiz, setModuleQuiz] = useState<boolean>(false);

  const { data: endUserInfo, refetch } = useQuery({
    queryKey: ["getEndUserData"],
    queryFn: getPartnerData,
    refetchOnWindowFocus: false,
    retry: 0,
    enabled: false,
  });

  const reasonsForPackagePurchase =
    userInfo?.user?.subscription?.subscription_obj?.reasons.split(",");

  useEffect(() => {
    if (endUserInfo && endUserInfo.role === "partner") {
      setModuleQuiz(endUserInfo.profile.model_quiz);
    }
  }, [endUserInfo]);

  useEffect(() => {
    if (userInfo && userInfo.role === "partner") {
      refetch();
    }
  }, [userInfo]);

  // validationSchemas
  const passwordValidationSchema = Yup.object({
    old_password: Yup.string().required("Old Password is required..!"),
    new_password: Yup.string()
      .matches(
        passwordRegex,
        "Password should be minimum 8 characters with at least 1 number and 1 letter."
      )
      .required("New Password is required..!"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password")], "Passwords must match")
      .required("Confirm New Password is required..!"),
  });

  // handle password change
  const handleChangePassword = useMutation({
    mutationFn: (data: IChangePassword) => changePassword(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Password changed successfully.");
      setPasswords(initialValues);
    },
    onError: () => {
      displayError("Password change failed");
    },
  });

  const handlePasswordSubmit = (
    values: IChangePassword,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    setSubmitting(false);
    if (values.new_password === values.confirm_password) {
      handleChangePassword.mutateAsync(values);
    } else {
      displayWarning("Password must be same");
    }
  };

  // handle module quiz
  const handlemoduleQuiz = useMutation({
    mutationFn: (data: IModuleQuiz) => updateModuleQuiz(data),
    retry: 0,
    onSuccess: (data) => {
      displaySuccess("Module quiz updated successfully.");

      setModuleQuiz(data.model_quiz);
    },
    onError: () => {
      displayError("Could not update module quiz ");
    },
  });

  const handleModuleQuizSubmit = (values: boolean) => {
    const payload = { model_quiz: values };
    handlemoduleQuiz.mutateAsync(payload);
  };

  return (
    <div className="block">
      {userInfo.user.role !== "super" && (
        <>
          <div className="pl-3 text-sm">
            View or update your account information.
          </div>
          <div className="max-w-[343px] min-h-[308px] pb-10 ">
            <GlimpseCard
              title="General Info"
              className="border-b rounded-none shadow-none border-horizontalRow"
            >
              {userInfo.user.role === "user" && <EndUserData />}
              {userInfo && userInfo.user.role === "partner" && (
                <PartnerDataSetting />
              )}
            </GlimpseCard>
          </div>
        </>
      )}

      <div
        className="max-w-[343px] min-h-[369px] md:max-w-[553px]
      pb-10"
      >
        <GlimpseCard
          title="Password"
          className="border-b rounded-none shadow-none border-horizontalRow"
        >
          <div className="py-1 text-sm">
            To reset your password, provide your current password and choose a
            new password.
          </div>
          <div className="py-4 ">
            <div className="flex text-start">
              <div className="w-[300px]">
                <Formik
                  initialValues={passwords}
                  validationSchema={passwordValidationSchema}
                  onSubmit={handlePasswordSubmit}
                >
                  {({ isSubmitting, setFieldValue, values, errors }) => (
                    <Form>
                      <GlimpseInputBox
                        type="password"
                        id="old_password"
                        label="Password"
                        as="input"
                        name="old_password"
                        placeholder="Password"
                        isPassword={true}
                        maxLength={50}
                      />
                      <GlimpseInputBox
                        type="password"
                        id="new_password"
                        label="New Password"
                        as="input"
                        name="new_password"
                        placeholder="Password"
                        isPassword={true}
                        maxLength={50}
                      />
                      <GlimpseInputBox
                        type="password"
                        id="confirm_password"
                        label="Confirm New Password"
                        as="input"
                        name="confirm_password"
                        placeholder="Password"
                        isPassword={true}
                        maxLength={50}
                      />

                      <div className="flex justify-between mt-5">
                        <button
                          type="submit"
                          className="text-white text-center bg-green gap-3 w-[147px] h-[32px] rounded-full text-sm font-normal leading-5"
                        >
                          Change Password
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </GlimpseCard>
      </div>
      {userInfo.user.role === "partner" && (
        <div className="max-w-[343px] min-h-[163px] md:max-w-[553px] pb-10">
          <GlimpseCard
            title="Module Quiz"
            className="border-b rounded-none shadow-none border-horizontalRow"
          >
            <div className="py-1 text-sm">
              Module quizzes are used at the end of career module to test
              members knowledge of the material covered.
              <span className="font-bold">
                This action is applied to all members associated with your
                account.
              </span>
            </div>
            <GlimpseCheckbox
              checked={moduleQuiz}
              label={`${moduleQuiz ? "On" : "Off"} `}
              onChange={(ev) => {
                handleModuleQuizSubmit(ev.target.checked);
              }}
            />
          </GlimpseCard>
        </div>
      )}
      {userInfo.user.role !== "super" && (
        <>
          {userInfo.user.role === "user" &&
            userInfo.user.subscription.gift_by === null && (
              <div className="max-w-[343px] min-h-[163px] pb-10">
                <GlimpseCard
                  title="My Plan"
                  className="border-b rounded-none shadow-none border-horizontalRow"
                >
                  <div className="py-1 text-sm">
                    View your plan details below.
                  </div>
                  <div className="flex flex-col py-4 text-md">
                    <div className="font-bold">
                      {userInfo.user.subscription.subscription_obj.type}
                    </div>
                    <span className="">
                      ${userInfo.user.subscription.subscription_obj.amount}
                    </span>
                  </div>
                  {reasonsForPackagePurchase && (
                    <ul className="pb-4">
                      {reasonsForPackagePurchase.map(
                        (item: string, index: number) => {
                          return (
                            <li
                              key={index}
                              className="flex items-center gap-2 "
                            >
                              <div>
                                <svg
                                  width="9"
                                  height="8"
                                  viewBox="0 0 9 8"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.45101 1.68765L4.30963 7.30771C4.21086 7.43875 4.06358 7.52461 3.90089 7.546C3.73819 7.56739 3.57373 7.52251 3.44444 7.42145L0.487093 5.05702C0.226126 4.84818 0.183869 4.46733 0.392709 4.20636C0.60155 3.94539 0.982404 3.90314 1.24337 4.11198L3.70944 6.08496L7.47692 0.97191C7.60046 0.786525 7.81589 0.684029 8.03766 0.705117C8.25944 0.726205 8.45169 0.867464 8.53807 1.07281C8.62446 1.27816 8.59101 1.51436 8.45101 1.68765Z"
                                    fill="black"
                                  />
                                </svg>
                              </div>
                              <div> {item}</div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                </GlimpseCard>
              </div>
            )}
          {userInfo.user.role === "partner" && (
            <GlimpseCard
              title="My Plan"
              className="border-b rounded-none shadow-none border-horizontalRow"
            >
              <div className="py-1 text-sm">
                Manage your plan details below.
              </div>
              <div className="py-4 text-lg">
                <div className="text-[12px]">Membership Licenses</div>
                {userInfo.user.profile.total_licenses}
                <div className="flex pt-4">
                  <a href={`mailto:blake@glimpse.org`}>
                    <button
                      type="button"
                      className="text-white text-center bg-green gap-3 h-[32px] w-[130px] rounded-full text-sm font-normal leading-5"
                    >
                      Add Licenses
                    </button>
                  </a>
                </div>
              </div>
            </GlimpseCard>
          )}
        </>
      )}
      {userInfo.user.role === "user" && (
        <div className="max-w-[343px] min-h-[308px] pb-10">
          <GlimpseCard
            title="Access"
            className="border-b rounded-none shadow-none border-horizontalRow"
          >
            <span className="py-1 text-sm">
              Your membership includes 1 year access to Glimpse. Your account
              will not auto-renew, but you may choose to renew your membership
              at that time.
            </span>
            {userInfo.user.subscription.gift_by && (
              <div> Gifted By: {userInfo.user.subscription.gift_by}</div>
            )}
            <div className="py-4 text-sm">
              <div className="text-sm ">
                Expires:&nbsp;
                {new Date(
                  userInfo.user.subscription.expiry_date
                ).toLocaleDateString("en-US")}
              </div>
            </div>
          </GlimpseCard>
        </div>
      )}
    </div>
  );
};

export default SettingsScreen;
