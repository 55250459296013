import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import GlimpseInputBox from "components/input/formik-input";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { displayError, displaySuccess } from "components/Toast/toast.component";
import { resetPassword } from "services/auth.service";
import { IPassReset, IPasswordReset } from "interfaces/resetPassword.interface";
import { passwordRegex } from "constants/regex";

const initialValues = {
  password: "",
  confirm_password: "",
};

const ResetPasswordScreen: React.FC = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();

  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(
        passwordRegex,
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      )
      .required("Password is required..!"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Password confirmation is required..!"),
  });

  const handleResetPassword = useMutation({
    mutationFn: (data: IPasswordReset) => resetPassword(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Password reset has been successful.");
      setIsEmailSent(true);
    },
    onError: () => {
      displayError("Unable to reset password.");
    },
  });

  const handleResetPasswordSubmit = (data: IPassReset) => {
    const tokenPart = search.split("=")[1];
    const token = tokenPart.split("&uid")[0];
    const uid = search.split("=")[2];

    const payload = {
      token: token,
      uid: uid,
      password: data.password,
      confirm_password: data.confirm_password,
    };

    handleResetPassword.mutateAsync(payload);
  };

  return (
    <div className="flex flex-col lg:px-8 h-[100%] bg-background">
      <div className="w-full h-full">
        <div className="mx-auto w-4/5 max-w-[600px]">
          {isEmailSent ? (
            <>
              <h2 className="mt-5 text-4xl font-bold leading-9 tracking-wider text-center text-headerBlue">
                Thank You!
              </h2>
              <p className="my-5 text-2xl font-bold tracking-wide text-center">
                Your password has been reset. Please return to the login screen
                to sign in.
              </p>
            </>
          ) : (
            <>
              <h2 className="mt-5 text-4xl font-bold leading-9 tracking-wider text-center text-headerBlue">
                Forgot Password?
              </h2>
              <p className="mt-5 text-2xl font-bold tracking-wide text-center">
                Please provide the email associated with your account below.
              </p>
            </>
          )}
        </div>
        <div className="flex justify-center mt-5">
          {isEmailSent ? (
            <div className="px-4 w-[300px]">
              {isEmailSent && (
                <div className="flex justify-center">
                  <span
                    className="underline cursor-pointer text-green underline-offset-2"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      navigate("/login")
                    }
                  >
                    Back to Login
                  </span>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="px-0 py-8 bg-white shadow-lg rounded-2xl">
                <div className="flex flex-col min-w-[300px] px-4 ">
                  <span className="flex justify-center gap-3 mx-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="79"
                      height="85"
                      viewBox="0 0 79 85"
                      fill="none"
                    >
                      <path
                        d="M1.24515 10.3971C0.994894 12.9264 0.861328 15.4908 0.861328 18.0862C0.861328 20.6816 0.994894 23.2461 1.24515 25.7754C3.48063 48.417 15.4988 68.1721 33.0043 80.7948C35.0936 82.302 37.2616 83.7051 39.4999 85C41.7241 83.7121 43.8766 82.3202 45.9532 80.8257C63.4827 68.2044 75.5163 48.4367 77.7546 25.7754C78.0048 23.2461 78.1384 20.6816 78.1384 18.0862C78.1384 15.4908 78.0048 12.9264 77.7546 10.3971C77.7546 10.3802 77.7518 10.3619 77.7518 10.345C63.8243 10.2761 50.7391 6.51099 39.4802 0C28.2227 6.5124 15.1768 10.2761 1.24797 10.345C1.24797 10.3619 1.24515 10.3802 1.24515 10.3971ZM5.35899 14.6374C17.2956 13.978 28.972 10.7022 39.4816 5.05442C49.9953 10.7022 61.6859 13.978 73.6407 14.6374C73.6956 15.7959 73.7237 16.9474 73.7237 18.0862C73.7237 20.4679 73.6014 22.9087 73.3609 25.3424C71.3111 46.0803 60.3811 64.9974 43.3733 77.2433C42.1178 78.1474 40.8201 79.0176 39.4985 79.8472C38.1614 79.0092 36.851 78.1277 35.5843 77.2152C18.6003 64.9651 7.68444 46.0592 5.63877 25.341C5.39835 22.9087 5.27604 20.4679 5.27604 18.0848C5.27604 16.946 5.30415 15.7931 5.35899 14.6346V14.6374Z"
                        fill="#60849F"
                      />
                      <path
                        d="M29.0035 31.6832H50.0001C52.3016 31.6832 54.1716 33.5517 54.1716 35.8547V52.6657C54.1716 54.9687 52.303 56.8372 50.0001 56.8372H29.0035C26.702 56.8372 24.832 54.9687 24.832 52.6657V35.8547C24.832 33.5517 26.7005 31.6832 29.0035 31.6832Z"
                        fill="#60849F"
                      />
                      <path
                        d="M49.9166 31.6832H45.5019V26.3195C45.5019 23.0099 42.8095 20.3189 39.5013 20.3189C36.1931 20.3189 33.5006 23.0113 33.5006 26.3195V31.6832H29.0859V26.3195C29.0859 20.5762 33.7579 15.9042 39.5013 15.9042C45.2446 15.9042 49.9166 20.5762 49.9166 26.3195V31.6832Z"
                        fill="#60849F"
                      />
                      <path
                        d="M39.5009 50.4781C38.5871 50.4781 37.8447 49.7371 37.8447 48.8233V43.0293C37.8447 42.1154 38.5857 41.3731 39.5009 41.3731C40.4162 41.3731 41.1558 42.114 41.1558 43.0293V48.8233C41.1558 49.7371 40.4148 50.4781 39.5009 50.4781Z"
                        fill="white"
                      />
                      <path
                        d="M42.8115 41.3042C42.8115 43.1333 41.3296 44.6152 39.5005 44.6152C37.6713 44.6152 36.1895 43.1333 36.1895 41.3042C36.1895 39.475 37.6713 37.9932 39.5005 37.9932C41.3296 37.9932 42.8115 39.475 42.8115 41.3042Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleResetPasswordSubmit}
                  >
                    {({}) => (
                      <Form autoComplete="off">
                        <div>
                          <GlimpseInputBox
                            type="text"
                            label="Password"
                            as="input"
                            id="password"
                            name="password"
                            placeholder="Password"
                            maxLength={50}
                          />
                        </div>
                        <div className="pt-4">
                          <GlimpseInputBox
                            type="text"
                            label="Confirm Password"
                            as="input"
                            id="confirm_password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            maxLength={50}
                          />
                        </div>

                        <button
                          type="submit"
                          className="text-white w-full text-center text-base font-semibold leading-4 tracking-wide capitalize whitespace-nowrap justify-center items-center bg-green self-stretch mt-8 py-3 rounded-[100px] max-md:px-5"
                        >
                          Reset Password
                        </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </>
          )}
        </div>
        {!isEmailSent && (
          <div className="flex justify-center mt-5">
            <p className="text-sm font-normal">
              <span>Know your password? </span>
              <span
                className="underline cursor-pointer text-green underline-offset-2"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                  navigate("/login")
                }
              >
                Login
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordScreen;
