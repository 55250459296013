import {
  FileType,
  IMediaUploadResponse,
} from "interfaces/MediaPickerInterface";
import { CareerDetailsInfo, CareerInfo, ICareerListItem, ICareerPrioritize } from "../interfaces/CareerInterface";
import { CAREER_ENDPOINT, FILE_ENDPOINT, PRIORITIZE_CAREER } from "./endpoint-urls";
import axios from "./http-service";

export const getAllCareer = (careerStatus: string) => {
  return axios
    .get<ICareerListItem[]>(`${CAREER_ENDPOINT}?status=${careerStatus}`)
    .then((res) => res.data);
};

export const prioritizeCareer = (data: ICareerPrioritize) => {
  return axios.post<any>(`${PRIORITIZE_CAREER}`, data).then((res) => res.data);
}

const formDataHeader = {
  "Content-Type": "multipart/form-data",
};

export const createCareer = (data: CareerInfo) => {
  // const formDataObject = jsonToFormData(data)
  return axios
    .post(CAREER_ENDPOINT, data, {
      headers: formDataHeader,
    })
    .then((res) => res.data);
};

export const updateCareer = (data: CareerInfo) => {
  return axios
    .put(`${CAREER_ENDPOINT}/${data.id}`, data, {
      headers: formDataHeader,
    })
    .then((res) => res.data);
};

export const archiveCareer = (data: CareerDetailsInfo) => {
  return axios
    .post(`${CAREER_ENDPOINT}/${data.id}/archive`, data, {
      headers: formDataHeader,
    })
    .then((res) => res.data);
};

export const getCareerById = (id: string) => {
  return axios
    .get<CareerDetailsInfo>(`${CAREER_ENDPOINT}/${id}`)
    .then((res) => res.data);
};

export const getCareerForEdit = (id: string) => {
  return axios
    .get<CareerInfo>(`${CAREER_ENDPOINT}/${id}/detail`)
    .then((res) => res.data);
};

export const uploadMedia = (data: FileType) => {
  return axios
    .post<IMediaUploadResponse>(FILE_ENDPOINT, data, {
      headers: formDataHeader,
    })
    .then((res) => res.data);
};

export const updateMedia = (data: FileType, mediaId: string) => {
  return axios
    .patch<IMediaUploadResponse>(`${FILE_ENDPOINT}/${mediaId}`, data, {
      headers: formDataHeader,
    })
    .then((res) => res.data);
};
