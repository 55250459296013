import React, { useState, useEffect, useRef, ReactNode, Children } from "react";

interface DropdownItem {
  label: string;
  onClick: () => void;
}

interface DropdownProps {
  buttonText: string;
  svg?: ReactNode;
  items: DropdownItem[];
  tableComponent: boolean;
  className?: string;
  onOpened: (is_Open: boolean) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  buttonText,
  items,
  svg,
  tableComponent,
  className,
  onOpened
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
    onOpened(true)
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
    onOpened(false)
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="inline-block text-left" ref={dropdownRef}>
      <button
        id="dropdownDefaultButton"
        onClick={toggleDropdown}
        className="text-center  flex justify-center items-center gap-3 w-[44px] h-[32px] rounded-full text-sm font-normal leading-5"
        type="button"
      >
        {buttonText === "" ? (
          <>{svg}</>
        ) : (
          <>
            {buttonText}
            <svg
              className="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </>
        )}
      </button>

      {isDropdownOpen && (
        <div
          id="dropdown"
          className={`${tableComponent
            ? "right-[45px] top-0 shadow-card rounded-lg"
            : "top-[295px] left-[74px] sm:left-[350px] sm:top-[320px]"
            } absolute p-1  bg-white rounded-lg shadow-card min-w-[160px] z-20 ${className} `}
        >
          <ul
            className="py-2 text-sm text-gray-700 "
            aria-labelledby="dropdownDefaultButton"
          >
            {items.map((item, index) => (
              <li
                key={index}
                className=" p-1 last:border-t last:border-horizontalRow last:text-red hover:bg-lightBlue  text-[12px]  cursor-pointer"
              >
                <button
                  onClick={() => {
                    item.onClick();
                    setDropdownOpen(false);
                  }}
                  className={` w-full px-4 py-[2px] text-left hover:bg-gray-100`}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
