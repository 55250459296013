import CareerItem from "../../components/career-item/career-item.component";
import { useNavigate } from "react-router-dom";
import { getAllCareer } from "../../services/career.service";
import { useQuery } from "@tanstack/react-query";
import Loading from "components/loading/loading.component";
import { ITabItem } from "components/tabs/tabs.interface";
import Tabs from "components/tabs/tabs.component";
import { useState } from "react";
import { ICareerListItem } from "interfaces/CareerInterface";
import CareerDragDrop from "./career-dragdrop";

const tabsList: ITabItem[] = [
  { name: "Published" },
  { name: "Draft" },
  { name: "Archive" },
];

const CareersScreen: React.FC = () => {
  const navigation = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const careerStatus =
    activeTab === 0 ? "published" : activeTab === 1 ? "draft" : "archive";

  const {
    data: careersList,
    status,
    error,
  } = useQuery({
    queryKey: ["getAllCareer", careerStatus],
    queryFn: () => getAllCareer(careerStatus),
    enabled: !!careerStatus,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const tabChanged = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="space-y-7">
      <div className="flex flex-row items-end justify-between">
        <div className="w-full overflow-auto ">
          <Tabs
            items={tabsList}
            onClick={tabChanged}
            activeIndex={activeTab}
            className="inline-table"
            tabFontSize="text-sm"
            opacity={false}
          />
        </div>
        <button
          type="button"
          onClick={(e) => navigation("/careers/details")}
          className="text-white text-center bg-green gap-3 pl-1.5 pt-2.5 pr-1.5 pb-2.5 w-[121px] rounded-full text-sm font-normal leading-5"
        >
          Add Career
        </button>
      </div>
      {activeTab === 0 && (
        <div>
          {status === "pending" && <Loading />}
          {careersList && (
            <CareerDragDrop
              datas={careersList}
              onClick={(data) =>
                navigation("/careers/career-insights/" + data.id)
              }
            />
          )}
        </div>
      )}
      {activeTab === 1 && (
        <div>
          <div className="flex flex-row items-end justify-between">
            <span className="text-xl font-bold leading-6 text-headerBlue">
              Draft Careers
            </span>
          </div>

          <div className="flex flex-wrap gap-2 mt-4 md:gap-4">
            {status === "pending" && <Loading />}
            {careersList && careersList.length === 0 && (
              <span>There are no draft careers</span>
            )}
            {careersList &&
              careersList.map((item: ICareerListItem) => {
                return (
                  <CareerItem
                    key={item.id}
                    id={item.id}
                    icon_url={item.icon_url}
                    status={item.status}
                    name={item.name}
                    pressed={(id: string) => {
                      navigation("/careers/career-insights/" + id);
                    }}
                  />
                );
              })}
          </div>
        </div>
      )}
      {activeTab === 2 && (
        <div>
          <div className="flex flex-row items-end justify-between">
            <span className="text-xl font-bold leading-6 text-headerBlue">
              Archived Careers
            </span>
          </div>

          <div className="flex flex-wrap gap-2 mt-4 md:gap-4">
            {status === "pending" && <Loading />}
            {careersList && careersList.length === 0 && (
              <span>There are no archived careers</span>
            )}
            {careersList &&
              careersList.map((item: ICareerListItem) => {
                return (
                  <CareerItem
                    key={item.id}
                    id={item.id}
                    icon_url={item.icon_url}
                    status={item.status}
                    name={item.name}
                    pressed={(id: string) => {
                      navigation("/careers/career-insights/" + id);
                    }}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default CareersScreen;
