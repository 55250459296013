function PublicHeader() {
  return (
    <>
      <nav className="flex sm:p-5 h-[90px] bg-lightBlue">
        <div id="glimpse-header" className="flex items-center justify-start">
          <img
            className="min-h-[35px] me-3 px-4 sm:min-h-[40px]"
            src="/assets/images/GlimpseLogo_White.svg"
            alt="Glimpse"
          />
        </div>
      </nav>
    </>
  );
}

export default PublicHeader;
