import React, { useState, useRef, useEffect, ReactNode } from "react";

interface PopoverProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  button: ReactNode | string;
}

const Popover: React.FC<PopoverProps> = ({
  button,
  children,
  isOpen,
  setIsOpen,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left ">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-sm font-medium text-black rounded-md "
      >
        {button}
      </button>
      {isOpen && (
        <div
          ref={popoverRef}
          className="absolute right-0 z-50 mt-2 origin-top-right bg-white rounded-md shadow-card w-[180px] min-h-[133px] px-4"
        >
          <div className="py-2">{children}</div>
        </div>
      )}
    </div>
  );
};

export default Popover;
