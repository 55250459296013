import React, { lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "./App";
import ProtectedRoutes from "layout/protected.layout";
import DefaultLayout from "layout/default.layout";
import CareerInfoScreen from "screens/career-info/career-info.screen";
import {
  // CareerFormScreen,
  // CareersScreen,
  // DashboardScreen,
  // ForgotPasswordScreen,
  // HelpCenterScreen,
  // IndividualScreen,
  // LoginScreen,
  // NotFound,
  // PartnerScreen,
  UserOnBoardComplete,
  // RegisterScreen,
  // SettingsScreen,
  ProfileScreen,
  UserOnBoard,
  UserOnBoardVideo,
  PartnerDashboard,
  PartnerCareers,
  AdminDashboardScreen,
} from "screens";
import UserCareersScreen from "screens/careers/user-career.screen";
import PartnerInsightScreen from "screens/partners/partner-insight.screen";
import IndividualInsightScreen from "screens/individual/individual-insight.screen";
import DeeperDive from "screens/deeper-dive/deeper-dive.screen";
import GlimpseRankings from "screens/rankings/rankings.screen";
import UserPrioritize from "screens/user-prioritize/user-prioritize.screen";
import CoachesCorner from "screens/coaches-corner/coaches-corner.screen";
import ResetPasswordScreen from "screens/reset-password/reset-password";

const DashboardScreen = lazy(
  () => import("screens/dashboard/dashboard.screen")
);
const CareerFormScreen = lazy(
  () => import("screens/careers/career-form.screen")
);
const PartnerScreen = lazy(() => import("screens/partners/partners.screen"));
const IndividualScreen = lazy(
  () => import("screens/individual/individual.screen")
);
const SettingsScreen = lazy(() => import("screens/settings/settings.screen"));
const PromoCodeScreen = lazy(() => import("screens/promo-codes/promo-codes.screen"));

const HelpCenterScreen = lazy(
  () => import("screens/help-center/help-center.screen")
);
const LoginScreen = lazy(() => import("screens/login/login.screen"));
const RegisterScreen = lazy(() => import("screens/register/register.screen"));
const ForgotPasswordScreen = lazy(
  () => import("screens/forgot-password/forgot-password.screen")
);
const CareersScreen = lazy(() => import("screens/careers/careers.screen"));
const NotFound = lazy(() => import("screens/NotFound"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="" element={<DefaultLayout />}>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
        <Route path="/reset-password" element={<ResetPasswordScreen />} />
        <Route path="/on-boarding" element={<UserOnBoard />} />
        <Route path="/on-boarding/start" element={<UserOnBoardVideo />} />
        <Route path="/on-boarding/complete" element={<UserOnBoardComplete position="center" />} />
      </Route>
      <Route path="" element={<ProtectedRoutes />}>

        <Route index={true} path="/" element={<DashboardScreen />} />
        <Route path="/prioritize" element={<UserPrioritize />} />
        <Route
          path="/prioritize/reprioritize"
          element={<UserOnBoardComplete position="start" />}
        />
        <Route path="/admin-dashboard" element={<AdminDashboardScreen />} />
        <Route path="/coaches-corner" element={<CoachesCorner />} />
        <Route path="/careers" element={<CareersScreen />} />
        <Route path="/user-careers" element={<UserCareersScreen />} />
        <Route
          path="/user-careers/deeper-dive/:param"
          element={<DeeperDive />}
        />
        <Route path="/career-awareness" element={<GlimpseRankings />} />
        <Route path="/careers/details/:param" element={<CareerFormScreen />} />
        <Route
          path="/careers/career-insights/:param"
          element={<CareerInfoScreen />}
        />
        <Route
          path="/partner-careers/careers/career-insights/:param"
          element={<CareerInfoScreen />}
        />
        <Route
          path="/user-careers/careers/career-insights/:param"
          element={<CareerInfoScreen />}
        />
        <Route path="/careers/details" element={<CareerFormScreen />} />
        <Route
          path="/partners"
          id="PartnerKey"
          key={"PartnerKey"}
          element={<PartnerScreen />}
        />
        <Route path="/partner-dashboard" element={<PartnerDashboard />} />
        <Route path="/partner-careers" element={<PartnerCareers />} />
        <Route
          path="/prioritize/unlock-next/start"
          element={<UserOnBoardVideo />}
        />
        <Route
          path="/user-careers/unlock-next/start"
          element={<UserOnBoardVideo />}
        />
        <Route
          path="/prioritize/unlock-next/complete"
          element={<UserOnBoardComplete position="start" />}
        />
        <Route
          path="/user-careers/unlock-next/complete"
          element={<UserOnBoardComplete position="start" />}
        />
        <Route
          path="/partners/partners-insight/:param"
          element={<PartnerInsightScreen />}
        />
        <Route path="/Individuals" element={<IndividualScreen />} />
        <Route
          path="/partner-dashboard/individual-insight/:param"
          element={<IndividualInsightScreen />}
        />
        <Route
          path="/individuals/individual-insight/:param"
          element={<IndividualInsightScreen />}
        />
        <Route path="/settings" element={<SettingsScreen />} />
        <Route path="/promo-codes" element={<PromoCodeScreen />} />

        <Route path="/help-center" element={<HelpCenterScreen />} />
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Route>
  )
);

export default router;
