import React, { ReactNode } from "react";

interface GlimpseSmallCardProps {
  children: ReactNode;
  className?: string;
  title?: string;
  childrenClass?: string;
  titleClass?: string;
}

const GlimpseSmallCard: React.FC<GlimpseSmallCardProps> = ({
  children,
  title,
  className,
  childrenClass,
  titleClass,
}) => {
  return (
    <div
      className={`h-[100px] min-w-[250px] w-full outline-0 rounded-xl flex-col p-2 shadow-card flex justify-start ${className}`}
    >
      <div className={`${titleClass} w-full pb-3 font-semibold leading-4`}>
        {title}
      </div>
      <div className={`${childrenClass}`}>{children}</div>
    </div>
  );
};

export default GlimpseSmallCard;
