import React, { FC, useState } from "react";

interface TooltipProps {
  children: React.ReactNode;
  content: string;
  placement: "top" | "bottom" | "left" | "right";
  placing: string;
  customClass?: string;
}
const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  placement,
  placing,
  customClass
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const getPosition = () => {
    switch (placement) {
      case "top":
        return `top-[${placing}] `;
      case "bottom":
        return `bottom-[${placing}] `;
      case "left":
        return `left-[${placing}] `;
      case "right":
        return `right-[${placing}] `;
      default:
        return "";
    }
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`${customClass ? customClass : 'relative inline-block'}`}
    >
      {children}
      {isVisible && (
        <div
          className={`tooltip absolute bg-white text-xs text-gray px-2 py-1 rounded-md shadow-md z-50 ${getPosition()}`}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
