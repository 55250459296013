import { useMutation } from "@tanstack/react-query";
import { downloadCoachesCorderPDF } from "services/members.service";

const CoachesCorner: React.FC = () => {
  const pdfData = useMutation({
    mutationKey: ["DownloadCoachPDF"],
    mutationFn: () => downloadCoachesCorderPDF(),
    retry: 0,
    onSuccess: (data: any) => {
      const link = document.createElement("a");
      link.download = "true";

      link.href = data.url;
      link.target = "_blank";
      link.rel = "noreferrer";

      link.click();
      link.parentNode?.removeChild(link);
    }
  })
  return (
    <div className="max-w-[455px]">
      <div className="text-xl ">Learn To Inspire</div>
      <div className="text-sm pb-[25px] ">
        The following learning material is aimed at helping you provide the best
        experience for your members as possible. You will instruction on how to
        help them best learn using Glimpse.
      </div>
      <button
        className="font-bold text-white rounded-full bg-green w-[195px] flex items-center justify-center gap-2 h-[40px] "
        onClick={() => {
          pdfData.mutateAsync()
        }}
      >
        <span>Download PDF</span>
        <span>
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3998 9.30793V11.07C14.3998 11.5163 14.0416 11.8779 13.5998 11.8779H2.39996C1.9581 11.8779 1.59997 11.5163 1.59997 11.07V9.30793C1.59997 8.86168 1.24185 8.5 0.799987 8.5C0.358127 8.5 0 8.86168 0 9.30793V11.3431C0 12.5343 0.956251 13.5 2.1357 13.5H13.8643C15.0437 13.5 16 12.5343 16 11.3431V9.30793C16 8.86168 15.6419 8.5 15.2 8.5C14.7582 8.5 14.4 8.86168 14.4 9.30793H14.3998Z"
              fill="white"
            />
            <path
              d="M8.91771 8.06588H6.71765V1.60016C6.71765 0.992667 7.21005 0.5 7.81781 0.5C8.42531 0.5 8.91798 0.992398 8.91798 1.60016V8.06614L8.91771 8.06588Z"
              fill="white"
            />
            <path
              d="M12.8738 4.47465C12.4441 4.04491 11.7476 4.04491 11.3181 4.47465L7.81756 7.97522L4.31699 4.47465C3.88725 4.04491 3.19074 4.04491 2.76127 4.47465C2.33153 4.90439 2.33153 5.6009 2.76127 6.03037L7.16192 10.431C7.52416 10.7933 8.11122 10.7933 8.47346 10.431L12.8741 6.03037C13.3039 5.60063 13.3039 4.90412 12.8741 4.47465H12.8738Z"
              fill="white"
            />
          </svg>
        </span>
      </button>
    </div>
  );
};

export default CoachesCorner;
