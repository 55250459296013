import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import Loading from "components/loading/loading.component";
import { IPaymentState } from "interfaces/PaymentInterface";
import GlimpseInput from "components/input/glimpse-input";
const PaymentForm: React.FC<IPaymentState> = ({
  isLoading,
  onGoback,
  onSuccess,
  email
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>("");
  const [billingEmail, setBillingEmail] = useState<string>(email || "");
  const [isProcessing, setIsProcessing] = useState(false);
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        receipt_email: billingEmail,
      },
    });

    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message as string);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      onSuccess();
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  return (
    <>
      {isLoading ? (
        <Loading text="Loading payment gateway..." />
      ) : (
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="mb-2">
            <GlimpseInput
              id="subtitle"
              name="Email"
              placeholder="Email Address"
              value={billingEmail}
              showLabel={true}
              label="Email"
              type="email"
              onDataChanged={(data: React.ChangeEvent<HTMLInputElement>) => {
                setBillingEmail(data.target.value);
              }}
            />
          </div>
          <PaymentElement id="payment-element" />
          <div className="flex w-full gap-3 p-2">
            <button
              type="button"
              name="save"
              onClick={onGoback}
              className="text-center w-1/3 bg-transparent border border-headerBlue text-headerBlue gap-3 h-[40px] lg:h-[32px] rounded-full text-sm font-normal leading-5"
            >
              Back
            </button>
            <button
              className="text-white w-2/3 text-center bg-green gap-3 h-[40px] lg:h-[32px] rounded-full text-sm font-normal leading-5"
              disabled={isProcessing || !stripe || !elements}
              id="submit"
              type="submit"
            >
              <span id="button-text">
                {isProcessing ? "Processing ... " : "Confirm Payment"}
              </span>
            </button>
          </div>

          {message && <div id="payment-message">{message}</div>}
        </form>
      )}
    </>
  );
};

export default PaymentForm;
