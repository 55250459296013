import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import DragAndDrop from "components/drag-and-drop/drag-and-drop.component";
import Loading from "components/loading/loading.component";
import { CareerCategory } from "enums/glimps.enums";
import {
  IMyCareersPrioritize,
  IPrioritizeItem,
} from "interfaces/OnbaordingInterface";
import { ListPrioritize, prioritizeCareer } from "services/on-board";

const PrioritizeComponent: React.FC = () => {
  const queryClient = useQueryClient();
  const {
    data: prioritizeListData,
    status: fetchStatus,
    refetch: refetchPrioritizeList,
  } = useQuery({
    queryKey: ["ListPrioritize"],
    queryFn: () => ListPrioritize(),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const prioritizeDraggedCareer = useMutation({
    mutationFn: (data: IPrioritizeItem) => prioritizeCareer(data),
    retry: 0,
    onSuccess: (data: IMyCareersPrioritize[]) => {
      queryClient.invalidateQueries({ queryKey: ['ListPrioritize'] })
    }
  });

  return (
    <>
      <div className="px-5 pb-[20px]">
        <div className="text-2xl font-bold text-headerBlue">
          Let's Sort Your Careers
        </div>
        <span className="text-[16px]">
          Drag and drop each career to re-sort them according to your
          preference.
        </span>
      </div>
      {prioritizeListData?.length && fetchStatus === "success" ? (
        <DragAndDrop
          key={Math.random().toFixed(2)}
          onDropped={(data: IMyCareersPrioritize, allList) => {
            switch (data?.category) {
              case CareerCategory.unsorted:
                let listOfUnsorted = allList.filter(
                  (x) => x.category === CareerCategory.unsorted
                );
                prioritizeDraggedCareer.mutateAsync({
                  category: CareerCategory.unsorted,
                  order: listOfUnsorted.indexOf(data)
                    ? listOfUnsorted.indexOf(data) + 1
                    : 1,
                  id: data.id,
                });
                break;
              case CareerCategory.top_three:
                let listOfTopThree = allList.filter(
                  (x) => x.category === CareerCategory.top_three
                );
                prioritizeDraggedCareer.mutateAsync({
                  category: CareerCategory.top_three,
                  order: listOfTopThree.indexOf(data)
                    ? listOfTopThree.indexOf(data) + 1
                    : 1,
                  id: data.id,
                });
                break;
              case CareerCategory.discard:
                let listOfDiscard = allList.filter(
                  (x) => x.category === CareerCategory.discard
                );
                prioritizeDraggedCareer.mutateAsync({
                  category: CareerCategory.discard,
                  order: listOfDiscard.indexOf(data)
                    ? listOfDiscard.indexOf(data) + 1
                    : 1,
                  id: data.id,
                });
                break;
              case CareerCategory.watch_list:
                let listOfWatchList = allList.filter(
                  (x) => x.category === CareerCategory.watch_list
                );
                prioritizeDraggedCareer.mutateAsync({
                  category: CareerCategory.watch_list,
                  order: listOfWatchList.indexOf(data)
                    ? listOfWatchList.indexOf(data) + 1
                    : 1,
                  id: data.id,
                });
                break;
              default:
                break;
            }
          }}
          displayUnsorted={true}
          data={prioritizeListData}
        />
      ) : (
        <Loading text="Loading Data" />
      )}
    </>
  );
};

export default PrioritizeComponent;
