import http from "./http-service";
import {
  ENDUSERDATA,
  HELP_CENTER,
  MODULE_QUIZ,
  PARTNER_ENDUSERDATA,
} from "./endpoint-urls";
import { IHelpCenter } from "interfaces/BaseInterface";
import {
  IEndUserData,
  IModuleQuiz,
  IPartnerDataUpdate,
  IPartnerUserData,
} from "interfaces/user.interface";

export const getEndUserData = () => {
  return http.get<IEndUserData>(`${ENDUSERDATA}`).then((res) => {
    return res.data;
  });
};

const formDataHeader = {
  "Content-Type": "multipart/form-data",
};

export const updateUserData = (data: FormData) => {
  return http
    .put<FormData>(`${ENDUSERDATA}/update`, data, {
      headers: formDataHeader,
    })
    .then((res) => {
      return res.data;
    });
};

export const getPartnerData = () => {
  return http.get<IPartnerUserData>(`${PARTNER_ENDUSERDATA}`).then((res) => {
    return res.data;
  });
};

export const updatePartnerData = (data: IPartnerDataUpdate) => {
  return http
    .put<IPartnerDataUpdate>(`${PARTNER_ENDUSERDATA}`, data)
    .then((res) => {
      return res.data;
    });
};

export const sendHelpCenterMessage = (message: IHelpCenter) => {
  return http.post<IHelpCenter>(`${HELP_CENTER}`, message).then((res) => {
    return res.data;
  });
};

export const updateModuleQuiz = (data: IModuleQuiz) => {
  return http.put<IModuleQuiz>(`${MODULE_QUIZ}`, data).then((res) => {
    return res.data;
  });
};
