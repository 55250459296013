import InputError from "components/input-error/input-error";
import { toggleEnum } from "enums/glimps.enums";
import { ErrorMessage } from "formik";
import React, { useState } from "react";

interface GlimpseSwitchToggleProps {
  label: string;
  name: string;
  id: string;
  selectedType: string;
  toggles: typeof toggleEnum;
  toggleChanged: (toggles: toggleEnum) => void;
}
const GlimpseSwitchToggle: React.FC<GlimpseSwitchToggleProps> = ({
  label,
  name,
  toggles,
  id,
  toggleChanged,
  selectedType,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    selectedType ? selectedType : toggles.paid
  );

  const handleToggleChange = (value: toggleEnum) => {};
  return (
    <>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-4 text-gray-900"
      >
        {label}
      </label>
      {Object.values(toggles).map((toggle: toggleEnum, index: number) => {
        return (
          <span key={index}>
            <label className="inline-flex pt-2">
              <input
                type="radio"
                id={toggle}
                name={name}
                value={toggle}
                checked={selectedValue === toggle}
                onChange={() => {
                  setSelectedValue(toggle);
                  toggleChanged(toggle);
                }}
                className="hidden"
              />

              <span
                className={`${
                  selectedValue === toggle
                    ? "bg-blue rounded-full text-white"
                    : "text-gray"
                } capitalize w-[80px] h-[32px] justify-center text-center flex items-center font-semibold cursor-pointer text-[12px] `}
              >
                {toggle}
              </span>
            </label>
            <ErrorMessage name={name} component={InputError} />
          </span>
        );
      })}
    </>
  );
};

export default GlimpseSwitchToggle;
