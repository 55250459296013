import { IAddNewSection } from "./add-new-section.interface";

const AddNewSection: React.FC<IAddNewSection> = ({
  className,
  clicked,
  label,
  subLabel,
  isEnable,
}: IAddNewSection) => {
  return (
    <div
      className={`flex flex-col cursor-pointer ${className} ${
        isEnable ? "text-black" : "text-gray"
      }`}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => clicked(e)}
    >
      <div className={`flex items-center gap-2`}>
        <svg
          width="17"
          height="18"
          viewBox="0 0 17 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.5 16.5C12.6421 16.5 16 13.1421 16 9C16 4.85786 12.6421 1.5 8.5 1.5C4.35786 1.5 1 4.85786 1 9C1 13.1421 4.35786 16.5 8.5 16.5Z"
            stroke={`${isEnable ? "black" : "gray"}`}
            strokeWidth="1.21462"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.16702 9H11.8337"
            stroke={`${isEnable ? "black" : "gray"}`}
            strokeWidth="1.21462"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.50033 5.66699V12.3337"
            stroke={`${isEnable ? "black" : "gray"}`}
            strokeWidth="1.21462"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span className="text-base font-semibold">{label}</span>
      </div>
      <div className="flex">
        <span className="font-normal text-sm">{subLabel}</span>
      </div>
    </div>
  );
};

export default AddNewSection;
