import React, { ReactNode } from "react";

interface GlimpsePopupProps {
  title?: string;
  popup: boolean;
  children: ReactNode;
  customClass?: string;
  onClose: () => void;
  showCross?: boolean;
}

const GlimpsePopup: React.FC<GlimpsePopupProps> = ({
  title,
  children,
  onClose,
  popup,
  showCross,
  customClass,
}) => {
  return (
    <>
      {popup && (
        <div className="fixed top-0 left-0 h-screen z-50 justify-center items-center w-full md:inset-0  max-h-full bg-[#EEF9FB65] flex">
          <div className="relative min-w-[347px] max-h-full p-4 overflow-auto touch-auto">
            <div
              className={`relative bg-white rounded-lg shadow ${customClass} overflow-auto`}
            >
              {showCross && (
                <button
                  type="button"
                  className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center "
                  onClick={onClose}
                >
                  <svg
                    width={30}
                    height={30}
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.5 22.5C26.6421 18.3579 26.6421 11.6421 22.5 7.5C18.3579 3.35786 11.6421 3.35786 7.5 7.5C3.35786 11.6421 3.35786 18.3579 7.5 22.5C11.6421 26.6421 18.3579 26.6421 22.5 22.5Z"
                      fill="#404040"
                    />
                    <path
                      d="M10.1721 19.8279C9.94265 19.5985 9.94265 19.2265 10.1721 18.9956L18.9956 10.1721C19.225 9.94265 19.5985 9.94265 19.8279 10.1721C20.0574 10.4015 20.0574 10.775 19.8279 11.0044L11.0044 19.8279C10.775 20.0574 10.4029 20.0574 10.1721 19.8279Z"
                      fill="white"
                    />
                    <path
                      d="M18.9956 19.8279L10.1721 11.0044C9.94265 10.775 9.94265 10.4015 10.1721 10.1721C10.4015 9.94265 10.775 9.94265 11.0044 10.1721L19.8279 18.9956C20.0574 19.225 20.0574 19.5971 19.8279 19.8279C19.5985 20.0574 19.225 20.0574 18.9956 19.8279Z"
                      fill="white"
                    />
                  </svg>

                  <span className="sr-only">Close modal</span>
                </button>
              )}
              <div className="p-4 text-center md:p-5">
                <div className="mb-5 text-2xl font-bold text-headerBlue">
                  {title}
                </div>
                <div className="text-start">{children}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GlimpsePopup;
