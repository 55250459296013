import GlimpseInputBox from "components/input/formik-input";
import GlimpseSwitchToggle from "components/switch/switchtoggle.component";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { IPartnerResult } from "interfaces/AddPartnersInterface";
import { toggleEnum } from "enums/glimps.enums";
import Loading from "components/loading/loading.component";

interface PartnersProps {
  partnerInfo: IPartnerResult;
  isLoading: boolean;
  onSubmit: (item: IPartnerResult) => void;
  onClose: () => void;
}

const AddPartner: React.FC<PartnersProps> = ({
  partnerInfo,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Organization Name is required..!"),
    contact_person: Yup.string().required("Contact is required..!"),
    contact_email: Yup.string()
      .email("Invalid email Address")
      .required("Email is required..!"),
    type: Yup.string().required("Account type is required..!"),
    total_licenses: Yup.number()
      .required("Number of licenses is required..!")
      .moreThan(0, "Number of licenses must be greather than 0"),
  });
  return (
    <div>
      <Formik
        initialValues={partnerInfo}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={true}
      >
        {({ isSubmitting, setFieldValue, errors, values }) => (
          <Form autoComplete="off">
            <div className="text-headerBlue text-[20px] flex items-start">
              {partnerInfo.name === ""
                ? "New Partner Account"
                : "Edit Partner Account"}
            </div>
            <div className="mt-5">
              <GlimpseInputBox
                type="text"
                id="name"
                label="Organization"
                as="input"
                name="name"
                autoComplete={false}
                placeholder="Organization "
                maxLength={50}
              />
            </div>
            <div className="mt-5">
              <GlimpseInputBox
                type="text"
                id="contact_person"
                label="Contact"
                as="input"
                name="contact_person"
                autoComplete={false}
                placeholder="Contact"
                maxLength={50}
              />
            </div>
            <div className="mt-5">
              <GlimpseInputBox
                type="text"
                id="contact_email"
                label="Contact Email"
                as="input"
                name="contact_email"
                autoComplete={false}
                placeholder="Contact Email"
                maxLength={50}
              />
            </div>
            <div className="mt-5">
              <GlimpseSwitchToggle
                label="Account Type"
                id="type"
                name="type"
                selectedType={values.type}
                toggles={toggleEnum}
                toggleChanged={(data: toggleEnum) => {
                  setFieldValue("type", data.toLowerCase());
                }}
              />
            </div>
            <div className="mt-5">
              <GlimpseInputBox
                type="number"
                id="total_licenses"
                label="Number of Licenses"
                as="input"
                name="total_licenses"
                placeholder="Number of Licenses"
                autoComplete={false}
                maxLength={50}
              />
            </div>

            <div className="flex justify-between mt-5">
              <button
                type="button"
                onClick={onClose}
                className="text-green text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-green"
              >
                Close
              </button>

              {partnerInfo.id ? (
                <button
                  type="submit"
                  disabled={isLoading}
                  className="text-white text-center bg-green w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                >
                  {isLoading ? <Loading /> : "Update"}
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isLoading}
                  className="text-white text-center bg-green w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                >
                  {isLoading ? <Loading /> : "Create Account"}
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddPartner;
