import { useQuery } from "@tanstack/react-query";
import CareerItem from "components/career-item/career-item.component";
import Loading from "components/loading/loading.component";
import { useNavigate } from "react-router-dom";
import { getAllCareer } from "services/career.service";

const PartnerCareers: React.FC = () => {
  const navigation = useNavigate();
  const {
    data: careersList,
    status,
    error,
  } = useQuery({
    queryKey: ["getAllCareer"],
    queryFn: () => getAllCareer("published"),
    refetchOnWindowFocus: false,
    retry: 0
  });

  return (
    <div>
      <div className="flex flex-row items-end justify-between">
        <span className="text-xl font-bold leading-6 text-headerBlue">
          Published Careers
        </span>
      </div>
      <div className="flex flex-wrap gap-4 mt-4">
        {status === "pending" && <Loading />}
        {careersList &&
          careersList.map((item) => {
            return (
              <CareerItem
                key={item.id}
                id={item.id}
                icon_url={item.icon_url}
                status={item.status}
                name={item.name}
                pressed={(id: string) => {
                  navigation("/partner-careers/careers/career-insights/" + id);
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default PartnerCareers;
