import React, { ReactNode, useState } from "react";
import { useNavigate } from "react-router";
import "./progress-dots.css";

interface GlimpseProgressDotsProps {
  totalSlides: number;
  children: ReactNode;
  isVertical?: boolean;
  currentSlide: number;
  enableNext?: boolean;
  onNext: (isQuiz: boolean) => void;
  handleSortNow: () => void;
  handleFinish: () => void;
  onPrev: () => void;
  enableQuiz: boolean;
  enableSorting?: boolean;
  isQuiz?: boolean;
}

const GlimpseProgressDots: React.FC<GlimpseProgressDotsProps> = ({
  totalSlides,
  children,
  isVertical,
  currentSlide,
  enableNext,
  onNext,
  handleSortNow,
  onPrev,
  enableQuiz,
  handleFinish,
  enableSorting,
  isQuiz,
}) => {
  // const [progress, setProgress] = useState(currentSlide);
  const navigate = useNavigate();

  const handleNext = (isQuiz: boolean = false) => {
    // setProgress((prevProgress) => Math.min(prevProgress + 1, totalSlides));
    onNext(isQuiz);
  };

  const handlePrev = () => {
    // setProgress((prevProgress) => Math.max(prevProgress - 1, 1));
    onPrev();
  };

  const renderDots = () => {
    const dots = [];

    for (let i = 1; i <= totalSlides; i++) {
      // const isActive = i === progress;

      dots.push(
        <div
          key={i}
          className={`rounded-full  ${currentSlide === i
              ? `bg-[#529C82] ${isVertical ? "h-[33px] w-[8px]" : "w-[33px] h-[8px]"
              } `
              : `bg-[#DDDDDD]  ${isVertical ? "my-[5px]" : "mx-[5px]"
              } h-[8px] w-[8px]`
            } `}
        />
      );
    }

    return dots;
  };

  return (
    <>
      <div className="w-full max-w-[605px]">
        <div className="flex flex-col">
          {!isVertical && <div className="h-[416px]">{children}</div>}
        </div>
        {isVertical ? (
          <div className="flex justify-center ">
            <div className="flex flex-col items-center justify-center pr-5 sm:px-5 relative left-0 sm:left-[15%]">
              {renderDots()}
            </div>
            <div className="flex flex-col items-center">{children}</div>

            {/* <div className="display-btns ">
              <button
                disabled={currentSlide <= 1}
                onClick={handlePrev}
                className={`px-2 py-1 bg-white border rounded-full text-green border-green ${
                  currentSlide <= 1 ? "hidden" : ""
                }`}
              >
                Back
              </button>
              {enableSorting && (
                <button
                  disabled={!enableNext}
                  onClick={handleSortNow}
                  className={`px-2 py-1 text-white rounded-full bg-green ${
                    !enableNext
                      ? " cursor-not-allowed opacity-40"
                      : " cursor-pointer"
                  }`}
                >
                  Sort Now
                </button>
              )}
              <button
                disabled={!enableNext}
                onClick={() => handleNext(false)}
                className={`px-2 py-1 text-white rounded-full bg-green ${
                  !enableNext
                    ? " cursor-not-allowed opacity-40"
                    : " cursor-pointer"
                }`}
              >
                Next
              </button>
            </div> */}
          </div>
        ) : (
          <>
            {currentSlide === totalSlides && !isQuiz ? (
              <>
                <div className="flex justify-end max-w-[600px] mt-2">
                  <button
                    onClick={
                      !enableQuiz ? handleFinish : () => handleNext(true)
                    }
                    className={`px-2 py-1 text-white rounded-full bg-green w-[121px] ${enableNext ? "" : "cursor-not-allowed bg-gray"
                      }`}
                    disabled={!enableNext}
                  >
                    {!enableQuiz ? `Finish` : `Start Quiz`}
                  </button>
                </div>
              </>
            ) : (
              <div
                className={`flex relative  gap-4 ${currentSlide <= 1 && !isQuiz
                    ? "justify-end"
                    : "justify-between"
                  } mt-2`}
              >
                <button
                  onClick={handlePrev}
                  className={`px-2 py-1 bg-white border rounded-full text-green border-green min-w-[121px] ${currentSlide <= 1 ? "invisible" : null
                    }`}
                >
                  Back
                </button>

                <div
                  className={`render-dots ${currentSlide <= 1 && !isQuiz && " sm:-left-[59px]"
                    }`}
                >
                  {renderDots()}
                </div>
                {enableSorting && (
                  <button
                    disabled={!enableNext}
                    onClick={handleSortNow}
                    className="px-2 py-1 text-white rounded-full bg-green min-w-[121px]"
                  >
                    Sort Now
                  </button>
                )}
                <button
                  disabled={!enableNext}
                  onClick={() => {
                    if (isQuiz && currentSlide === totalSlides) {
                      handleFinish();
                    } else {
                      handleNext(false);
                    }
                  }}
                  className={`px-2 py-1 text-white rounded-full bg-green min-w-[121px] ${!enableNext
                      ? " cursor-not-allowed opacity-40"
                      : " cursor-pointer"
                    }`}
                >
                  {isQuiz && currentSlide === totalSlides ? "Finish" : "Next"}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default GlimpseProgressDots;
