import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragableCareerItem } from "./dragable-career-item";

export const SortableCareerItem = (props: any) => {
  const sortable = useSortable({ id: props.url });
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div onClick={() => { props.onPressed(props.url) }}>
      <DragableCareerItem
        ref={setNodeRef}
        style={style}
        {...props}
        {...attributes}
        {...listeners}
      />
    </div>
  );
};
