import { ICalendarProps } from "interfaces/CalendarInterface";
import React from "react";

const WeekRangePicker: React.FC<ICalendarProps> = ({
  currentWeek,
  setCurrentWeek,
}) => {
  const handlePrevWeek = () => {
    setCurrentWeek((prevWeekStart) => {
      const prevWeekStartCopy = new Date(prevWeekStart);
      prevWeekStartCopy.setDate(prevWeekStartCopy.getDate() - 7);
      return prevWeekStartCopy;
    });
  };

  const handleNextWeek = () => {
    setCurrentWeek((prevWeekStart) => {
      const nextWeekStartCopy = new Date(prevWeekStart);
      nextWeekStartCopy.setDate(nextWeekStartCopy.getDate() + 7);
      return nextWeekStartCopy;
    });
  };

  const weekEnd = new Date(currentWeek);
  weekEnd.setDate(weekEnd.getDate() + 6);

  return (
    <div className="text-xs font-bold week-range-picker">
      <div className="flex items-center gap-2 calendar-header">
        <button onClick={handlePrevWeek}>
          <span>
            <span>
              <svg
                width="6"
                height="11"
                viewBox="0 0 6 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.18125 5.9351L4.98061 10.8211C5.21488 11.0596 5.59478 11.0596 5.82905 10.8211C6.05698 10.5826 6.05698 10.2023 5.82905 9.96381L1.44757 5.50322L5.82905 1.04263C6.05698 0.804131 6.05698 0.417375 5.82905 0.178875C5.59478 -0.059625 5.21488 -0.059625 4.98061 0.178875L0.174918 5.07134C-0.0593517 5.30984 -0.0593517 5.6966 0.18125 5.9351Z"
                  fill="black"
                />
              </svg>
            </span>
          </span>
        </button>
        <span>
          {`${currentWeek.toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
          })} - ${weekEnd.toLocaleDateString("en-US", {
            month: "numeric",
            day: "numeric",
          })}`}
        </span>
        <button onClick={handleNextWeek} disabled={weekEnd >= new Date()}>
          <span>
            <span>
              <svg
                width="6"
                height="11"
                viewBox="0 0 6 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.81875 5.0649L1.01939 0.178875C0.78512 -0.0596247 0.405223 -0.0596247 0.170954 0.178875C-0.0569844 0.417375 -0.0569844 0.797686 0.170954 1.03619L4.55243 5.49678L0.170953 9.95737C-0.0569845 10.1959 -0.0569845 10.5826 0.170953 10.8211C0.405223 11.0596 0.78512 11.0596 1.01939 10.8211L5.82508 5.92866C6.05935 5.69016 6.05935 5.3034 5.81875 5.0649Z"
                  fill="black"
                />
              </svg>
            </span>
          </span>
        </button>
      </div>
      <div className="flex justify-center">
        {`${currentWeek.toLocaleDateString("en-US", {
          year: "numeric",
        })}`}
      </div>
    </div>
  );
};

export default WeekRangePicker;
