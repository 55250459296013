import React, { ReactNode } from "react";

interface GlimpseImpactCardProps {
  className?: string;
  impact: string;
  author: string;
}

const GlimpseImpactCard: React.FC<GlimpseImpactCardProps> = ({
  className,
  impact,
  author,
}) => {
  return (
    <div
      className={`min-w-[226px] md:max-w-[590px] text-headerBlue flex flex-col items-center ${className}`}
    >
      <div className="w-full text-center font-normal leading-[20px]">
        "{impact}"
      </div>
      <div className="pt-2 txet-sm"> -{author} </div>
    </div>
  );
};

export default GlimpseImpactCard;
