import React from "react";
import GlimpseCard from "components/card/card.component";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { IPaymentState } from "interfaces/PaymentInterface";
import { STRIPE_KEY } from "services/endpoint-urls";
import GlimpseInputBox from "components/input/formik-input";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { displayError, displaySuccess } from "components/Toast/toast.component";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { validatePromoCode } from "services/promo.service";
import { IPromoValidateDTO, IPromoValidateResponse } from "interfaces/BaseInterface";
const stripePromise = loadStripe(STRIPE_KEY);


interface InitialValue {

  code: string
}
const initialValues: InitialValue = {
  code: "",

};

const PaymentScreen: React.FC<IPaymentState> = ({
  onGoback,
  clientSecret,
  onSuccess,
  priceInfo,
  email,
}) => {
  const dispatch = useDispatch();


  const queryClient = useQueryClient()

  // Validate Promo Codes
  const { mutate: validatePromoCodes, isPending: isPromoValidating , data:validatedReponse} = useMutation<IPromoValidateResponse, Error, IPromoValidateDTO>({
    mutationFn: (data: IPromoValidateDTO) => validatePromoCode(data),
    retry: 0,
    onSuccess: (res) => {
      displaySuccess("Promo Code Validated");
      console.log(res,'res from api')

      return res
      // queryClient.invalidateQueries({queryKey: ["PromoCodes"]})

    },
    onError: (error:any) => {
      // displayError(error);
    },
  });

  console.log(validatedReponse,'validate response')
  const submitHandler = async (values: InitialValue) => {
    try {
      if (clientSecret) { validatePromoCodes({ coupon: values.code, client_secret: clientSecret }) }
    } catch (error) {
      displayError(
        "Unable to Verify"
      );
    }
  };

  return (
    <div className="flex flex-col px-6 py-2 lg:px-8 h-[100%] bg-background">
      <div className="flex flex-col justify-center gap-3 md:flex-row">
        <GlimpseCard
          title="Billing Information"
          className="min-w-full md:min-w-[490px] md:w-[490px] bg-white order-2 md:order-1"
        >
          {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentForm
                onGoback={onGoback}
                onSuccess={onSuccess}
                email={email}
              />
            </Elements>
          )}
        </GlimpseCard>

        <GlimpseCard
          title=""
          className="min-w-full md:min-w-[274px] md:w-[274px] bg-white px-6 order-1 md:oredr-2"
          childrenClass="flex flex-col gap-5 text-[14px] leading-4"
        >
          {priceInfo && (
            <>
              <span className="font-semibold ">Order Summary</span>
              <div className="flex justify-between pb-2 border-b border-horizontalRow">
                <span> Glimpse Membership</span>
                <span className="pl-2 text-xs"> $ {priceInfo.amount.toFixed(2)}</span>
              </div>
              <div className="flex flex-col gap-2 border-b border-horizontalRow">
                <span className="flex justify-between ">
                  <span>Subtotal</span>
                  <span className="pl-2 text-xs"> $ {priceInfo.amount.toFixed(2)}</span>
                </span>
                <span className="flex justify-between ">
                  <span>Tax </span>
                  <span className="pl-2 text-xs">$ {priceInfo.tax.toFixed(2)}</span>
                </span>
              </div>
              <div className="flex flex-col  gap-2 ">

              <div className="font-semibold flex justify-between text-[14px]">
                <span>Order Total</span>
                <span className="pl-2 text-xs">
                  $ {(priceInfo.amount + priceInfo.tax).toFixed(2)}
                </span>
              </div>

              {
              // @ts-nocheck
              validatedReponse&&
      <div className="flex flex-col  gap-3 ">

                 <div className="font-semibold flex justify-between text-[14px] border-b border-horizontalRow">
                 <span>Promo Discount</span>
                 <span className="pl-2 text-xs">
                  
                  
                 - $ {validatedReponse?.discount?.discount_amount.toFixed(2)}
                 </span>
               </div>
               

               <div className="font-semibold flex justify-between  text-[14px]">
                <span>Order Total After Discount</span>
                <span className="pl-2 text-xs">
                  $ {validatedReponse?.amount.toFixed(2)}
                </span>
              </div>


               
              </div>

              



            }
              </div>

              <Formik

                initialValues={initialValues}
                onSubmit={submitHandler}
              >
                {({ }) => (
                                    <Form autoComplete="off">

                  <div>

                    <span className="flex flex-row justify-between items-center ">
                      <span className="w-32]">Promo Code </span>
                      <div className="">
                        <GlimpseInputBox
                          type="text"
                          id="code"
                          
                          // label="Promo Code"
                          as="input"
                          val
                          name="code"
                          placeholder="Add Promo Code"
                          maxLength={50}
                          sanitized={true}
                          disabled={validatedReponse}
                        />
                      </div>

                    </span>
           

                    <button
                      type="submit"
                      disabled={isPromoValidating||(validatedReponse?true:false)}
                      className="text-white w-full text-center text-base font-semibold leading-4 tracking-wide capitalize whitespace-nowrap justify-center items-center bg-green self-stretch mt-8 py-3 rounded-[100px] max-md:px-5"
                    >
                      {isPromoValidating ? "Loading..." : "Apply"}
                      </button>
                  </div>
                  </Form>

                )}
              </Formik>

      

        </>
          )}
      </GlimpseCard>
    </div>
    </div >
  );
};

export default PaymentScreen;
