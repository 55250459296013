import { StatusFilter, Top3Filter } from "enums/glimps.enums";

export const filterItemsDatas = [
    {
        label: "Status",
        filters: [
            { name: StatusFilter.Active, label: "Active", is_checked: false },
            { name: StatusFilter.Invited, label: "Invited", is_checked: false },
        ],
    },
    {
        label: "Top 3",
        filters: [
            { name: Top3Filter.Assigned, label: "Assigned", is_checked: false },
            { name: Top3Filter.Unassigned, label: "Unassigned", is_checked: false },
        ],
    },
];