import { useSelector } from "react-redux";

const DashboardScreen: React.FC = () => {
  const { userInfo } = useSelector((state: any) => state.auth);
  if (userInfo.user.role === "super") {
    window.location.href = "/admin-dashboard"
  } else if (userInfo.user.role === "partner") {
    window.location.href = "/partner-dashboard"
  } else {
    window.location.href = "/user-careers?tab=preview"
  }
  return <></>;
};

export default DashboardScreen;
