import { Chart } from "chart.js";
import { IDoughnutComponent } from "interfaces/dashboard.interface";
import React from "react";
import { Doughnut } from "react-chartjs-2";

const DughnutComponent: React.FC<IDoughnutComponent> = ({
  centerPercentage,
  data,
  centerValue,
  fontStyle,
  showDataLabels,
  totalCount,
  ratioData,
}) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Chart.js Doughnut Chart",
      },
      datalabels: {
        display: showDataLabels ? true : false,
        color: "white",
        font: {
          size: 12,
          weight: 700,
        },
        formatter: function (value: number) {
          if (value !== 0 && totalCount) {
            return ((value / totalCount) * 100).toFixed(0) + "%";
          } else {
            return null;
          }
        },
      },
    },
  };

  const textCenterDoughnut = {
    id: "textCenter",
    beforeDatasetsDraw(chart: Chart<"doughnut">) {
      const { ctx } = chart;
      let x = chart.getDatasetMeta(0).data[0].x;
      let y = chart.getDatasetMeta(0).data[0]?.y;

      let text = centerPercentage ? centerValue : "";
      if (text.split(" ")[0] === "Total") {
        text = text.split(" ")[0] + " " + text.split(" ")[1];
        ctx.font = "400 16px 'Nunito',sans-serif";
        ctx.fillStyle = "#000000";
        ctx.fillText(`${totalCount}`, x, y + 20);
      }

      ctx.save();
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.font = fontStyle ? fontStyle : "700 16px 'Nunito', sans-serif";
      ctx.fillStyle = "#000000";

      ctx.fillText(text, x, y);
      ctx.font = "700 10px 'Nunito', sans-serif";
      ctx.fillStyle = "#60849F";
      ctx.fillText(ratioData ? ratioData : "", x, y + 15);
    },
  };

  return (
    <>
      {data && (
        <Doughnut
          data={data}
          options={chartOptions}
          plugins={[textCenterDoughnut]}
        />
      )}
    </>
  );
};

export default DughnutComponent;
