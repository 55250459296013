import React, { useEffect, useState } from "react";
import GlimpseHeader from "components/career-header/career-header.component";
import GlimpseProgressDots from "components/progress-dots/progress-dots.component";
import { useNavigate, useParams } from "react-router-dom";
import {
  ContributionCard,
  EducationCard,
  DayinLifeCard,
  ProsConsCard,
  SalaryCard,
  CareerTrendCard,
  DidYouKnowCard,
  ImpactCard,
  Reprioritise,
  GreatWork,
  ExpertVoices,
  Quizzes,
} from "components/career-details/career-details.component";
import {
  CareerTrends,
  DidYouKnow,
  Impacts,
  PageNumberState,
} from "interfaces/CareerInfoInterface";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import Loading from "components/loading/loading.component";
import {
  completeDeeperDive,
  deeperDiveLevelChanged,
} from "services/deeper-dive.service";
import { References, VIdeoStatus } from "enums/glimps.enums";

import { WSS_BASE_URL } from "services/endpoint-urls";
import useWebSocket from "react-use-websocket";
import {
  IDeeperDiveExpertVoice,
  IDeeperDiveProsCons,
} from "interfaces/CareerInterface";
import { displayError } from "components/Toast/toast.component";
import GlimpsePopup from "components/popup/popup.component";
import PrioritizeComponent from "components/prioritize/prioritize.component";

const DeeperDive: React.FC = () => {
  let { param } = useParams();
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [quizCurrentSlide, setQuizCurrentSlide] = useState(0);
  const [isQuiz, setIsQuiz] = useState<boolean>(false);
  const [enableNextButton, setEnableNextButton] = useState<boolean>(true);
  const [enableDayInLife, setEnableDayInLife] = useState<boolean>(false);
  const [enableDidYouKnow, setEnableDidYouKnow] = useState<boolean>(false);
  const [enableProsCons, setEnableProsCons] = useState<boolean>(false);
  const [enableEducation, setEnableEducation] = useState<boolean>(false);
  const [slideCount] = useState(11);
  const [intervalCount, setIntervalCount] = useState<number>(0);
  const [currentVideo, setCurrentVideo] = useState<IDeeperDiveExpertVoice>();
  const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);
  const [displayLinkPopup, setDisplayLinkPopup] = useState<{ type: References | null, isOpen: boolean }>({ type: null, isOpen: false });
  const [rightAnswer, setRightAnswer] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<PageNumberState>({
    dayInLife: 0,
    didyouknow: 0,
    education: 0,
    prosCons: 0
  });

  const { sendJsonMessage } = useWebSocket(WSS_BASE_URL, {
    onError: (error) => {
    },
    // onClose: (status) => {
    // },
  });

  useEffect(() => {
    if (enableDayInLife) {
      enableNext();
    }
  }, [enableDayInLife])

  useEffect(() => {
    if (enableDidYouKnow) {
      enableNext();
    }
  }, [enableDidYouKnow])

  useEffect(() => {
    if (enableEducation) {
      enableNext();
    }
  }, [enableEducation])

  useEffect(() => {
    if (enableProsCons) {
      enableNext();
    }
  }, [enableProsCons])

  useEffect(() => {
    const localStorageValue = localStorage.getItem("userInfo");
    if (localStorageValue) {
      const userInfo: any = JSON.parse(localStorageValue);
      const { access } = userInfo;
      sendJsonMessage({
        action: "authenticate",
        token: access,
      });
    }
  }, []);
  const {
    data: careerInfo,
    isFetching,
    error,
    refetch: refetchDeeperDive,
  } = useSuspenseQuery({
    queryKey: ["GetCareerForDeeperDive", param],
    queryFn: () => {
      enableNext();
      return deeperDiveLevelChanged(param as string, currentSlide + 1);
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const markAsComplete = useMutation({
    mutationKey: ["CompleteDeeperDive"],
    mutationFn: (id: string) => completeDeeperDive(id),
    retry: 0,
    onSuccess: (data: any) => {
      navigate("/user-careers?tab=deep-dive");
    },
  });

  useEffect(() => {
    if (
      careerInfo?.user_career_expert_voices &&
      careerInfo?.user_career_expert_voices.length > 0
    ) {
      setCurrentVideo(careerInfo.user_career_expert_voices[0]);
      enableNext()
    }
  }, [careerInfo]);

  useEffect(() => {
    if (error) {
      handlePrev();
    }
  }, [error]);

  const quizComponents = () => {
    switch (quizCurrentSlide) {
      case 1:
        return (
          <Quizzes
            currentSlide={1}
            key={"Question1"}
            question={careerInfo.quizzes ? careerInfo.quizzes[0].question : ""}
            answers={careerInfo.quizzes ? careerInfo.quizzes[0].answers : []}
            answerChoosed={(data) => {
              setRightAnswer(data.is_correct);
            }}
          />
        );
      case 2:
        return (
          <Quizzes
            currentSlide={2}
            key={"Question2"}
            question={careerInfo.quizzes ? careerInfo.quizzes[1].question : ""}
            answers={careerInfo.quizzes ? careerInfo.quizzes[1].answers : []}
            answerChoosed={(data) => {
              setRightAnswer(data.is_correct);
            }}
          />
        );
      case 3:
        return (
          <Quizzes
            currentSlide={3}
            key={"Question3"}
            question={careerInfo.quizzes ? careerInfo.quizzes[2].question : ""}
            answers={careerInfo.quizzes ? careerInfo.quizzes[2].answers : []}
            answerChoosed={(data) => {
              setRightAnswer(data.is_correct);
            }}
          />
        );
    }
  };

  const conditionalComponent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <ContributionCard
            key={"contribution"}
            from="DeeperDive"
            contribution_url={careerInfo?.career.contribution_url}
          />
        );
      case 1:
        return (
          <ExpertVoices
            key={"export"}
            from="DeeperDive"
            expertVoiceInfo={
              careerInfo?.user_career_expert_voices
                ? careerInfo.user_career_expert_voices
                : []
            }
            selectedVideo={currentVideo?.expert_voice.voice_url}
            videoSelected={(video) => {
              setVideoCurrentTime(0)
              setCurrentVideo(video);
            }}
            deeperDiveClass={true}
            onVideoStarted={() => {
              sendJsonMessage({
                action: "video_progress",
                progress: {
                  id: currentVideo?.id,
                  progress: 0,
                  status: "started",
                },
              });
            }}
            onProgress={(data) => {
              if (data.playedSeconds >= intervalCount + 5 && Math.trunc(data.playedSeconds % 5) === 0 && Math.trunc(data.playedSeconds) !== 0) {
                sendJsonMessage({
                  action: "video_progress",
                  progress: {
                    id: currentVideo?.id,
                    progress: 5,
                    status: "watching",
                  },
                });
                setIntervalCount((prev => prev + 5))
              }
              setVideoCurrentTime(data.playedSeconds);
            }}
            onPaused={() => { }}
            onVideoEnded={() => {
              sendJsonMessage({
                action: "video_progress",
                progress: {
                  id: currentVideo?.id,
                  progress: videoCurrentTime,
                  status: "completed",
                },
              });
              careerInfo?.user_career_expert_voices?.map((x) => {
                if (x.id === currentVideo?.id) {
                  x.deeper_dive_status = VIdeoStatus.Completed;
                }
              });
              enableNext()
            }}
          />
        );
      case 2:
        return (
          careerInfo?.days_in_life && (
            <DayinLifeCard
              from="DeeperDive"
              daysInLifeInfo={careerInfo.days_in_life}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              checkNextEnable={setEnableDayInLife}
            />
          )
        );
      case 3:
        return (
          <ProsConsCard
            key={"proscons"}
            from="DeeperDive"
            prosconsInfo={careerInfo?.pros_cons as IDeeperDiveProsCons}
            deeperDiveClass={true}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            checkNextEnable={setEnableProsCons}
          />
        );

      case 4:
        return (
          <DidYouKnowCard
            key={"didyouknow"}
            from="DeeperDive"
            checkNextEnable={setEnableDidYouKnow}
            handleOpen={() => {
              setDisplayLinkPopup({ type: References.DidYouKnow, isOpen: true })
            }}
            didyouKnowsInfo={
              (careerInfo?.did_you_knows
                ? careerInfo.did_you_knows
                : []) as DidYouKnow[]
            }
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        );
      case 5:
        return (
          careerInfo?.educations && (
            <EducationCard
              key={"education"}
              checkNextEnable={setEnableEducation}
              education={careerInfo?.educations ? careerInfo.educations : []}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              from={"DeeperDive"}
            />
          )
        );
      case 6:
        return (
          <>
            {careerInfo?.salaries && (
              <SalaryCard
                key={"salary"}
                from={"DeeperDive"}
                handleOpen={() => {
                  setDisplayLinkPopup({ type: References.Salary, isOpen: true })
                }}
                advance={Number(careerInfo.salaries.advance)}
                median={Number(careerInfo.salaries.median)}
                starting={Number(careerInfo.salaries.starting)}
              />
            )}
          </>
        );

      case 7:
        return (
          <CareerTrendCard
            key={"careertrend"}
            from={"DeeperDive"}
            handleOpen={() => {
              setDisplayLinkPopup({ type: References.CareerDemand, isOpen: true })
            }}
            careerTrendInfo={
              (careerInfo?.trends ? careerInfo.trends : []) as CareerTrends[]
            }
            deeperDiveClass={true}
          />
        );
      case 8:
        return (
          <ImpactCard
            key={"impact"}
            from="DeeperDive"
            handleOpen={() => {
              setDisplayLinkPopup({ type: References.ImpactQuote, isOpen: true })
            }}
            impactInfo={
              (careerInfo?.impacts ? careerInfo.impacts : []) as Impacts[]
            }
          />
        );
      case 9:
        return (
          <Reprioritise
            onYes={() => {
              // displayError("Implement On yes");
              setDisplayPopup(true);
            }}
            onNo={handleNext}
          />
        );
      case 10:
        return <GreatWork isQuizEnable={careerInfo?.enable_quiz as boolean} careerName={careerInfo.career.name} />;
      default:
        return <Loading text="Unable to load data" />;
    }
  };

  const handleNext = async (isEnableQuiz: boolean) => {
    setEnableNextButton(false);
    if (isQuiz && quizCurrentSlide === 3) {
      markAsComplete.mutateAsync(careerInfo.id);

      return;
    } else if (isEnableQuiz) {
      setIsQuiz(true);
      setQuizCurrentSlide(1);
    } else if (isQuiz) {
      await setQuizCurrentSlide((prev) => prev + 1);
      setRightAnswer(false);

      return;
    }
    await setCurrentSlide((prev) => prev + 1);
    refetchDeeperDive();
  };

  const handlePrev = async () => {
    if (isQuiz) {
      await setQuizCurrentSlide((prev) => prev - 1);
      setRightAnswer(false);
    } else {
      if (currentSlide > 0) {
        await setCurrentSlide((prev) => prev - 1);
        refetchDeeperDive();
      }
    }
  };

  function enableNext() {
    setTimeout(() => {
      switch (currentSlide) {
        case 1:
          let allUnlocked = careerInfo?.user_career_expert_voices?.find(x => x.deeper_dive_status === VIdeoStatus.Unlocked)
          setEnableNextButton(!!!allUnlocked);
          break;
        case 2:
          setEnableNextButton(enableDayInLife)
          break;
        case 3:
          setEnableNextButton(enableProsCons)
          break;
        case 4:
          setEnableNextButton(enableDidYouKnow);
          break;
        case 5:
          setEnableNextButton(enableEducation);
          break;
        default:
          setEnableNextButton(true);
          break;
        // return true;
      }
    }, 2000); // Unlock Next button after 2 second
  }

  return (
    <>
      <div className="p-4">
        <button
          type="button"
          onClick={() => {
            navigate(-1);
          }}
          className="text-black text-center bg-horizontalRow gap-3 mb-4 w-[80px] h-[35px] rounded-full text-sm font-normal leading-5"
        >
          Close
        </button>
        <GlimpsePopup
          title="Sources"
          popup={displayLinkPopup?.isOpen}
          onClose={() => {
            setDisplayLinkPopup({ isOpen: false, type: null })
          }}
          showCross={true}
          customClass="max-w-[388px]"
        >
          <>
            <div>
              <div className="mb-6">
                <p className="text-2xl font-bold text-[#10446A]">
                  {careerInfo.career.name}: Sources
                </p>
                <p className="text-base font-normal">
                  Listed below are sources we used to gather data
                  about this career.
                </p>
              </div>
              <div>
                {careerInfo.references?.map((item) => {
                  return <div key={item.link}><a href={item.link as string} target="_blank" rel="noreferrer" title={item.title}>{item.link as string}</a></div>
                })}
              </div>
            </div>
          </>
        </GlimpsePopup>
        <GlimpsePopup
          customClass={"max-w-[1024px]"}
          onClose={() => {
            setDisplayPopup(false);
          }}
          popup={displayPopup}
          showCross={true}
          title={"Reprioritize"}
        >
          <PrioritizeComponent />
          <div className="flex justify-between flex-row-reverse gap-2 mt-2">
            <button
              onClick={() => {
                setDisplayPopup(false);
                handleNext(false)
              }}
              className="text-white text-center bg-green w-[119px] h-[40px] rounded-full text-sm font-bold border border-green"
            >
              Finish
            </button>
          </div>
        </GlimpsePopup>
        <div className="max-w-[297px] h-full">
          <GlimpseHeader
            key={`Deeper_Dive_Header_${currentSlide}`}
            title={careerInfo.career.name}
            subtitle={careerInfo.career.superpower}
            image={
              careerInfo.career.icon_url
                ? careerInfo.career.icon_url
                : "/assets/images/NoPreview.jpg"
            }
          >
            {careerInfo.career.description}
          </GlimpseHeader>
        </div>
        {careerInfo && !isQuiz ? (
          <GlimpseProgressDots
            key={"ConditionalDeeperDive"}
            totalSlides={slideCount}
            currentSlide={currentSlide + 1}
            onNext={handleNext}
            onPrev={handlePrev}
            enableQuiz={careerInfo.enable_quiz as boolean}
            enableSorting={false}
            isQuiz={isQuiz}
            handleFinish={() => {
              markAsComplete.mutateAsync(careerInfo.id);
            }}
            enableNext={enableNextButton}
            handleSortNow={() => { }}
          >
            {conditionalComponent()}
          </GlimpseProgressDots>
        ) : (
          <GlimpseProgressDots
            key={"QuizDeeperDive"}
            totalSlides={3}
            currentSlide={quizCurrentSlide}
            onNext={handleNext}
            onPrev={handlePrev}
            enableQuiz={careerInfo.enable_quiz as boolean}
            isQuiz={isQuiz}
            handleFinish={() => {
              markAsComplete.mutateAsync(careerInfo.id);
            }}
            enableSorting={false}
            enableNext={rightAnswer}
            handleSortNow={() => { }}
          >
            {quizComponents()}
          </GlimpseProgressDots>
        )}
      </div>
    </>
  );
};

export default DeeperDive;
