import { IMyCareersPrioritize } from "interfaces/OnbaordingInterface";

interface HorizontalCareerItem {
  task: IMyCareersPrioritize;
  index: number;
  onboardingSorted?: boolean;
}

const HorizontalCareerItem = ({
  task,
  index,
  onboardingSorted,
}: HorizontalCareerItem) => {
  return (
    <>
      <div className="relative flex w-full" title={task.name}>
        {task.category !== "discard" && !onboardingSorted && (
          <span className="absolute top-0 -left-2 rounded-full bg-red w-[15px] h-[15px] text-center text-white text-[10px] font-semibold">
            {index}
          </span>
        )}
        <img
          src={task.icon_url}
          alt={task.name}
          width={"24px"}
          height={"24px"}
        />
        &nbsp;
        <span className="overflow-clip">{task.name}</span>
      </div>
    </>
  );
};

export default HorizontalCareerItem;
