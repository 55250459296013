import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ImagePicker from "components/image-picker/image-picker.component";
import GlimpseInputBox from "components/input/formik-input";
import { displayError, displaySuccess } from "components/Toast/toast.component";
import { Form, Formik } from "formik";
import { FileType } from "interfaces/MediaPickerInterface";
import { IEndUserData } from "interfaces/user.interface";
import React, { useEffect, useState } from "react";
import { getEndUserData, updateUserData } from "services/setting.service";
import * as Yup from "yup";

const EndUserData: React.FC = () => {
  const [userData, setUserData] = useState<IEndUserData>();
  const queryClient = useQueryClient()
  const { data: endUserInfo, refetch } = useQuery({
    queryKey: ["getEndUserData"],
    queryFn: getEndUserData,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  useEffect(() => {
    if (endUserInfo) {
      setUserData(endUserInfo);
    }
  }, [endUserInfo]);

  const userValidationSchema = Yup.object().shape({
    profile: Yup.object().shape({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
    }),
  });

  const handleUserInfo = useMutation({
    mutationFn: (data: FormData) => updateUserData(data),
    retry: 0,
    onSuccess: () => {
      refetch();
      displaySuccess("User detail changed successfully.");
      queryClient.invalidateQueries({ queryKey: ['getEndUserData'] })
    },
    onError: () => {
      displayError("Could not update user details.");
    },
  });

  const handleUserInfoSubmit = (values: IEndUserData) => {
    const formData = new FormData();
    if (values.avatar) {
      formData.append("avatar", values.avatar);
    }
    formData.append("first_name", values.profile.first_name);
    formData.append("last_name", values.profile.last_name);

    handleUserInfo.mutateAsync(formData);
  };

  return (
    <div className="py-4 ">
      {userData && (
        <Formik
          initialValues={userData}
          validationSchema={userValidationSchema}
          onSubmit={handleUserInfoSubmit}
        >
          {({ setFieldValue, errors, values }) => (
            <Form>
              <ImagePicker
                id="icon"
                name="icon"
                media={values.profile.avatar}
                className="w-[62px] h-[62px] relative"
                svgPenClassName={`relative ${values.profile.avatar ? "-bottom-[10px]" : "bottom-2"
                  } right-[0px]`}
                svgWidth={27}
                svgHeight={27}
                order={1}
                enableAutoUpload={false}
                onFileUploaded={(e: FileType) => {
                  setFieldValue("avatar", e);
                }}
              />
              <div className="flex gap-2 py-1 text-start">
                <div>
                  <GlimpseInputBox
                    id="first_name"
                    name="profile.first_name"
                    placeholder="First Name"
                    as="input"
                    showLabel={true}
                    label="First Name"
                    onDataChanged={() => { }}
                    maxLength={50}
                  />
                </div>
                <div>
                  <GlimpseInputBox
                    id="last_name"
                    name="profile.last_name"
                    placeholder="Last Name"
                    as="input"
                    showLabel={true}
                    label="Last Name"
                    onDataChanged={() => { }}
                    maxLength={50}
                  />
                </div>
              </div>
              <div className="flex py-1 text-start">
                <div className="w-full">
                  <GlimpseInputBox
                    disabled
                    id="email"
                    name="email"
                    placeholder="Email"
                    as="input"
                    showLabel={true}
                    label="Email"
                    maxLength={50}
                  />
                </div>
              </div>
              <button
                type="submit"
                className=" mt-5 text-white text-center bg-green gap-3 w-[89px] h-[32px] rounded-full text-sm font-normal leading-5"
              >
                Save
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EndUserData;
