import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store/glimpse-store";
import { RouterProvider } from "react-router-dom";
import router from "./routers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Chart } from "chart.js";
import {
  PieController,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

const queryClient = new QueryClient();

Chart.register(
  PieController,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale
); // Register the controller, element and scale for the pie chart
const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container, {
  onRecoverableError(error, errorInfo) {
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
        {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
          <ReactQueryDevtools initialIsOpen={false} />
        ) : null}
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
