import GlimpseCard from "components/card/card.component";
import {
  ISubscription,
  ISubscriptionTable,
} from "../../interfaces/subscription.interface";

const PriceTable = ({ items, onSubscriptionSelect }: ISubscriptionTable) => {
  return (
    <>
      {items?.map((item: ISubscription, index: number) => {
        return (
          <GlimpseCard key={index} className="max-w-[292px] bg-white pb-4">
            <div className="px-4">
              <p className="flex items-baseline mt-6 gap-x-2 text-headerBlue">
                <span className="text-4xl font-bold leading-3 tracking-tighter">
                  ${item.amount}
                </span>
                <span className="text-base font-normal w-[70px] tracking-wide ">
                  /one year access
                </span>
              </p>
              <div className="py-2 text-2xl font-semibold text-headerBlue">
                {item.type}
              </div>
              {/* <div>
                <p className="text-base font-normal text-headerBlack">
                  {item.description}
                </p>
              </div> */}
              <ul className="grid grid-cols-1 gap-2 py-5 text-sm leading-6 text-headerBlack">
                {item.reasons?.split(",").map((reason, index) => (
                  <li key={index} className="flex gap-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      className="flex-none w-5 h-6 text-indigo-600"
                    >
                      <path
                        opacity="0.1"
                        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                        fill="white"
                      />
                      <path
                        d="M15.7725 6.83333L10.0684 14.5742C9.93234 14.7547 9.72948 14.8729 9.50539 14.9024C9.2813 14.9318 9.05478 14.87 8.87671 14.7308L4.80338 11.4742C4.44393 11.1865 4.38573 10.6619 4.67338 10.3025C4.96102 9.94305 5.4856 9.88485 5.84504 10.1725L9.24171 12.89L14.4309 5.84749C14.601 5.59215 14.8978 5.45098 15.2032 5.48003C15.5087 5.50907 15.7735 5.70364 15.8925 5.98647C16.0115 6.26931 15.9654 6.59465 15.7725 6.83333Z"
                        fill="#3A3A3A"
                      />
                    </svg>
                    {reason}
                  </li>
                ))}
              </ul>
              <button
                type="button"
                onClick={() => onSubscriptionSelect(item, index)}
                className="text-white w-full text-center text-base font-semibold leading-4 tracking-wide capitalize whitespace-nowrap justify-center items-center bg-green self-stretch mt-6 py-3 rounded-[100px]"
              >
                Choose Plan
              </button>
            </div>
          </GlimpseCard>
        );
      })}
    </>
  );
};
export default PriceTable;
