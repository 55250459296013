import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { displaySuccess } from "components/Toast/toast.component";
import PrioritizeComponent from "components/prioritize/prioritize.component";
import { CareerCategory } from "enums/glimps.enums";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ListPrioritize, completeOnBoard } from "services/on-board";

const UserOnBoardComplete: React.FC<{ position: "start" | "center" }> = ({
  position,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const completedOnBoard = useMutation({
    mutationFn: () => completeOnBoard(),
    retry: 0,
    onSuccess: async (data) => {
      const localStorageValue = localStorage.getItem("userInfo");
      if (localStorageValue) {
        let userInfo = JSON.parse(localStorageValue || "");
        userInfo.user.profile.onboarding_completed = true;
        await localStorage.setItem("userInfo", JSON.stringify(userInfo));
        displaySuccess(data.message);
        window.location.href = "/user-careers?tab=preview";
      }
    },
  });

  const { data: listofSortedData } = useQuery({
    queryKey: ["ListPrioritize"],
    queryFn: () => ListPrioritize(),
    retry: false,
    refetchOnWindowFocus: false,
  });

  const onBoardComplete = () => {
    if (location.pathname.includes("on-boarding")) {
      completedOnBoard.mutateAsync();
    }
    else {
      if (window.location.pathname.includes("prioritize/unlock-next")) {
        navigate("/prioritize");
      }
      else if (window.location.pathname.includes("user-careers/unlock-next")) {
        navigate("/user-careers?tab=preview");
      } else {
        navigate("/user-careers?tab=preview");
      }
    }
  };
  return (
    <div className={`flex flex-row justify-${position}`}>
      <div className="flex flex-col pb-[200px] md:pb-0  md:p-5 w-[992px]">
        <PrioritizeComponent />
        <div className="flex justify-end px-10 pt-[70px]">
          <button
            disabled={!!listofSortedData?.some((x) => x.category === CareerCategory.unsorted)}
            onClick={() => onBoardComplete()}
            className={`text-white text-center w-[110px] h-[40px] rounded-full text-md font-normal leading-5 bg-green ${!!listofSortedData?.some((x) => x.category === CareerCategory.unsorted) && 'cursor-not-allowed opacity-40'}`}
          >
            Save
          </button>
        </div>
      </div>
    </div >
  );
};

export default UserOnBoardComplete;
