import React, { ReactNode } from "react";

interface GlimpseCardProps {
  children: ReactNode;
  className?: string;
  title?: string;
  subtitle?: string;
  childrenClass?: string;
  titleClass?: string;
  subtitleClass?: string;
}

const GlimpseCard: React.FC<GlimpseCardProps> = ({
  children,
  title,
  subtitle,
  className,
  childrenClass,
  titleClass,
  subtitleClass,
}) => {
  return (
    <div
      className={`h-full w-full outline-0 rounded-lg flex-col p-3 shadow-card flex justify-start ${className}`}
    >
      <div
        className={`w-full pb-2 leading-4 tracking-wide text-headerBlue font-600 font-bold ${titleClass ? titleClass : null}`}
      >
        {title}
        <div
          className={`w-full leading-5 text-gray font-normal max-w-[85%] ${subtitleClass ? subtitleClass : null}`}
        >
          {subtitle}
        </div>
      </div>
      <div className={childrenClass}>{children}</div>
    </div>
  );
};

export default GlimpseCard;
