import GlimpseCard from "components/card/card.component";
import GlimpseInputBox from "components/input/formik-input";
import { registrationEnum } from "enums/glimps.enums";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { IHelpSomeoneLearn } from "interfaces/RegisterInterface";
import React from "react";
import InputError from "components/input-error/input-error";
import { displayWarning } from "components/Toast/toast.component";

const HelpSomeoneLearn: React.FC<IHelpSomeoneLearn> = ({
  setActiveRegStep,
  setActiveTab,
  handleGiftInvitation,
  email,
}) => {
  const validationSchema = Yup.object().shape({
    emailList: Yup.array().of(
      Yup.string().email("Invalid email format").required("Required")
    ),
  });

  return (
    <>
      <div className="flex flex-col justify-center topic">
        <h2 className="mt-5 text-2xl font-bold tracking-tight text-center md:text-4xl text-headerBlue">
          Help Someone Learn
        </h2>
        <p className="px-10 pt-2 text-sm font-bold tracking-tight text-center min-w-[327px] ">
          Provide the emails for every person you want to send a code to.
        </p>
      </div>
      <div className="flex justify-center py-4">
        <GlimpseCard className="bg-white max-w-[340px] md:w-[360px] pb-4">
          <Formik
            initialValues={email}
            validationSchema={validationSchema}
            onSubmit={handleGiftInvitation}
          >
            {({ isSubmitting, values, errors }) => (
              <Form>
                <div className="">
                  {errors?.emailList &&
                    typeof errors.emailList === "string" && (
                      <ErrorMessage name={"emailList"} component={InputError} />
                    )}
                  <FieldArray
                    name="emailList"
                    render={({ push, pop }) => (
                      <>
                        <div className="flex items-center justify-center w-full h-20 text-end">
                          <div className="flex items-end justify-end w-2/5 p-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                              fill="none"
                              className="mt-6 cursor-pointer"
                              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                                if (values.emailList.length > 1) {
                                  pop();
                                } else {
                                  displayWarning(
                                    "You can not remove last item."
                                  );
                                }
                              }}
                            >
                              <circle cx="13" cy="13" r="13" fill="#D4B07D" />
                              <path
                                d="M6 13H20"
                                stroke="white"
                                strokeWidth="3"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                          <div className="w-1/5 h-20 flex justify-center items-center text-center bg-cover bg-center bg-[url('../public/assets/icons/gift_pack.svg')] max-w-max min-w-[75px]">
                            <div className="flex items-center justify-center w-10 h-10 mt-6 text-center bg-white rounded-full">
                              <span className="font-bold">
                                {values.emailList.length}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-start justify-start w-2/5 p-2 text-start">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                              fill="none"
                              className="mt-6 cursor-pointer"
                              onClick={(e: React.MouseEvent<SVGSVGElement>) => {
                                push("");
                              }}
                            >
                              <circle cx="13" cy="13" r="13" fill="#D4B07D" />
                              <path
                                d="M6 13H20"
                                stroke="white"
                                strokeWidth="3"
                                strokeLinecap="round"
                              />
                              <path
                                d="M13 6L13 20"
                                stroke="white"
                                strokeWidth="3"
                                strokeLinecap="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="overflow-auto h-[300px]">
                          {values.emailList.map((item, index: number) => {
                            return (
                              <div
                                key={index}
                                className="max-w-[462px] pt-[11px] "
                              >
                                <GlimpseInputBox
                                  id={`emailList.${index}`}
                                  name={`emailList.${index}`}
                                  label={index === 0 && "Email"}
                                  type="text"
                                  as="input"
                                  placeholder="Email"
                                  maxLength={50}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    )}
                  />
                </div>
                <div className="flex justify-between gap-5 pt-4">
                  <button
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      setActiveRegStep(registrationEnum.subscription)
                    }
                    className="text-green border border-green text-center bg-white gap-3 font-bold w-[121px] h-[40px] rounded-full text-sm leading-5"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="text-white text-center bg-green gap-3  w-[193px] h-[40px] rounded-full text-sm font-medium leading-5"
                  >
                    Continue to Cart
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </GlimpseCard>
      </div>
      <div className="flex justify-center">
        <p className="text-sm font-normal text-headerBlue">
          <span>Want to redeem a code you received?</span>
          <span
            className="pl-1 font-bold underline cursor-pointer text-headerBlue underline-offset-2"
            onClick={() => setActiveTab(2)}
          >
            Redeem Code
          </span>
        </p>
      </div>
    </>
  );
};

export default HelpSomeoneLearn;
