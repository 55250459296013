import GlimpseCard from "components/card/card.component";
import GlimpseInput from "components/input/glimpse-input";
import { IRedeemCode } from "interfaces/RegisterInterface";
import React from "react";
import { useNavigate } from "react-router";

const RedeemCode: React.FC<IRedeemCode> = ({
  handleRedeemCodeSubmit,
  redeemCode,
  handleSubmit,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="topic">
        <h2 className="mt-5 text-4xl font-bold tracking-tight text-center text-headerBlue">
          Let's Start Learning
        </h2>
        <p className="pt-2 text-xl font-bold tracking-tight text-center ">
          If you have a code, enter it now!
        </p>
      </div>
      <div className="flex justify-center p-5">
        <GlimpseCard className="max-w-[343px] md:max-w-[360px] bg-white pb-5 ">
          <div className="flex flex-col">
            <img
              className="w-[90px] h-[90px] self-center"
              src="/assets/icons/LoginCheck.svg"
              alt="Login Check"
              loading="lazy"
            />


            <div className="container p-4 mx-auto">
              <div className="gap-5 md:flex">
                <div className="w-full mt-3">
                  <GlimpseInput
                    id={"code"}
                    name={"code"}
                    label={"Code"}
                    type="text"
                    placeholder="ENTER CODE"
                    value={redeemCode}
                    onDataChanged={function (
                      e: React.ChangeEvent<HTMLInputElement>
                    ) {
                      handleRedeemCodeSubmit(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-4">
            <button
              type="submit"
              onClick={() => handleSubmit()}
              className="text-white w-full text-center text-base font-semibold leading-4 tracking-wide capitalize whitespace-nowrap justify-center items-center bg-green self-stretch mt-6 py-3 rounded-[100px]"
            >
              Redeem Code
            </button>
          </div>
        </GlimpseCard>
      </div>
      <div className="flex justify-center mt-5">
        <p className="text-sm font-normal text-headerBlue">
          <span>Already have a subscription? </span>
          <span
            className="font-bold underline cursor-pointer text-headerBlue underline-offset-2"
            onClick={() => navigate("/login")}
          >
            Login
          </span>
        </p>
      </div>
    </>
  );
};

export default RedeemCode;
