import GlimpseInputBox from "components/input/formik-input";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  IResetPasswordPartner,
  IResetPasswordPartnerProps,
  IResetPasswordPartnerPayload,
} from "interfaces/resetPassword.interface";
import { useState } from "react";
import {
  displayError,
  displaySuccess,
  displayWarning,
} from "components/Toast/toast.component";
import { useMutation } from "@tanstack/react-query";
import { resetPartnerPassword } from "services/resetPassword.service";
import { useParams } from "react-router-dom";
import { passwordRegex } from "constants/regex";

const initialValues = {
  password: "",
  new_password: "",
  confirm_password: "",
};

const ResetPasswordPartner: React.FC<IResetPasswordPartnerProps> = ({
  partnerInfo,
  onClose,
}) => {
  const passwordValidationSchema = Yup.object({
    password: Yup.string().required("Password is required..!"),
    new_password: Yup.string()
      .matches(
        passwordRegex,
        "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      )
      .required("New Password is required..!"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("new_password")], "Passwords must match")
      .required("Confirm New Password is required..!"),
  });
  const handleChangePassword = useMutation({
    mutationFn: (data: IResetPasswordPartnerPayload) =>
      resetPartnerPassword(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Password changed successfully.");
      onClose();
    },
    onError: () => {
      displayError("Password change failed");
    },
  });

  const handlePasswordSubmit = (values: IResetPasswordPartner) => {
    if (values.new_password === values.confirm_password) {
      if (partnerInfo.id) {
        const payload = { id: partnerInfo.id, data: values };
        handleChangePassword.mutateAsync(payload);
      }
    } else {
      displayWarning("Password must be same");
    }
  };

  return (
    <div>
      <div className="py-5">
        If you reset
        <span className="font-bold "> {`${partnerInfo.name}`}'s </span>
        password, they will be automatically logged out on all devices.
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={passwordValidationSchema}
        onSubmit={handlePasswordSubmit}
      >
        {({ isSubmitting, setFieldValue, values, errors }) => (
          <Form>
            <GlimpseInputBox
              type="password"
              id="password"
              label="Password"
              as="input"
              name="password"
              placeholder="Password"
              isPassword={true}
              maxLength={50}
            />
            <GlimpseInputBox
              type="password"
              id="new_password"
              label="New Password"
              as="input"
              name="new_password"
              placeholder="New Password"
              isPassword={true}
              maxLength={50}
            />
            <GlimpseInputBox
              type="password"
              id="confirm_password"
              label="Confirm New Password"
              as="input"
              name="confirm_password"
              placeholder="Confirm New Password"
              isPassword={true}
              maxLength={50}
            />

            <div className="flex justify-between mt-5">
              <button
                type="button"
                onClick={onClose}
                className="text-headerBlue text-center bg-white gap-3 w-[147px] h-[32px] rounded-full text-sm font-normal leading-5 border border-headerBlue"
              >
                Close
              </button>
              <button
                type="submit"
                className="text-white text-center bg-green gap-3 w-[147px] h-[32px] rounded-full text-sm font-normal leading-5"
              >
                Confirm
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPasswordPartner;
