import { useMutation } from "@tanstack/react-query";
import { displayError, displaySuccess } from "components/Toast/toast.component";
import GlimpseInputBox from "components/input/formik-input";
import { FieldArray, Form, Formik } from "formik";
import {
  IAddMemberEmailList,
  IEmailList,
} from "interfaces/AddPartnersInterface";
import React, { useState } from "react";
import { inviteMembersWithList } from "services/members.service";
import * as Yup from "yup";

const initialValues = { emailList: [{ email: "" }] };

const AddMemberEmailList: React.FC<IAddMemberEmailList> = ({
  next,
  setNext,
  onClose,
}) => {
  const [count, setCount] = useState(1);
  const [emailList, setEmailList] =
    useState<typeof initialValues>(initialValues);

  const validationSchema = Yup.object().shape({
    emailList: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email("Invalid email format")
          .required("Atleast 1 email is required"),
      })
    ),
  });

  const handleEmailList = useMutation({
    mutationFn: (members: IEmailList) => inviteMembersWithList(members),
    retry: 0,
    onSuccess: (data) => {
      displaySuccess(
        `${data.invites.length > 1
          ? "Invitations sent successfully"
          : "Invitation sent successfully"
        }`
      );
      onClose();
      setNext(false);
    },
    onError: () => {
      displayError("Sending invitation has been failed.");
    },
  });

  const handleSubmitEmailList = (values: typeof initialValues) => {
    const emails = values.emailList.map(
      (item: { email: string }) => item.email
    );
    const payload = { emails: emails };

    handleEmailList.mutateAsync(payload);
  };

  return (
    <div>
      <div className="flex flex-col ">
        <Formik
          initialValues={emailList}
          validationSchema={validationSchema}
          onSubmit={handleSubmitEmailList}
        >
          {({ isSubmitting, values, errors }) => (
            <Form>
              {!next ? (
                <>
                  <div className="text-xl text-headerBlue">
                    Invite a new member to Glimpse. Each invitation will use one
                    license.
                  </div>

                  <div className="h-[200px] overflow-auto">
                    <FieldArray
                      name="emailList"
                      render={(arrayHelpers) => (
                        <>
                          <div className="flex gap-2 py-2 btn">
                            <button
                              className="cursor-pointer"
                              disabled={count === 1}
                              onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                              ) => {
                                if (count > 1) {
                                  arrayHelpers.remove(
                                    values.emailList.length - 1
                                  );
                                  setCount(count - 1);
                                }
                              }}
                            >
                              <svg
                                width={26}
                                height={26}
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx={13} cy={13} r={13} fill="#D4B07D" />
                                <path
                                  d="M6 13H20"
                                  stroke="white"
                                  strokeWidth={3}
                                  strokeLinecap="round"
                                />
                              </svg>
                            </button>
                            <span className="text-xl font-bold">{count}</span>
                            <button
                              className="cursor-pointer"
                              onClick={(
                                e: React.MouseEvent<HTMLButtonElement>
                              ) => {
                                arrayHelpers.push({ email: "" });
                                setCount(count + 1);
                              }}
                            >
                              <svg
                                width={26}
                                height={26}
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle cx={13} cy={13} r={13} fill="#D4B07D" />
                                <path
                                  d="M6 13H20"
                                  stroke="white"
                                  strokeWidth={3}
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M13 6L13 20"
                                  stroke="white"
                                  strokeWidth={3}
                                  strokeLinecap="round"
                                />
                              </svg>
                            </button>
                          </div>
                          <div>
                            {values?.emailList?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="max-w-[462px] pt-[11px]"
                                >
                                  <GlimpseInputBox
                                    id={`emailList.${index}.email`}
                                    name={`emailList.${index}.email`}
                                    label={index === 0 && "Email"}
                                    type="input"
                                    placeholder="Email"
                                    maxLength={50}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="flex justify-between mt-5 ">
                    <button
                      onClick={() => {
                        onClose();
                        setNext(false);
                      }}
                      className="text-headerBlue text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-headerBlue"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        setNext(true);
                      }}
                      className="text-white text-center bg-green w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                    >
                      Next
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="button">
                    <button
                      onClick={() => setNext(false)}
                      className="text-black text-center bg-horizontalRow gap-3 mb-4 w-[57px] h-[20px] rounded-md text-sm font-normal leading-5 text-[10px] pl-1"
                    >
                      <div className="flex items-center justify-center">
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z"
                            fill="#3A3A3A"
                          />
                        </svg>
                        <span className="pl-1 font-semibold">Back</span>
                      </div>
                    </button>
                  </div>
                  <div className="py-2 text-xl text-headerBlue">
                    Send invitations to join Glimpse
                  </div>
                  <div className="py-2 text-sm text-headerBlack">
                    An invitation email from Glimpse will be sent to each email
                    address entered. Do you want to proceed?
                  </div>
                  <div className="flex justify-between mt-5 ">
                    <button
                      onClick={() => {
                        onClose();
                        setNext(false);
                      }}
                      className="text-headerBlue text-center bg-white w-[119px] h-[40px] rounded-full text-sm font-bold leading-5 border border-headerBlue"
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="text-white text-center bg-green w-[119px] h-[40px] rounded-full text-sm font-normal leading-5"
                    >
                      Yes, Send Invites
                    </button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddMemberEmailList;
