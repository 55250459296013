import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { KeenSliderPlugin, useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import "./scroller.css";
import VideoStreaming from "components/video-streaming-onboard/video-streaming.component";
import { IOnBoardResult } from "interfaces/OnbaordingInterface";
import ReactPlayer from "react-player";
import { KeenSliderHooks, KeenSliderInstance } from "keen-slider";
export interface ISliderItem {
  items: IOnBoardResult[];
  onStart: () => void;
  onEnd: () => void;
  onProgress: (data: any) => void;
  onBuffer: () => void;
  onPaused: () => void;
  onError: () => void;
  onSeek?: (data: any) => void;
  setMobileIndex: any;
  setCurrentSlide: any;
  setIntervalCount: any;
  currentSlide: number;
  totalSlides: number;

  handleSortNow?: () => void;
  handleNext?: (
    type: boolean,
    ref?: MutableRefObject<KeenSliderInstance<{}, {}, KeenSliderHooks> | null>
  ) => void;
  handleBack?: (
    ref?: MutableRefObject<KeenSliderInstance<{}, {}, KeenSliderHooks> | null>
  ) => void;
  enableNext?: boolean;
}

const WheelControls: KeenSliderPlugin = (slider) => {
  let touchTimeout: ReturnType<typeof setTimeout>;
  let position: {
    x: number;
    y: number;
  };
  let wheelActive: boolean;

  function dispatch(e: WheelEvent, name: string) {
    position.x -= e.deltaX;
    position.y -= e.deltaY;
    slider.container.dispatchEvent(
      new CustomEvent(name, {
        detail: {
          x: position.x,
          y: position.y,
        },
      })
    );
  }

  function wheelStart(e: WheelEvent) {
    position = {
      x: e.pageX,
      y: e.pageY,
    };
    dispatch(e, "ksDragStart");
  }

  function wheel(e: WheelEvent) {
    dispatch(e, "ksDrag");
  }

  function wheelEnd(e: WheelEvent) {
    dispatch(e, "ksDragEnd");
  }

  function eventWheel(e: WheelEvent) {
    e.preventDefault();
    if (!wheelActive) {
      wheelStart(e);
      wheelActive = true;
    }
    wheel(e);
    clearTimeout(touchTimeout);
    touchTimeout = setTimeout(() => {
      wheelActive = false;
      wheelEnd(e);
    }, 50);
  }

  slider.on("created", () => {
    slider.container.addEventListener("wheel", eventWheel, {
      passive: false,
    });
  });
};

const MutationPlugin = (slider: any) => {
  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      slider.update();
    });
  });
  const config = { childList: true };
  slider.on("created", () => {
    observer.observe(slider.container, config);
  });
  slider.on("destroyed", () => {
    observer.disconnect();
  });
  return observer;
};

const VerticalCarousel: React.FC<ISliderItem> = ({
  items,
  onEnd,
  setMobileIndex,
  onBuffer,
  onError,
  onPaused,
  onProgress,
  onStart,
  onSeek,
  currentSlide,
  setCurrentSlide,
  setIntervalCount,
  enableNext,
  handleBack,
  handleNext,
  handleSortNow,
  totalSlides,
}) => {
  const getPerView = () => {
    // return window.matchMedia("(max-height: 800px)").matches ? 1 : 1.3;
    if (window.matchMedia("(max-height: 800px)").matches) {
      return 1;
    } else if (window.matchMedia("(max-height: 870px)").matches) {
      return 1.2;
    } else {
      return 1.3;
    }
  };
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: false,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
        setMobileIndex(slider.track.details.rel);
        setIntervalCount(0);
      },
      slides: {
        origin: "center",
        perView: getPerView(),
        spacing: 10,
      },
      vertical: true,
    },
    [MutationPlugin, WheelControls]
  );
  useEffect(() => {
    instanceRef.current?.moveToIdx(currentSlide);
  }, [currentSlide]);

  return (
    <>
      <div
        ref={sliderRef}
        className="keen-slider !w-[315px] sm:!w-[465px]"
        style={{ height: "90vh" }}
      >
        {items.map((item, index) => (
          <div
            className={`keen-slider__slide number-slide ${
              index !== currentSlide ? "blur-sm" : null
            }`}
          >
            <VideoStreaming
              videoStatus={item.cover_video.deeper_dive_status}
              currentIndex={index}
              currentSlide={currentSlide}
              enableButtons={true}
              header={{
                title: item.career.name,
                subtitle: item.career.superpower,
                children: (
                  <span className="flex w-[300px] max-w-[297px] sm:w-[270px]">
                    {item.career.description}
                  </span>
                ),
                image: item.career.icon_url,
              }}
              video_url={item.cover_video.expert_voice.voice_url}
              thumbnail={item.cover_video.expert_voice.voice_thumbnail}
              key={index}
              onBuffer={onBuffer}
              onStart={onStart}
              onProgress={onProgress}
              onPaused={onPaused}
              onEnd={onEnd}
              onError={onError}
              onSeek={onSeek}
              enableNext={enableNext}
              handleBack={() => {
                if (handleBack && instanceRef.current) handleBack(instanceRef);
              }}
              handleNext={() => {
                if (handleNext && instanceRef.current)
                  handleNext(false, instanceRef);
              }}
              handleSortNow={handleSortNow}
              className="!w-[296px] !h-[430px]"
              popupClass="relative sm:grid sm:grid-cols-2 "
            ></VideoStreaming>
          </div>
        ))}
      </div>
      {currentSlide === totalSlides - 1 && (
        <div className="flex sm:hidden absolute bottom-12 right-16 w-[110px] shadow-2xl rounded-2xl">
          <button
            disabled={!enableNext}
            onClick={() => (handleNext ? handleNext(false) : null)}
            className={`px-2 py-1 text-white rounded-full w-[87%] bg-green ${
              !enableNext ? " cursor-not-allowed opacity-40" : " cursor-pointer"
            }`}
          >
            Next
          </button>
        </div>
      )}
    </>
  );
};

export default VerticalCarousel;
