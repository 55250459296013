import React from "react";
import { useField, ErrorMessage } from "formik";
import { IGlimpseSelectProps } from "./glimpse-input.interface";

const GlimpseSelect: React.FC<IGlimpseSelectProps> = ({
  options,
  placeholder,
  className,
  showLabel,
  label,
  title,
  name,
}) => {
  const [field, meta] = useField(name);
  return (
    <>
      {showLabel ? (
        <label
          htmlFor={title}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {label}
        </label>
      ) : null}
      <div className={`relative ${className}`}>
        <select
          {...field}
          className={`block w-full h-[40px] mt-1 text-gray-900 border shadow-sm rounded-lg border-input_outline placeholder:text-headerBlack sm:text-sm sm:leading-6 text-headerBlack focus:ring-0 focus:border-input_outline focus:outline-0`}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options?.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <ErrorMessage
          component="div"
          name={name}
          className="mt-1 text-sm text-red"
        />
      </div>
    </>
  );
};

export default GlimpseSelect;
