import { SortableContext } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Task } from "./dragable.component";
import GlimpseCard from "components/card/card.component";
import { IMyCareersPrioritize } from "interfaces/OnbaordingInterface";
import { UniqueIdentifier } from "@dnd-kit/core";
import "./scroll.css";
interface IDragCol {
  column: {
    category: UniqueIdentifier;
    title: string;
    subtitle: string;
  };
  tasks: IMyCareersPrioritize[];
  totalTopThree: number;
  onboardingSorted?: boolean;
}
export const Column = ({
  column,
  tasks,
  totalTopThree,
  onboardingSorted,
}: IDragCol) => {
  const taskIds = tasks.map(({ id }: any) => id);
  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({
      id: column.category,
      data: {
        type: "Column",
        column,
      },
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const getDivsBasedOnTasksLength = (
    totalLength: number,
    tasksLength: number
  ) => {
    const divs = [];
    const numberOfDivs = totalLength - tasksLength;

    for (let i = 0; i < numberOfDivs; i++) {
      divs.push(
        <div
          key={i}
          className="mx-2 mt-2 gap-4 rounded-lg h-[33px] border-2 border-dashed border-horizontalRow"
        />
      );
    }
    return divs;
  };

  return (
    <GlimpseCard
      className={`${(column.title === "Watch List" || column.title === "Discard") &&
        "scrollbar-invisible"
        } ${column.title === "Unsorted" && "p-0 bg-background sm:bg-transparent"} ${column.title === "Top 3" && "h-auto"
        }`}
      title={
        onboardingSorted && column.title === "Unsorted" ? "" : column.title
      }
      titleClass={`text-[16px] ${!onboardingSorted && column.title === "Unsorted"
        ? " text-headerBlue font-bold text-[24px]"
        : "font-bold text-headerBlue "
        }`}
      subtitle={column.title === "Unsorted" ? "" : column.subtitle}
      subtitleClass="text-[12px] md:text-nowrap "
    >
      {/* <header>
        <p className={`text-[16px]`}></p>
        <p className="text-xs">{column.subtitle}</p>
      </header> */}
      <main
        id={column.title}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={`flex flex-grow ${column.title === "Unsorted" ? "flex-row" : "flex-col"
          } gap-4 p-2 overflow-auto`}
      >
        <SortableContext items={taskIds}>
          {tasks.map((task: any, index: number) => (
            <Task
              key={task.id}
              task={task}
              index={index}
              totalTopThree={totalTopThree}
              onboardingSorted={onboardingSorted}
            />
          ))}
        </SortableContext>
      </main>

      <span className={`${onboardingSorted && "hidden"}`}>
        {column.title === "Top 3" &&
          tasks.length !== 3 &&
          getDivsBasedOnTasksLength(3, tasks.length)}
        <span className="hidden md:block">
          {(column.title === "Watch List" || column.title === "Discard") &&
            getDivsBasedOnTasksLength(8, 0)}
        </span>
        <span className="block md:hidden">
          {(column.title === "Watch List" || column.title === "Discard") &&
            getDivsBasedOnTasksLength(1, 0)}
        </span>
      </span>
    </GlimpseCard>
  );
};
