import React from "react";
import { IScrollCard } from "../../interfaces/ScrollCardInterface";

const GlimpseScrollCard: React.FC<IScrollCard> = ({
  className,
  image,
  title,
  subtitle,
}) => {
  return (
    <div className={`block min-w-[170px] ${className}`}>
      <div className="flex justify-center">
        <img src={image} alt={title} className="w-[62px] h-[62px]" />
      </div>
      <div className="w-full font-bold leading-4 tracking-wide text-[16px] text-green mt-2 text-center">
        {title}
        <div className="w-[200px] lg:w-full pt-3 text-xs font-normal leading-4 text-gray">
          {subtitle}
        </div>
      </div>
    </div>
  );
};

export default GlimpseScrollCard;
