import React, { ReactNode } from "react";

interface GlimpseCareerTrendsProps {
  className?: string;
  title: string;
  subtitle: string;
}

const GlimpseCareerTrends: React.FC<GlimpseCareerTrendsProps> = ({
  title,
  className,
  subtitle,
}) => {
  return (
    <div className={`block max-w-[226px] max-h-[250px] ${className}`}>
      <div className="w-full text-4xl font-bold text-green">{title}</div>
      <div className="w-full font-normal leading-[20px] text-gray">
        {subtitle}
      </div>
    </div>
  );
};

export default GlimpseCareerTrends;
