import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CareerItem from "components/career-item/career-item.component";
import HorizontalCareerItem from "components/career-item/horizontal-career-item.component";
import { IMyCareersPrioritize } from "interfaces/OnbaordingInterface";
import { CareerCategory } from "enums/glimps.enums";

interface TaskInterface {
  task: IMyCareersPrioritize;
  index: number;
  totalTopThree: number;
  onboardingSorted?: boolean;
}

export const Task = ({
  task,
  index,
  totalTopThree,
  onboardingSorted,
}: TaskInterface) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: {
      type: "Task",
      task,
    },
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  // We need to give an overlay so that performance issue wont happen
  if (isDragging) {
    return (
      <div
        ref={setNodeRef}
        style={style}
        //     className="h-[33px] w-[262px]
        // items-center flex text-left rounded-xl hover:ring-2 hover:ring-inset
        // hover:ring-rose-500 cursor-grab"
        // className="min-w-full lg:min-w-[100px] max-w-[100px]"
        className={`min-w-[80px] outline-2 outline-dashed outline-horizontalRow rounded-md ${task.category === CareerCategory.unsorted ? 'min-w-[80px]' : 'min-w-full lg:min-w-[100px]'}`}
      >
        &nbsp;
      </div>
    );
  }

  return task.category === CareerCategory.unsorted ? (
    <div style={style} ref={setNodeRef} {...attributes} {...listeners}>
      {/* {JSON.stringify(index)} */}
      <CareerItem
        id={"123"}
        icon_url={task.icon_url}
        name={task.name}
        pressed={() => { }}
      />
    </div>
  ) : (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="flex bg-widgetBlue rounded-lg min-w-full h-[33px] p-1 text-white"
    >
      <HorizontalCareerItem
        task={task}
        index={
          task.category === CareerCategory.top_three
            ? index + 1
            : index + totalTopThree + 1
        }
        onboardingSorted={onboardingSorted}
      />
    </div>
  );
};
