import { Education } from "interfaces/CareerInfoInterface";
import React from "react";

const GlimpseEducationSection: React.FC<Education> = ({
  title,
  className,
  description,
  duration,
  image,
}) => {
  return (
    <div className={`block justify-center items-center ${className}`}>
      <div className="flex justify-center pb-2">
        <img src={image} alt={title} className="w-[85px] h-[85px]" onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = "/assets/icons/Certification.png";
        }} />
      </div>

      <div className="w-full font-bold leading-4 text-md tracking-wide text-center text-green">
        {title}
      </div>
      <div className="w-full font-normal text-sm leading-4 capitalize text-gray mt-1">
        {description}
      </div>
      <div className="w-full pt-2 text-sm leading-3 tracking-wide">
        {duration} years
      </div>
    </div>
  );
};

export default GlimpseEducationSection;
