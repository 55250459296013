import React from "react";
import GlimpseCard from "components/card/card.component";
import { IRegisterUser } from "interfaces/RegisterInterface";
import GlimpseSelect from "components/input/glimpse-select";
import GlimpseInputBox from "components/input/formik-input";
import { Formik, Form } from "formik";
import { IUserRegistration } from "interfaces/user.interface";
import { emailRegex, passwordRegex } from "constants/regex";
import * as Yup from "yup";

const RegisterUser: React.FC<IRegisterUser> = ({
  userInfo,
  handleValueChanged,
  handleSubmit,
  options,
}) => {
  const validationSchema = Yup.object({
    first_name: Yup.string().required("Enter your first name."),
    last_name: Yup.string().required("Enter your last name."),
    email: Yup.string()
      .matches(emailRegex, "Enter valid email.")
      .required("Email is required"),
    password: Yup.string()
      .matches(
        passwordRegex,
        "Password should be minimum 8 characters with at least 1 number and 1 letter."
      )
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Password and Confirm password must match")
      .required("Password confirmation is required"),
    dob: Yup.string().required("Enter your birth year"),
  });
  return (
    <>
      <div className="topic">
        <h2 className="mt-5 text-2xl font-bold tracking-tight text-center md:text-4xl text-headerBlue">
          <p>Welcome!</p>{" "}
          <span className="text-2xl">We're excited you're here</span>
        </h2>
        <p className="pt-2 text-sm font-bold tracking-tight text-center ">
          Fill out the information below to complete account set up.
        </p>
      </div>
      <div className="flex justify-center py-4">
        <GlimpseCard className="bg-white max-w-[340px] md:max-w-[360px] pb-4">
          <div className="flex flex-col ">
            <img
              className="h-[90px] w-[90px] ml-3 self-center"
              src="/assets/icons/boy_with_glass.svg"
              alt="Boy_With_Glass_Icon "
              loading="lazy"
            />
            <Formik
              initialValues={userInfo}
              validationSchema={validationSchema}
              onSubmit={(e: IUserRegistration) => {
                handleSubmit(e);
              }}
            >
              {() => (
                <Form>
                  <div className="container p-2 mx-auto">
                    <div className="flex gap-2 mt-3">
                      <div className="w-1/2">
                        <GlimpseInputBox
                          id="first_name"
                          name="first_name"
                          label="First Name"
                          type="input"
                          placeholder={"First Name*"}
                          maxLength={50}
                        />
                      </div>
                      <div className="w-1/2">
                        <GlimpseInputBox
                          id="last_name"
                          name="last_name"
                          label="Last Name"
                          type="input"
                          placeholder={"Last Name*"}
                          maxLength={50}
                        />
                      </div>
                    </div>
                    <div className="w-full mt-4">
                      <GlimpseInputBox
                        type="email"
                        label="Email"
                        as="input"
                        id="email"
                        name="email"
                        placeholder="Email*"
                        maxLength={50}
                      />
                    </div>
                    <div className="w-full mt-4">
                      <GlimpseSelect
                        name="dob"
                        title={"Birth Year"}
                        value={userInfo.dob}
                        options={options ? options : []}
                        showLabel={true}
                        label="Birth Year"
                        id="dob"
                        placeholder="Select.."
                        onChange={handleValueChanged}
                      />
                    </div>
                    <div className="w-full mt-4">
                      <div>
                        <GlimpseInputBox
                          type="password"
                          id="password"
                          label="New Password"
                          as="input"
                          name="password"
                          placeholder="New Password"
                          isPassword={true}
                          maxLength={50}
                        />
                      </div>
                      <div>
                        <GlimpseInputBox
                          type="password"
                          id="confirm_password"
                          label="Confirm New Password"
                          as="input"
                          name="confirm_password"
                          placeholder="Confirm New Password"
                          isPassword={true}
                          maxLength={50}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-center ">
                    <input
                      id="terms_checkbox"
                      type="checkbox"
                      aria-label="terms_checkbox"
                      name="terms-checkbox"
                      required={true}
                      className="w-4 h-4 rounded text-green focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2"
                    />
                    <label
                      htmlFor="terms-checkbox"
                      className="text-[12px] font-medium ms-2"
                    >
                      <span>I agree to the Glimpse </span>
                      <span
                        className="underline cursor-pointer text-green underline-offset-2"
                        onClick={() => {
                          window.open(
                            "https://www.glimpse.org/terms-of-use",
                            "_blank"
                          );
                        }}
                      >
                        Terms of Use
                      </span>
                      <span className="px-1">and</span>
                      <span
                        className="underline cursor-pointer text-green underline-offset-2"
                        onClick={() => {
                          window.open(
                            "https://www.glimpse.org/privacy-policy",
                            "_blank"
                          );
                        }}
                      >
                        Privacy Policy
                      </span>
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="text-white w-full text-center text-base font-semibold leading-4 tracking-wide capitalize whitespace-nowrap justify-center items-center bg-green self-stretch mt-6 px-16 py-3 rounded-[100px] max-md:px-5"
                  >
                    Create Account
                  </button>
                </Form>
              )}
            </Formik>
            {/*  <form onSubmit={handleSubmit} autoComplete="off">
              <div className="container p-4 mx-auto">
                <div className="flex gap-5">
                  <div className="w-1/2">
                    <GlimpseInput
                      value={userInfo.first_name}
                      disabled={false}
                      showLabel={true}
                      label="First Name"
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First Name*"
                      onDataChanged={handleValueChanged}
                    />
                  </div>
                  <div className="w-1/2">
                    <GlimpseInput
                      value={userInfo.last_name}
                      disabled={false}
                      showLabel={true}
                      label="Last Name"
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last Name*"
                      onDataChanged={handleValueChanged}
                    />
                  </div>
                </div> 
                <div className="w-full mt-3">
                  <GlimpseInput
                    value={userInfo.email}
                    disabled={userInfo.invitation_id ? true : false}
                    showLabel={true}
                    label="Email"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email*"
                    onDataChanged={handleValueChanged}
                  />
                </div>
                <div className="w-full mt-3">
                  <GlimpseSelect
                    title={"Birth Year"}
                    value={userInfo.dob}
                    options={options ? options : []}
                    showLabel={true}
                    label="Birth Year"
                    id="dob"
                    placeholder="Select.."
                    onChange={handleValueChanged}
                  />
                </div>
                <div className="w-full mt-3">
                  <GlimpseInput
                    value={userInfo.password}
                    disabled={false}
                    showLabel={true}
                    label="Password"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Password*"
                    onDataChanged={handleValueChanged}
                  />
                </div>
                <div className="w-full mt-3">
                  <GlimpseInput
                    value={userInfo.confirm_password}
                    disabled={false}
                    showLabel={true}
                    label="Confirm Password"
                    id="confirm_password"
                    name="confirm_password"
                    type="password"
                    placeholder=" Confirm Password*"
                    onDataChanged={handleValueChanged}
                  />
                </div>
              </div>

              <div className="flex items-center justify-center ">
                <input
                  id="terms_checkbox"
                  type="checkbox"
                  aria-label="terms_checkbox"
                  name="terms-checkbox"
                  required={true}
                  className="w-4 h-4 rounded text-green focus:ring-blue-500 dark:focus:ring-blue-600 focus:ring-2"
                />
                <label
                  htmlFor="terms-checkbox"
                  className="text-sm font-medium ms-2"
                >
                  <span>I agree to the Glimpse </span>
                  <span className="underline cursor-pointer text-green underline-offset-2">
                    Terms of Service
                  </span>
                </label>
              </div>
              <button
                type="submit"
                className="text-white w-full text-center text-base font-semibold leading-4 tracking-wide capitalize whitespace-nowrap justify-center items-center bg-green self-stretch mt-6 px-16 py-3 rounded-[100px] max-md:px-5"
              >
                Create Account
              </button>
            </form>*/}
          </div>
        </GlimpseCard>
      </div>
    </>
  );
};

export default RegisterUser;
