import {
  ICSVUploadData,
  ICSVUploadDataPayload,
  IEmailList,
  IInviteMemberList,
  IReinviteMember,
  IReinviteMemberResponse,
} from "interfaces/AddPartnersInterface";
import {
  COACHESCORNERPDF,
  INVITE_MEMBERS,
  MEMBERS,
  PARTNER,
} from "./endpoint-urls";
import http from "./http-service";
import { IDownloadReport, IPagination } from "interfaces/BaseInterface";
import { IMemberFullInfo, IMembersList } from "interfaces/MembersInterface";
import { FileType } from "interfaces/MediaPickerInterface";

const formDataHeader = {
  "Content-Type": "multipart/form-data",
};

export const getAllMembers = (payload: IPagination) => {

  return http
    .get<IMembersList>(
      `${MEMBERS}?page=${payload.page}&page_size=${payload.page_size}&top_ten=${payload.top_ten}&report=${payload.report}&top_three=${payload.top_three}&status=${payload.status}&search=${payload.search}&type=${payload.type}&ordering=${payload.ordering}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getMemberInfobyId = (id: string) => {
  return http.get<IMemberFullInfo>(`${MEMBERS}/${id}`).then((res) => {
    return res.data;
  });
};

export const downloadCoachesCorderPDF = () => {
  return http.post<any>(`${COACHESCORNERPDF}`).then((res) => {
    return res.data;
  });
};

export const inviteMembersWithList = (members: IEmailList) => {
  return http
    .post<IInviteMemberList>(`${INVITE_MEMBERS}`, members)
    .then((res) => {
      return res.data;
    });
};

export const downloadReportFile = (reportParam: IDownloadReport) => {
  return http
    .post<Blob>(`${MEMBERS}/report`, reportParam, {
      responseType: "blob",
    })
    .then((res) => {
      return res.data;
    });
};

export const downloadReportFilePartners = (reportParam: IDownloadReport) => {
  return http
    .post<Blob>(`${PARTNER}/report`, reportParam, {
      responseType: "blob",
    })
    .then((res) => {
      return res.data;
    });
};

export const inviteWithCSVFile = (file: FileType) => {
  return http
    .post<ICSVUploadData>(`${INVITE_MEMBERS}/csv`, file, {
      headers: formDataHeader,
    })
    .then((res) => {
      return res.data;
    });
};

export const inviteMembersFromCSVUpload = (members: ICSVUploadDataPayload) => {
  return http
    .post<IReinviteMemberResponse>(`${INVITE_MEMBERS}/csv-emails`, members)
    .then((res) => {
      return res.data;
    });
};

export const reInviteMember = (email: IReinviteMember) => {
  return http
    .post<IReinviteMemberResponse>(`${INVITE_MEMBERS}/re`, email)
    .then((res) => {
      return res.data;
    });
};

// delete member
export const deleteMember = (id: string) => {
  return http.delete(`${MEMBERS}/${id}`).then((res) => {
    return res.data;
  });
};

export const deleteInviteMember = (id: string) => {
  return http.delete(`${INVITE_MEMBERS}/${id}/delete`).then((res) => {
    return res.data;
  });
};
