import { useMutation, useQuery } from "@tanstack/react-query";
import { displaySuccess } from "components/Toast/toast.component";
import GlimpseCard from "components/card/card.component";
import PaymentScreen from "components/stripe-elements/payment.screen";
import { IRenewSubscription } from "interfaces/subscription.interface";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { logout } from "services/auth.service";
import { getClientSecret } from "services/payment.service";
import { getEndUserData } from "services/setting.service";

const RenewSubscription: React.FC<IRenewSubscription> = ({ dialogue }) => {
  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data: paymentData, status } = useQuery({
    queryKey: ["getClientSecret"],
    queryFn: getClientSecret,
    refetchOnWindowFocus: false,
    retry: 0
  });

  const { data: endUserInfo, refetch } = useQuery({
    queryKey: ["getEndUserData"],
    queryFn: getEndUserData,
    refetchOnWindowFocus: false,
    enabled: false,
    retry: 0,
  });

  useEffect(() => {
    if (endUserInfo) {
      const localStorageValue = localStorage.getItem("userInfo");
      if (localStorageValue) {
        const userInfo: any = JSON.parse(localStorageValue);
        userInfo.user = endUserInfo;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        window.location.reload();
      }
    }
  }, [endUserInfo])

  // const logoutUser = useMutation({
  //   mutationFn: () => logout(),
  //   onSuccess: async () => {
  //     await localStorage.removeItem("userInfo");
  //     window.location.reload();
  //   },
  // });

  const handleSubmit = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    // logoutUser.mutateAsync();
    await localStorage.removeItem("userInfo");
    window.location.reload();
  };

  return (
    <div className="flex items-center self-center justify-center h-full py-10 bg-background">
      {dialogue ? (
        <>
          {openPayment ? (
            <PaymentScreen
              onGoback={() => {
                navigate(-1);
              }}
              clientSecret={paymentData?.client_secret}
              onSuccess={() => {
                displaySuccess("Payment has been completed. You can now login to you account!");
                refetch()
              }}
              priceInfo={paymentData}
            />
          ) : (
            <div className="flex items-center justify-center bg-background ">
              <GlimpseCard className="min-w-[330px] max-w-[347px] h-[100px] bg-white p-4">
                <div className="text-xl text-headerBlue">
                  It looks like your membership has expired!
                </div>
                <span className="text-headerBlack">
                  Hurry! Don’t lose access.
                </span>

                <div className="flex justify-between w-full gap-3 pt-10">
                  <button
                    onClick={(ev: React.MouseEvent<HTMLButtonElement>) =>
                      handleSubmit(ev)
                    }
                    className="text-center w-[110px] bg-transparent border border-headerBlue text-headerBlue gap-3 h-[40px]  rounded-full text-sm font-normal leading-5"
                  >
                    No Thanks
                  </button>
                  <button
                    className="text-white text-center bg-green gap-3 h-[40px] w-[122px] rounded-full text-sm font-normal leading-5"
                    onClick={() => setOpenPayment(true)}
                  >
                    Renew Now!
                  </button>
                </div>
              </GlimpseCard>
            </div>
          )}
        </>
      ) : (
        <PaymentScreen
          onGoback={() => {
            navigate(-1);
          }}
          clientSecret={paymentData?.client_secret}
          onSuccess={() => {
            displaySuccess("Payment has been completed. You can now login to you account!");
            refetch()
          }}
          priceInfo={paymentData}
        />
      )}
    </div>
  );
};

export default RenewSubscription;
