import { Bounce, ToastContainer, ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const toastConfig: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
};
export const GlimpseToastContainer: React.FC = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        progressClassName={""}
      />
      <ToastContainer />
    </>
  );
};
export const displayError = (message: string | undefined) => {
  toast.error(<div className="first-letter:uppercase"><span>{message}</span></div>, toastConfig);
};

export const displayWarning = (message: string | undefined) => {
  toast.warn(<div className="first-letter:uppercase"><span>{message}</span></div>, toastConfig);
};
export const displaySuccess = (message: string | undefined) => {
  toast.success(<div className="first-letter:uppercase"><span>{message}</span></div>, toastConfig);
};
export default GlimpseToastContainer;
