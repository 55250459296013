import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateHeader from "../components/header/private-header.component";
import { Suspense } from "react";
import Loading from "../components/loading/loading.component";
import RenewSubscription from "screens/renew-subscription/renew-screen";

const ProtectedRoutes = () => {
  const { userInfo } = useSelector((state: any) => state.auth);
  return (
    <>
      {!userInfo ? (
        <Navigate to="/login" replace />
      ) : (
        <>
          {userInfo.user.role === "super" ||
            userInfo.user.role === "partner" ||
            (userInfo.user.is_account_activated &&
              userInfo.user.profile?.onboarding_completed) ? (
            <>
              <PrivateHeader userInfo={userInfo.user} key={userInfo.user.id} />

              <div className="p-5 pt-10 sm:p-10 sm:ml-64">
                <div className="mt-14">
                  <Suspense fallback={<Loading />}>
                    <Outlet />
                  </Suspense>
                </div>
              </div>
            </>
          ) : (
            <>
              {userInfo.user.role === "user" && (
                <>
                  {/* For User gifted */}
                  {userInfo.user.subscription.gift_by !== null &&
                    !userInfo.user.profile?.onboarding_completed && (
                      <Navigate to="/on-boarding" replace />
                    )}
                  {/* For User who have not paid yet */}
                  {userInfo.user.has_subscription_expired === false &&
                    userInfo.user.is_account_activated === false && (
                      <RenewSubscription dialogue={false} />
                    )}

                  {/* For Existing User whose payment should be renewed */}
                  {userInfo.user.has_subscription_expired === true &&
                    userInfo.user.is_account_activated === false && (
                      <RenewSubscription dialogue={true} />
                    )}

                  {userInfo.user.is_account_activated &&
                    !userInfo.user.profile?.onboarding_completed && (
                      <Navigate to="/on-boarding" replace />
                    )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProtectedRoutes;
