import Tabs from "components/tabs/tabs.component";
import { useState } from "react";
import FileUploader from "../file-uploader/file-uploader.component";
import {
  inviteMembersFromCSVUpload,
  inviteMembersWithList,
  inviteWithCSVFile,
} from "services/members.service";
import { useMutation } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import AddMemberEmailList from "./AddMemberEmailList";
import {
  ICSVUploadData,
  ICSVUploadDataPayload,
  IEmailList,
  IGlimpseAddMemberProps,
  IInviteCSVList,
} from "interfaces/AddPartnersInterface";
import { displayError, displaySuccess } from "components/Toast/toast.component";
import { FileType } from "interfaces/MediaPickerInterface";

const GlimpseAddMember: React.FC<IGlimpseAddMemberProps> = ({
  onClose,
  // onUpload,
  tablist,
  next,
  setNext,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [uploadDataInfo, setUploadDataInfo] = useState<ICSVUploadData>();
  const [file, setFile] = useState<FileType | undefined>();
  const tabChanged = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    setActiveTab(index);
  };

  const handleEmailCSV = useMutation({
    mutationFn: (file: FileType) => inviteWithCSVFile(file),
    retry: 0,
    onSuccess: (data: ICSVUploadData) => {
      setUploadDataInfo(data);
    },
    onError: () => {
      displayError(`CSV file upload has been failed`);
    },
  });

  const handleSubmitEmailCSV = (file: FileType | undefined) => {
    if (file) {
      handleEmailCSV.mutateAsync(file);
    } else {
      return;
    }
  };

  //csv invite members
  const handleEmailList = useMutation({
    mutationFn: (members: ICSVUploadDataPayload) =>
      inviteMembersFromCSVUpload(members),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Invitation sent succesfully");
      onClose();
      setNext(false);
    },
    onError: () => {
      displayError("Sending invitation has been failed.");
    },
  });

  const handleSubmitEmailList = () => {
    if (uploadDataInfo) {
      const payload = {
        emails: uploadDataInfo.new_invites,
      };
      handleEmailList.mutateAsync(payload);
    }
  };

  return (
    <div className="max-h-[440px] ">
      <>
        {!next && (
          <Tabs
            items={tablist}
            onClick={tabChanged}
            activeIndex={activeTab}
            className="flex"
          />
        )}

        <div className="px-4 py-1">
          {activeTab === 0 && (
            <AddMemberEmailList
              next={next}
              setNext={setNext}
              onClose={onClose}
            />
          )}
          {activeTab === 1 && (
            <>
              {!next ? (
                <div>
                  <div className="text-xl text-headerBlue">
                    Invite a new member to Glimpse. Each invitation will use one
                    license.
                  </div>
                  <div>
                    <FileUploader
                      file={file}
                      isUploading={handleEmailCSV.isPending}
                      onUpload={(data: FileType) => {
                        setFile(data);
                        handleSubmitEmailCSV(data);
                      }}
                      type=".csv"
                    />
                    <div className="pt-2 text-headerBlue w-fit">
                      <Link
                        to={require("../../files/invite_emails.csv")}
                        download="Glimpse Mass Upload File Sample.csv"
                        target="_blank"
                        rel="noreferrer"
                        className="flex gap-2"
                      >
                        <span className="text-sm">Download Template</span>
                        <span>
                          <svg
                            width={16}
                            height={14}
                            viewBox="0 0 16 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.3998 9.30793V11.07C14.3998 11.5163 14.0416 11.8779 13.5998 11.8779H2.39996C1.9581 11.8779 1.59997 11.5163 1.59997 11.07V9.30793C1.59997 8.86168 1.24185 8.5 0.799987 8.5C0.358127 8.5 0 8.86168 0 9.30793V11.3431C0 12.5343 0.956251 13.5 2.1357 13.5H13.8643C15.0437 13.5 16 12.5343 16 11.3431V9.30793C16 8.86168 15.6419 8.5 15.2 8.5C14.7582 8.5 14.4 8.86168 14.4 9.30793H14.3998Z"
                              fill="#60849F"
                            />
                            <path
                              d="M8.91783 8.06588H6.71777V1.60016C6.71777 0.992667 7.21017 0.5 7.81794 0.5C8.42543 0.5 8.9181 0.992398 8.9181 1.60016V8.06614L8.91783 8.06588Z"
                              fill="#60849F"
                            />
                            <path
                              d="M12.8738 4.47465C12.4441 4.04491 11.7476 4.04491 11.3181 4.47465L7.81756 7.97522L4.31699 4.47465C3.88725 4.04491 3.19074 4.04491 2.76127 4.47465C2.33153 4.90439 2.33153 5.6009 2.76127 6.03037L7.16192 10.431C7.52416 10.7933 8.11122 10.7933 8.47346 10.431L12.8741 6.03037C13.3039 5.60063 13.3039 4.90412 12.8741 4.47465H12.8738Z"
                              fill="#60849F"
                            />
                          </svg>
                        </span>
                      </Link>
                    </div>
                  </div>
                  {file && (
                    <div className="flex flex-col gap-1 text-headerBlack">
                      <div className="flex justify-between">
                        <span>Emails Found:</span>
                        <span className="font-bold text-headerBlue">
                          {uploadDataInfo && uploadDataInfo.total_emails}
                        </span>
                      </div>
                      <div className="flex justify-between">
                        <span>Duplicates Found:</span>
                        <span className="font-bold text-headerBlue">
                          {uploadDataInfo && uploadDataInfo.duplicate_emails}
                        </span>
                      </div>
                      <span className="text-[10px]">
                        Only one invitation will be sent ...
                      </span>
                    </div>
                  )}
                  <div className="flex justify-between mt-5 ">
                    <button
                      onClick={() => {
                        onClose();
                        setNext(false);
                      }}
                      className="text-headerBlue text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-headerBlue"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => setNext(true)}
                      className="text-white text-center bg-green w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="button">
                    <button
                      type="button"
                      onClick={() => setNext(false)}
                      className="text-black text-center bg-horizontalRow gap-3 mb-4 w-[57px] h-[20px] rounded-md text-sm font-normal leading-5 text-[10px] pl-1"
                    >
                      <div className="flex items-center justify-center">
                        <svg
                          width="12"
                          height="8"
                          viewBox="0 0 12 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z"
                            fill="#3A3A3A"
                          />
                        </svg>
                        <span className="pl-1 font-semibold">Back</span>
                      </div>
                    </button>
                  </div>
                  <div className="py-2 text-xl text-headerBlue">
                    Send invitations to join Glimpse
                  </div>
                  <div className="py-2 text-sm text-headerBlack">
                    An invitation email from Glimpse will be sent to each email
                    address entered. Do you want to proceed?
                  </div>
                  <div className="flex justify-between mt-5 ">
                    <button
                      type="button"
                      onClick={() => {
                        onClose();
                        setNext(false);
                      }}
                      className="text-headerBlue text-center bg-white w-[119px] h-[40px] rounded-full text-sm font-bold leading-5 border border-headerBlue"
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      onClick={handleSubmitEmailList}
                      className="text-white text-center bg-green w-[119px] h-[40px] rounded-full text-sm font-normal leading-5"
                    >
                      Yes, Send Invites
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default GlimpseAddMember;
