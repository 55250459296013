import VerticalCarousel from "components/scroller/scroller";
import VerticalCarouselEdit from "./slider";

const ProfileScreen = () => {
  const slides = [
    { id: 1, content: 'Slide 1', image: '/assets/images/Expert_voice.png' },
    { id: 2, content: 'Slide 2', image: '/assets/images/Expert_voice.png' },
    { id: 3, content: 'Slide 3', image: '/assets/images/Expert_voice.png' },
  ];
  return (
    <>
      {/* <VerticalCarousel></VerticalCarousel> */}
    </>
  );
};

export default ProfileScreen;
