export const BASE_URL = process.env.REACT_APP_BASE_URL as string;
export const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL as string;
export const STRIPE_KEY = process.env.REACT_APP_ADD_STRIPE_KEY as string;

// Authencation URLs
export const LOGIN_USER: string = `${BASE_URL}/auth/login`;
export const LOGOUT_USER: string = `${BASE_URL}/auth/logout`;
export const REGISTER_USER: string = `${BASE_URL}/auth/signup`;
export const REFRESH_TOKEN: string = `${BASE_URL}/auth/token-refresh`;
export const SUBSCRIPTIONS: string = `${BASE_URL}/subscriptions`;
export const CHANGE_PASSWORD: string = `${BASE_URL}/auth/change-password`;
export const FORGOT_PASSWORD: string = `${BASE_URL}/auth/forgot-password`;
export const RESET_PASSWORD: string = `${BASE_URL}/auth/reset-password`;

// Career URLs
export const CAREER_ENDPOINT: string = `${BASE_URL}/careers`;
export const MY_CAREER: string = `${BASE_URL}/my-careers`;
export const FILE_ENDPOINT: string = `${BASE_URL}/files`;
export const COUNT_REPORT: string = `${BASE_URL}/my-careers/count-report`;
export const PRIORITIZE_CAREER: string = `${BASE_URL}/careers/prioritize`;

// Partners URL
export const PARTNER: string = `${BASE_URL}/partners`;
export const UNLOCK_ONBOARD: string = `${BASE_URL}/my-careers/unlock`;
export const UNLOCK_NEXT_CAREER: string = `${BASE_URL}/my-careers/unlock-next`;
export const START_ONBOARD: string = `${BASE_URL}/on-boarding/careers`;
export const COMPLETE_ONBOARD: string = `${BASE_URL}/on-boarding/complete`;
export const LIST_PRIORITIZE: string = `${BASE_URL}/my-careers/list-prioritize`;

export const PARTNER_DASHBOARD: string = `${BASE_URL}/admin-partners-overview-data`;
export const USER_PARTNER_DASHBOARD: string = `${BASE_URL}/partner-dashboard-data`;

// Members URL
export const MEMBERS: string = `${BASE_URL}/members`;
export const INVITE_MEMBERS: string = `${BASE_URL}/invite-members`;
export const COACHESCORNERPDF: string = `${BASE_URL}/coaches-corner/pdf`;

// EndUser Profile Setting URLs
export const ENDUSERDATA: string = `${BASE_URL}/auth/me`;
export const PARTNER_ENDUSERDATA: string = `${BASE_URL}/partners/me`;

//end user share count
export const SHARECAREER: string = `${BASE_URL}/my-careers/share-career`;

//Payment URLs
export const PAYMENT_INTENT: string = `${BASE_URL}/create-payment-intent`;
export const CLIENT_SECRET: string = `${BASE_URL}/get-client-secret`;

// Gift Invitation URL
export const GIFT_INVITATION: string = `${BASE_URL}/gift-invitation`;
export const REDEEM_CODE: string = `${BASE_URL}/validate-gift-invitation`;

// Dashboard Ususage URLs
export const BAR_USUAGE_DATA: string = `${BASE_URL}/daily-usage`;
export const DOUGHNUT_USUAGE_DATA: string = `${BASE_URL}/membership-type-count`;
export const MOST_POPULAR_CAREER: string = `${BASE_URL}/get-most-popular-career`;
export const ADMIN_BADGES: string = `${BASE_URL}/get-badges-report`;
export const ADMIN_DASHBOARD: string = `${BASE_URL}/admin-dashboard-data`;
export const ADMIN_INDIVIDUAL_DASHBOARD: string = `${BASE_URL}/admin-members-overview-data`;
export const ADMIN_INDIVIDUAL_DASHBOARD_INSIGHT: string = `${BASE_URL}/members-overview`;

// Help center URL
export const HELP_CENTER: string = `${BASE_URL}/email/help-center`;

// Setting Module quiz for partner
export const MODULE_QUIZ: string = `${BASE_URL}/partners/module-quiz`;

// my Career Awareness ranking
export const USER_BADGES_REPORT: string = `${BASE_URL}/my-badges-report`;

// user careers main screen data
export const USER_DASHBOARD_DATA: string = `${BASE_URL}/user-dashboard-data`;


//promo code endpoints
export const PROMO: string = `${BASE_URL}/coupons`;
export const PROMO_APPLY: string = `${BASE_URL}/coupon-apply`;
