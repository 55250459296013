import { Outlet } from "react-router-dom";
import PublicHeader from "../components/header/public-header.component";
import { Suspense } from "react";
import Loading from "../components/loading/loading.component";

const DefaultLayout = () => {
  return (
    <>
      <PublicHeader />
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </>
  );
};

export default DefaultLayout;
