import {
  IAddPartner,
  IPartnerInsight,
  IPartnerResult,
  IPartnersList,
} from "interfaces/AddPartnersInterface";
import http from "./http-service";
import {
  ADMIN_BADGES,
  ADMIN_DASHBOARD,
  ADMIN_INDIVIDUAL_DASHBOARD,
  ADMIN_INDIVIDUAL_DASHBOARD_INSIGHT,
  BAR_USUAGE_DATA,
  DOUGHNUT_USUAGE_DATA,
  MEMBERS,
  MOST_POPULAR_CAREER,
  PARTNER,
  PARTNER_DASHBOARD,
  USER_PARTNER_DASHBOARD,
} from "./endpoint-urls";
import { IPagination } from "interfaces/BaseInterface";
import {
  IAdminIndividualOverview,
  IBadgeReport,
  IBarDataGet,
  IDailyUsage,
  IDashboardOverview,
  IDoughnutData,
  IIndividualInsightCareer,
  IIndividualInsightCareerList,
  IIndividualInsightOverviewData,
  IMostPopularGlimpseResponse,
  IPartnersDashboardOverview,
  IUserPartnerDashboardOverview,
} from "interfaces/dashboard.interface";

export const addNewPartner = (partners: IPartnerResult) => {
  return http.post<IPartnerResult>(PARTNER, partners).then((res) => {
    return res.data;
  });
};

export const updatePartnerInfo = (partners: IPartnerResult) => {
  return http
    .put<IPartnerResult>(`${PARTNER}/${partners.id}`, partners)
    .then((res) => {
      return res.data;
    });
};

export const getAllPartners = (payload: IPagination) => {
  return http
    .get<IPartnersList>(
      `${PARTNER}?page=${payload.page}&page_size=${payload.page_size}&top_ten=${payload.top_ten}&report=${payload.report}&search=${payload.search}&type=${payload.type}&ordering=${payload.ordering}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getPartnerById = (id: string) => {
  return http.get<IPartnerInsight>(`${PARTNER}/${id}`).then((res) => {
    return res.data;
  });
};

export const getPartnermembers = (payload: IPagination, id: string) => {
  return http
    .get<IPartnersList>(
      `${PARTNER}/${id}/members?page=${payload.page}&page_size=${payload.page_size}&search=${payload.search}&status=${payload.status}&top_three=${payload.top_three}`
    )
    .then((res) => {
      return res.data;
    });
};
export const getPartnerDashboardData = () => {
  return http
    .get<IPartnersDashboardOverview>(`${PARTNER_DASHBOARD}`)
    .then((res) => {
      return res.data;
    });
};

// Admin Dashboard Daily Usuage Report

export const getBarDailyUsuage = (data: IBarDataGet) => {
  return http
    .get<IDailyUsage>(
      `${BAR_USUAGE_DATA}?start_date=${data.start_date}&user_type=${data.user_type}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getDoughnutDailyUsuage = () => {
  return http.get<IDoughnutData>(`${DOUGHNUT_USUAGE_DATA}`).then((res) => {
    return res.data;
  });
};

export const getMostPopularCareer = () => {
  return http
    .get<IMostPopularGlimpseResponse>(`${MOST_POPULAR_CAREER}`)
    .then((res) => {
      return res.data.most_repeated_career;
    });
};
export const getCareerBadgesAdmin = () => {
  return http.get<IBadgeReport[]>(`${ADMIN_BADGES}`).then((res) => {
    return res.data;
  });
};
export const getDashboardData = () => {
  return http.get<IDashboardOverview>(`${ADMIN_DASHBOARD}`).then((res) => {
    return res.data;
  });
};

// admin individual dashboard overview
export const getIndividualDashboardData = () => {
  return http
    .get<IAdminIndividualOverview>(`${ADMIN_INDIVIDUAL_DASHBOARD}`)
    .then((res) => {
      return res.data;
    });
};

// admin individual insight overview
export const getIndividualInsightOverviewData = (user_id: string) => {
  return http
    .get<IIndividualInsightOverviewData>(
      `${ADMIN_INDIVIDUAL_DASHBOARD_INSIGHT}/${user_id}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getIndividualCareerData = (
  param: string,
  careerStatus: string
) => {
  return http
    .get<IIndividualInsightCareerList>(
      `${MEMBERS}/${param}/careers?${careerStatus}=true`
    )
    .then((res) => {
      return res.data;
    });
};

// user_partner dashboard
export const getUserPartnerDashboardData = () => {
  return http
    .get<IUserPartnerDashboardOverview>(`${USER_PARTNER_DASHBOARD}`)
    .then((res) => {
      return res.data;
    });
};

// delete partner
export const deletePartner = (id: string) => {
  return http.delete(`${PARTNER}/${id}`).then((res) => {
    return res.data;
  });
};
