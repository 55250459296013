import { IPriceInfo } from "interfaces/PaymentInterface";
import { ICode } from "interfaces/RegisterInterface";
import { IGiftInvitation } from "interfaces/user.interface";
import { GIFT_INVITATION, REDEEM_CODE } from "./endpoint-urls";
import http from "./http-service";

export const sendGiftInvitation = (giftData: IGiftInvitation) => {
  return http.post<IPriceInfo>(`${GIFT_INVITATION}`, giftData).then((res) => {
    return res.data;
  });
};

export const validateGiftInvitation = (code: ICode) => {
  return http.post<any>(`${REDEEM_CODE}`, code).then((res) => {
    return res.data;
  });
};
