import React, { useState } from "react";

import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToWindowEdges, } from "@dnd-kit/modifiers"
import { arrayMove } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import { Column } from "./sortable-task";
import { Task } from "./dragable.component";
import "./drag-drop.css";
import { IGlimpseDragDrop } from "interfaces/DragDrop.interface";

import { IMyCareersPrioritize } from "interfaces/OnbaordingInterface";
import { CareerCategory } from "enums/glimps.enums";

const DragAndDrop: React.FC<IGlimpseDragDrop> = ({
  data,
  displayUnsorted,
  onboardingSorted,
  onDropped,
}) => {
  const [render, setRender] = useState<number>(0);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 10,
      distance: 10,
      tolerance: 15,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 10,
      distance: 10,
      tolerance: 15,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);
  // const sensors = useSensors(
  //   useSensor(MouseSensor, {
  //     activationConstraint: {
  //       distance: 8,
  //     },
  //   }),
  //   useSensor(TouchSensor, {
  //     activationConstraint: {
  //       distance: 8,
  //     },
  //   })
  // );

  const [columns, setColumns] = useState([
    {
      category: CareerCategory.unsorted,
      title: "Unsorted",
      subtitle: "You have sorted all of the careers.",
    },
    {
      category: CareerCategory.top_three,
      title: "Top 3",
      subtitle: "Highest potential for my future",
    },
    {
      category: CareerCategory.watch_list,
      title: "Watch List",
      subtitle: "Have some potential for my future",
    },
    {
      category: CareerCategory.discard,
      title: "Discard",
      subtitle: "Not in my future",
    },
  ]);

  const [tasks, setTasks] = useState<IMyCareersPrioritize[]>(data);

  const [activeTask, setActiveTask] = useState(null);

  const onDragStart = (event: any) => {
    if (event.active.data.current?.type === "Task") {
      setActiveTask(event.active.data.current.task);
      return;
    }
  };
  const onDragOver = (event: any) => {
    // The active and over represent the card which we have holded n
    // over is the card we are hovering on top of

    const { active, over } = event;
    if (!over) return;
    if (
      over.data.current.task?.category === "top_three" &&
      over.data.current.sortable.items.length > 3
    ) {
      // displayError("Your top 3 list is full.");
      return;
    }
    const activeId = active.id;
    const overId = over.id;
    // Check if picked element and the element we hover on is same
    // if it's same then kuch change nhi karna hai right? It's literally same position
    if (activeId === overId) return;
    const isActiveATask = active.data.current?.type === "Task";
    const isOverATask = over.data.current?.type === "Task";
    if (!isActiveATask) return;
    //We check for 2 cases
    // I am dropping a task over another task
    if (isActiveATask && isOverATask) {
      setTasks((task) => {
        // Finding indexes here as we need to shuffle them
        // activeId is same because we always give draggable id remember?
        const activeIndex = task.findIndex((t) => t.id === activeId);
        const overIndex = task.findIndex((t) => t.id === overId);
        // If we drop the task on a task on different column, just gotta check overId
        // We can also
        if (task[activeIndex].category !== task[overIndex].category) {
          task[activeIndex].category = task[overIndex].category;
        }
        // This is a smooth inbuilt function which switches positions of
        // 2 elements in an array based on index given.
        return arrayMove(task, activeIndex, overIndex);
      });
    }
    // I am dropping a task over another column i.e when no tasks are there in column
    const isOverAColumn = over.data.current?.type === "Column";
    if (isActiveATask && isOverAColumn) {
      setTasks((tasks) => {
        const activeIndex = tasks.findIndex((t) => t.id === activeId);
        tasks[activeIndex].category = overId;
        // The reason we are using arrayMove with same 2 indexes is bc we get new array
        return arrayMove(tasks, activeIndex, activeIndex);
      });
    }
  };
  const dragEnd = (event: DragEndEvent) => {
    setActiveTask(null);
    onDropped(event.active.data.current?.task as IMyCareersPrioritize, tasks);
  };
  const unsortedLength = tasks.filter(
    (task) => task?.category === CareerCategory.unsorted
  ).length;

  return (
    <>
      {unsortedLength === 0 && !onboardingSorted && (
        <span className="px-5 text-2xl font-bold text-headerBlue">Sorted</span>
      )}
      <div
        className={`flex flex-col w-full p-0 ${!onboardingSorted && "sm:p-4"
          } m-auto overflow-x-auto overflow-y-hidden select-none`}
      >
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnd={dragEnd}
          modifiers={[restrictToWindowEdges]}
        >
          <section className="relative grid content-center grid-cols-3 gap-4">
            {columns.map((col, index) => (
              <>
                <div
                  className={`first:col-span-3 first:shadow-none rounded-lg shadow-card first:fixed md:first:relative first:h-auto first:z-10  first:bottom-0 first:w-full overflow-auto   ${onboardingSorted
                    ? "col-span-3 bg-white !min-h-[100px] first:bg-background first:order-first first:relative"
                    : `col-span-3 lg:col-span-1 first:order-last first:bg-white md:first:order-first md:first:-ml-[7px] ${unsortedLength !== 0 && " md:first:mt-0 md:mt-10"
                    }  ${col.category === CareerCategory.top_three
                      ? ""
                      : "md:h-[471px]"
                    } `
                    }
                 ${!onboardingSorted &&
                      col.category === CareerCategory.unsorted &&
                      unsortedLength === 0
                      ? `first:hidden`
                      : `${unsortedLength !== 0 &&
                      !onboardingSorted &&
                      "sorted_with_unsorted"
                      }`
                    }`}
                >
                  <Column
                    column={col}
                    key={col.category}
                    tasks={tasks.filter(
                      (task) => task.category === col.category
                    )}
                    totalTopThree={
                      tasks.filter(
                        (task) => task.category === CareerCategory.top_three
                      ).length
                    }
                    onboardingSorted={onboardingSorted}
                  />
                </div>
              </>
            ))}
          </section>
          {createPortal(
            <DragOverlay>
              {activeTask && (
                <Task
                  task={activeTask}
                  index={0}
                  totalTopThree={0}
                  onboardingSorted={onboardingSorted}
                />
              )}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </div>
    </>
  );
};

export default DragAndDrop;
