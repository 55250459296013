import axios from "axios";
import {
  IResetPasswordMemberPayload,
  IResetPasswordPartnerPayload,
  IResetPasswordResponse,
} from "interfaces/resetPassword.interface";
import { MEMBERS, PARTNER } from "./endpoint-urls";

// admin and partner changing password of member
export const resetMemberPassword = (data: IResetPasswordMemberPayload) => {
  return axios
    .post<IResetPasswordResponse>(
      `${MEMBERS}/${data.id}/reset-password`,
      data.data
    )
    .then((res) => {
      return res.data;
    });
};

// admin changing password of partner
export const resetPartnerPassword = (data: IResetPasswordPartnerPayload) => {
  return axios
    .post<IResetPasswordResponse>(
      `${PARTNER}/${data.id}/reset-password`,
      data.data
    )
    .then((res) => {
      return res.data;
    });
};
