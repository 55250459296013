import React from "react";

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const GlimpseCheckbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
}) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={checked}
        onChange={onChange}
      />
      <div className="w-6 h-3 bg-[#1F2122] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-[60%] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-headerBlue after:content-[''] after:absolute after:start-[2px] after:bg-white after:border-headerBlue after:border after:rounded-full after:h-3 after:w-3 after:transition-all peer-checked:bg-blue-600 bg-headerBlue"></div>
      <span className="font-medium ms-3 text-md">{label}</span>
    </label>
  );
};

export default GlimpseCheckbox;
