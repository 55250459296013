import React from "react";
import { Chart as ChartJS, Tooltip, Legend, ChartData } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { Bar } from "react-chartjs-2";
interface IBarChartProps {
  data: ChartData<"bar">;
  salaryChart?: boolean;
}

const BarChart: React.FC<IBarChartProps> = ({ data, salaryChart }) => {
  ChartJS.register(Tooltip, Legend, ChartDataLabels);
  const chartOptions = {
    responsive: true,
    aspectRatio: 1,
    maintainAspectRatio: true,
    scales: {
      x: {
        ticks: {
          font: {
            size: 9,
            weight: 600,
            family: "'Nunito', sans-serif",
          },
        },
        title: {
          display: salaryChart ? false : true,
          text: "Days",
          font: {
            size: 10,
            weight: 600,
            family: "'Nunito', sans-serif",
          },
          padding: {
            top: 0,
            bottom: 0,
          },
        },
        grid: {
          drawOnChartArea: false,
          drawBorder: false,
          display: false,
        },
      },
      y: {
        ticks: {
          font: {
            size: 10,
            weight: 600,
            family: "'Nunito', sans-serif",
          },
        },
        grid: {
          drawOnChartArea: false,
          display: false,
          drawBorder: false,
        },

        title: {
          display: true,
          text: "Hours",
          font: {
            weight: 600,
            size: 10,
            family: "'Nunito', sans-serif",
          },
        },
        scaleLabel: {},
        display: salaryChart ? false : true,
      },
    },
    layout: {
      padding: {
        top: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      labels: { render: "text" },
      title: {
        display: true,
        text: "Char",
      },

      datalabels: {
        display: true,
        align: (context: any) => {
          const index = context.dataIndex;
          return (index || index === 0) && "end";
        },
        anchor: (context: any) => {
          const index = context.dataIndex;
          return (index || index === 0) && "end";
        },
        color: salaryChart ? "#529C82" : "#000",
        font: {
          size: salaryChart ? 10 : 8,
          weight: salaryChart ? 700 : 400,
          family: "'Nunito', sans-serif",
        },
        formatter: (value: number) => {
          let a = value.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          return `${salaryChart ? "$" : ""}${salaryChart ? a : value} ${
            salaryChart ? "" : "hrs"
          }`;
        },
      },
    },
  };

  return (
    <>
      <Bar
        data={{ datasets: data.datasets, labels: data.labels }}
        options={chartOptions}
      />
    </>
  );
};

export default BarChart;
