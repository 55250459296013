import Loading from "components/loading/loading.component";
import PriceTable from "components/price/price.component";
import { IChoosePlan } from "interfaces/RegisterInterface";
import { ISubscription } from "interfaces/subscription.interface";
import React from "react";
import { useNavigate } from "react-router-dom";

const ChoosePlan: React.FC<IChoosePlan> = ({
  status,
  onPlanSelect,
  subscriptionList,
  activeTab,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="topic">
        <h2 className="mt-5 text-2xl font-bold tracking-tight text-center md:text-4xl text-headerBlue">
          Let's Start Learning
        </h2>
        <p className="pt-2 text-sm font-bold tracking-tight text-center ">
          Confirm your plan below
        </p>
      </div>
      <div className="flex justify-center mt-5">
        {status === "pending" ? (
          <Loading />
        ) : status === "error" ? (
          <h1>Package Loading Failed. Please refresh the page</h1>
        ) : (
          <PriceTable
            items={subscriptionList || []}
            onSubscriptionSelect={(item: ISubscription, index: number) =>
              onPlanSelect(item, index)
            }
          />
        )}
      </div>
      <div className="flex justify-center mt-5">
        <p className="text-sm font-normal text-headerBlue">
          {activeTab === 0 ? (
            <span className="text-headerBlack">Already have a membership?</span>
          ) : (
            <span>Already have a subscription? </span>
          )}
          <span
            className="pl-1 font-bold underline cursor-pointer text-headerBlue underline-offset-2"
            onClick={() => navigate("/login")}
          >
            Login
          </span>
        </p>
      </div>
    </>
  );
};

export default ChoosePlan;
