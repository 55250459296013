import { useState } from "react";
import GlimpseTextarea from "../../components/textarea/glimpse-textarea.component";
import { IHelpCenter } from "interfaces/BaseInterface";
import { sendHelpCenterMessage } from "services/setting.service";
import { displayError, displaySuccess } from "components/Toast/toast.component";
import { useMutation } from "@tanstack/react-query";

const HelpCenterScreen: React.FC = () => {
  const [message, setMessage] = useState<IHelpCenter>({
    message: "",
  });
  const {
    data: helpMessage,
    isPending,
    mutateAsync: sendHelpAsync,
  } = useMutation({
    mutationFn: () => sendHelpCenterMessage(message),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Your message has been sent to glimpse team.");
      setMessage({ message: "" });
    },
  });
  // const newValue = message.message.replace(/\s/, "");

  return (
    <div>
      <div className="flex flex-col">
        <p className="text-xl font-normal leading-6 text-black">
          How can we help?
        </p>
        <p className="text-sm font-normal leading-5 text-gray">
          To contact someone on the Glimpse team, please fill out this form. We will respond within 1-2 business days.
        </p>
      </div>
      <div className="flex flex-col mt-[19px] max-w-[553px]">
        <p className="mb-1 text-xl font-semibold leading-4 text-black">
          Message
        </p>
        <GlimpseTextarea
          rows={6}
          name="help_content"
          id="help_content"
          value={message.message}
          onDataChanged={(ev) => {
            setMessage({ message: ev.target.value });
          }}
          placeholder="Message here..."
        />
      </div>
      <button
        type="button"
        disabled={isPending || message.message.length === 0}
        onClick={(e) => {
          if (message.message.trim()) {
            sendHelpAsync();
          }
          else {
            displayError("Please input you message")
          }
        }}
        className="text-center bg-green min-w-[120px] text-white text-base font-semibold leading-4 tracking-wide capitalize whitespace-nowrap items-stretch border self-stretch justify-center mt-[17px] py-3 rounded-[100px] border-solid max-md:pl-6 max-md:pr-6"
      >
        {isPending ? "Sending..." : "Send"}
      </button>
    </div>
  );
};

export default HelpCenterScreen;
