import http from "./http-service";
import { PROMO, PROMO_APPLY } from "./endpoint-urls";
import { IPromoCode, IPromoCodeBody, IPromoValidateDTO, IPromoValidateResponse } from "interfaces/BaseInterface";

export const getPromoCodes = () => {
  return http.get<IPromoCode[]>(`${PROMO}`).then((res) => {
    return res.data;
  });
};

export const addPromoCode = (promoCode: IPromoCodeBody) => {
  return http.post<IPromoCode>(`${PROMO}`, promoCode).then((res) => {
    return res.data;
  });
};

export const deletePromoCode = (id: string) => {
  return http.delete<void>(`${PROMO}/${id}`).then((res) => {
    return res.data;
  });
};


export const validatePromoCode = (body: IPromoValidateDTO)=> {
  return http.post<IPromoValidateResponse>(`${PROMO_APPLY}`,body).then((res) => {
    return res.data;
  });
};

