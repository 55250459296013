import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import InputError from "../input-error/input-error";

const GlimpseTextAreaBox = (props: any) => {
  const { label, name, id, type, maxLength, showCount, ...rest } = props;
  const { values, setFieldValue } = useFormikContext<any>();
  const [charCount, setCharCount] = useState<number>(
    typeof values[name] === "string" ? values[name].length : 0
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const currentValue = values[name];
    if (typeof currentValue === "string") {
      setCharCount(currentValue.length);
    }
  }, [values[name]]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    if (typeof value === "string") {
      if (value.length <= maxLength) {
        setCharCount(value.length);
        setFieldValue(name, value);
        setErrorMessage("");
      } else {
        setErrorMessage(`Maximum character limit of ${maxLength} exceeded.`);
      }
    }
  };

  return (
    <>
      <label
        htmlFor={id}
        className="block text-sm font-medium leading-4 text-gray-900"
      >
        {label}
      </label>
      <Field
        component="textarea"
        name={name}
        id={id}
        rows="4"
        onChange={handleChange}
        className={`block w-full min-h-[74px] p-3 mt-1 text-gray-900 border shadow-sm rounded-xl border-input_outline placeholder:text-headerBlack sm:text-sm sm:leading-6 text-headerBlack focus:ring-0 focus:border-input_outline ${
          errorMessage && "border border-red"
        } `}
        {...rest}
      ></Field>
      {showCount && (
        <span className="text-[16px] text-horizontalRow text-end relative left-[85%]  bottom-[30px]">
          {charCount}/{maxLength}
        </span>
      )}
      {errorMessage && (
        <span className="flex text-xs text-red text-start">{errorMessage}</span>
      )}

      <ErrorMessage name={name} component={InputError} />
    </>
  );
};

export default GlimpseTextAreaBox;
