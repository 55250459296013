import CareerItem from "components/career-item/career-item.component";
import React, { forwardRef } from "react";

export const DragableCareerItem = forwardRef(
  ({ url, index, faded, style, ...props }: any, ref) => {
    const inlineStyles = {
      opacity: faded ? "0.2" : "1",
      transformOrigin: "0 0",
      ...style,
    };

    return <div ref={ref} style={inlineStyles} {...props}><CareerItem isAdmin={true} icon_url={url.icon_url} id={url.id} name={url.name} showRanking={true} rank={index + 1} key={url.id} pressed={() => { }}></CareerItem></div>;
  }
);
