import DughnutComponent from "components/charts/doughnut.component";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tabs from "components/tabs/tabs.component";
import { ITabItem } from "components/tabs/tabs.interface";
import GlimpseCard from "components/card/card.component";
import CareerItem from "components/career-item/career-item.component";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteMember, getMemberInfobyId } from "services/members.service";
import {
  getIndividualCareerData,
  getIndividualInsightOverviewData,
} from "services/partners.service";
import {
  BadgesCount,
  IIndividualInsightCareer,
} from "interfaces/dashboard.interface";
import GlimpsePopup from "components/popup/popup.component";
import ResetPasswordMember from "components/reset-password/resetPasswordMember";
import { displayError, displaySuccess } from "components/Toast/toast.component";
import { IMemberFullInfo } from "interfaces/MembersInterface";
import { CareerCategory } from "enums/glimps.enums";
import Dropdown from "components/dropdown/dropdown.component";
import { useSelector } from "react-redux";

const tabsList: ITabItem[] = [
  { name: "Overview" },
  { name: "Top 10" },
  { name: "Video Previews" },
  { name: "Deeper Dives" },
];

const IndividualInsightScreen: React.FC = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.auth);
  const [activeTab, setActiveTab] = useState(0);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [displayPopupPasswordMember, setDisplayPopupPasswordMember] =
    useState(false);
  let { param } = useParams();
  const careerStatus =
    activeTab === 1
      ? "top_ten"
      : activeTab === 2
      ? "is_previewed"
      : activeTab === 3 && "is_completed";

  const {
    data: individualInfo,
    isLoading: isIndividualInfoLoading,
    refetch: refectIndividualInfo,
  } = useQuery({
    queryKey: ["GetIndivisualUserInfo", param],
    queryFn: () => getMemberInfobyId(param as string),
    enabled: !!param,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  // handlemember delete
  const handleDeleteMember = useMutation({
    mutationFn: (data: string) => deleteMember(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Member has been deleted successfully.");
    },
    onError: () => {
      displayError("Unable to delete member.");
    },
  });

  const handleDeleteMemberSubmit = (values: IMemberFullInfo) => {
    handleDeleteMember.mutateAsync(values.id);
  };

  const { data: individualInsightOverviewData } = useQuery({
    queryKey: ["individualInsightOverviewData", param],
    queryFn: () => getIndividualInsightOverviewData(param as string),
    enabled: !!param,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const { data: individualCareerData } = useQuery({
    queryKey: ["individualCareerData", param, careerStatus],
    queryFn: () =>
      getIndividualCareerData(param as string, careerStatus as string),
    enabled: !!careerStatus,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const tabChanged = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    setActiveTab(index);
  };

  // calculate center data
  const previewPercentage =
    individualInsightOverviewData?.preview_progress
      ?.preview_completed_percentage ?? 0;

  const previewCenterData = `${individualInsightOverviewData?.preview_progress.total_previewed_count}/${individualInsightOverviewData?.preview_progress.total_career_count}`;

  const deeperdivePercentage =
    individualInsightOverviewData?.deep_dive_progress
      .deep_dive_progress_percent ?? 0;

  const deeperdiveCenterData = ` ${individualInsightOverviewData?.deep_dive_progress.completed_deep_dive_career_count}/${individualInsightOverviewData?.deep_dive_progress.total_deep_dive_career_count}`;

  // OVERVIEW CHART PERCENTAGE
  const chartDataPreviewProgress = {
    datasets: [
      {
        data: [previewPercentage, 100 - previewPercentage],
        backgroundColor: ["#D4B07D", "#E2E3E6"],
      },
    ],
  };

  const chartDataDeeperDiveProgress = {
    datasets: [
      {
        data: [deeperdivePercentage, 100 - deeperdivePercentage],
        backgroundColor: ["#06A77D", "#E2E3E6"],
      },
    ],
  };
  const dropdownItems = [
    {
      label: "Reset Password",
      onClick: () => setDisplayPopupPasswordMember(true),
    },
    { label: "Delete", onClick: () => setDeleteAlert(true) },
  ];

  return (
    <>
      <div className="p-1">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-black text-center bg-horizontalRow gap-3 mb-4 w-[80px] h-[35px] rounded-full text-sm font-normal leading-5"
        >
          <div className="flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              viewBox="0 0 12 8"
              fill="none"
            >
              <path
                d="M11 4.5C11.2761 4.5 11.5 4.27614 11.5 4C11.5 3.72386 11.2761 3.5 11 3.5L11 4.5ZM0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659727 4.53553 0.464465C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z"
                fill="black"
              />
            </svg>
            <span className="pl-2 font-semibold">Back</span>
          </div>
        </button>
        <div className="flex flex-col gap-3 p-3 overflow-auto info w-100 ">
          <div className="flex flex-row gap-4">
            <div className="rounded-full w-[62px] h-[62px]">
              <img
                src={
                  individualInfo?.profile.avatar
                    ? individualInfo?.profile.avatar
                    : "/assets/icons/boy_with_glass.svg"
                }
                alt="User_Avatar"
              />
            </div>
            <div className="text-[16px] leading-6 ">
              <div className="text-[20px]">{individualInfo?.full_name}</div>
              <div>{individualInfo?.email}</div>
              <div>
                Member Since{" "}
                {individualInfo
                  ? new Date(individualInfo?.date_joined).getFullYear()
                  : null}
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-start gap-3">
            <a href={`mailto: ${individualInfo?.email}`}>
              <button
                type="button"
                className="text-white text-center bg-green gap-3  w-[121px] h-[32px] rounded-full text-sm font-normal leading-5"
              >
                Send Email
              </button>
            </a>

            <Dropdown
              tableComponent={false}
              buttonText=""
              onOpened={(data) => {}}
              items={dropdownItems}
              className="!top-[290px]"
              svg={
                <svg
                  width="44"
                  height="33"
                  viewBox="0 0 44 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.510776 16.045C0.510776 7.48517 7.44992 0.546023 16.0098 0.546023H28.0098C36.5697 0.546023 43.5088 7.48517 43.5088 16.045C43.5088 24.6049 36.5697 31.5441 28.0098 31.5441H16.0098C7.44992 31.5441 0.510776 24.6049 0.510776 16.045Z"
                    stroke="#60849F"
                    stroke-width="1.00196"
                  />
                  <path
                    d="M14.0098 16.045C14.0098 15.495 14.2055 15.0244 14.5968 14.633C14.9888 14.241 15.4598 14.045 16.0098 14.045C16.5598 14.045 17.0308 14.241 17.4228 14.633C17.8141 15.0244 18.0098 15.495 18.0098 16.045C18.0098 16.595 17.8141 17.066 17.4228 17.458C17.0308 17.8494 16.5598 18.045 16.0098 18.045C15.4598 18.045 14.9888 17.8494 14.5968 17.458C14.2055 17.066 14.0098 16.595 14.0098 16.045ZM20.0098 16.045C20.0098 15.495 20.2058 15.0244 20.5978 14.633C20.9891 14.241 21.4598 14.045 22.0098 14.045C22.5598 14.045 23.0308 14.241 23.4228 14.633C23.8141 15.0244 24.0098 15.495 24.0098 16.045C24.0098 16.595 23.8141 17.066 23.4228 17.458C23.0308 17.8494 22.5598 18.045 22.0098 18.045C21.4598 18.045 20.9891 17.8494 20.5978 17.458C20.2058 17.066 20.0098 16.595 20.0098 16.045ZM26.0098 16.045C26.0098 15.495 26.2058 15.0244 26.5978 14.633C26.9891 14.241 27.4598 14.045 28.0098 14.045C28.5598 14.045 29.0305 14.241 29.4218 14.633C29.8138 15.0244 30.0098 15.495 30.0098 16.045C30.0098 16.595 29.8138 17.066 29.4218 17.458C29.0305 17.8494 28.5598 18.045 28.0098 18.045C27.4598 18.045 26.9891 17.8494 26.5978 17.458C26.2058 17.066 26.0098 16.595 26.0098 16.045Z"
                    fill="#60849F"
                  />
                </svg>
              }
            />
          </div>
        </div>
      </div>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDisplayPopupPasswordMember(false);
        }}
        showCross={true}
        popup={displayPopupPasswordMember}
        customClass="max-w-[347px]"
      >
        <div className="text-headerBlue text-[20px] flex items-start">
          Reset Member Password?
        </div>

        <ResetPasswordMember
          memberInfo={individualInfo as any}
          onClose={() => {
            setDisplayPopupPasswordMember(false);
          }}
        />
      </GlimpsePopup>
      <GlimpsePopup
        title=""
        onClose={() => {
          setDeleteAlert(false);
        }}
        showCross={false}
        popup={deleteAlert}
        customClass="max-w-[347px]"
      >
        {individualInfo && (
          <>
            <div className="text-headerBlue text-[20px] flex items-start">
              Delete Member Account
            </div>
            <div>
              If you delete
              <span className="px-1 font-bold">
                {individualInfo.full_name}'s{" "}
              </span>
              membership, their account data will be permanently deleted. This
              action cannot be undone.
            </div>
            <div className="flex justify-between mt-5">
              <button
                type="button"
                onClick={() => setDeleteAlert(false)}
                className="text-green text-center bg-white w-[119px] h-[32px] rounded-full text-sm font-bold leading-5 border border-green"
              >
                Close
              </button>

              <button
                className="text-white text-center bg-red w-[119px] h-[32px] rounded-full text-sm font-normal leading-5"
                onClick={() => {
                  handleDeleteMemberSubmit(individualInfo);
                  setDeleteAlert(false);
                  navigate("/individual");
                }}
              >
                Sure Delete
              </button>
            </div>
          </>
        )}
      </GlimpsePopup>
      <div>
        <Tabs
          items={tabsList}
          onClick={tabChanged}
          activeIndex={activeTab}
          className="flex"
        />
      </div>

      {activeTab === 0 && (
        <div className="flex flex-row h-[190px] max-w-[920px] gap-5 charts flex-wrap lg:flex-nowrap ">
          {individualInsightOverviewData && (
            <>
              <GlimpseCard
                className="w-[180px] h-[160px] lg:w-[217px] lg:h-[190px]"
                title="Top 3"
                titleClass="text-sm font-normal text-left lg:text-center lg:text-xl lg:font-bold text-headerBlue"
              >
                <div className="flex justify-center gap-8 mt-3 sm:gap-2">
                  {individualInsightOverviewData.top_three_careers.map(
                    (item, index) => {
                      return (
                        <div
                          key={index}
                          className="flex flex-col justify-center items-center w-[80px] "
                        >
                          <img
                            className="w-[67px] h-[67px] sm:w-[47px] sm:h-[47px] "
                            src={
                              item.icon_url
                                ? item.icon_url
                                : "/assets/icons/boy_with_glass.svg"
                            }
                            alt={item.name}
                            title={item.name}
                          />
                          <span className="text-center sm:text-sm">
                            {item.name}
                          </span>
                        </div>
                      );
                    }
                  )}
                </div>
              </GlimpseCard>
              <GlimpseCard
                className="w-[180px] h-[160px] lg:w-[217px] lg:h-[190px]"
                title="Video Previews"
                titleClass="text-sm font-normal text-left lg:text-center lg:text-[20px] lg:font-bold text-headerBlue"
              >
                <div className="flex items-center justify-center min-w-[133px] h-[133px]">
                  <DughnutComponent
                    centerPercentage={true}
                    ratioData={previewCenterData}
                    centerValue={`${previewPercentage.toFixed(0)}%`}
                    data={chartDataPreviewProgress}
                  />
                </div>
              </GlimpseCard>
              <GlimpseCard
                className="w-[180px] h-[160px] lg:w-[217px] lg:h-[190px]"
                title="Deeper Dives"
                titleClass="text-sm font-normal text-left lg:text-center lg:text-[20px] lg:font-bold text-headerBlue"
              >
                <div className="flex items-center justify-center min-w-[133px] h-[133px]">
                  <DughnutComponent
                    centerPercentage={true}
                    ratioData={deeperdiveCenterData}
                    centerValue={`${deeperdivePercentage}% `}
                    data={chartDataDeeperDiveProgress}
                  />
                </div>
              </GlimpseCard>
              <GlimpseCard
                className="w-[180px] h-[160px] lg:w-[217px] lg:h-[190px]"
                title="Career Awareness"
                titleClass="text-sm font-normal text-left lg:text-center lg:text-xl lg:font-bold text-headerBlue"
                // childrenClass="min-w-[180px] lg:w-[225px]"
              >
                {/* <div className="flex justify-between"> */}
                {individualInsightOverviewData.badges_earned.map(
                  (item: BadgesCount, index: number) => {
                    return (
                      <div
                        key={index}
                        className="flex flex-col items-center justify-center text-sm text-center text-headerBlue"
                      >
                        <span className="px-4 py-2">
                          <img
                            src={`/assets/icons/${item.slug}_badge.png`}
                            alt="badge"
                            className="w-[70px] h-auto"
                          />
                        </span>
                        <span className="capitalize">
                          {`${item.slug} Level`}
                        </span>
                        <span className="font-bold">{item.count}</span>
                        {/* todo see design */}
                      </div>
                    );
                  }
                )}
                {/* </div> */}
              </GlimpseCard>
            </>
          )}
        </div>
      )}
      {activeTab === 1 && (
        <div className="flex flex-col gap-3 p-3 overflow-auto w-100 ">
          <div className="text-xl font-bold leading-6 text-headerBlue">
            Top 3
            {/* <div className="text-[12px] font-normal text-headerBlue">Top 3</div> */}
          </div>
          <div className="flex flex-wrap items-center justify-start ">
            {individualCareerData &&
              individualCareerData.careers
                ?.filter((x) => x.category === CareerCategory.top_three)
                .map((item: IIndividualInsightCareer, index: number) => {
                  return (
                    <div key={item.id} className="p-2 ">
                      <CareerItem
                        key={index}
                        id={item.id}
                        icon_url={item.icon_url}
                        status={item.status}
                        name={item.name}
                        showRanking={true}
                        rank={index + 1}
                        pressed={() => {
                          if (userInfo.user.role === "partner") {
                            navigate(
                              `/partner-careers/careers/career-insights/${item.career}`
                            );
                          }
                        }}
                      />
                    </div>
                  );
                })}
          </div>
          <div className="text-xl font-bold leading-6 text-headerBlue">
            Top 10
            {/* <div className="text-[12px] font-normal text-headerBlue">
              Top 10
            </div> */}
          </div>
          <div className="flex flex-wrap items-center ">
            {individualCareerData &&
              individualCareerData.careers
                ?.filter((x) => x.category === CareerCategory.watch_list)
                .map((item: IIndividualInsightCareer, topTenIndex: number) => {
                  return (
                    <div key={topTenIndex} className="p-2 ">
                      <CareerItem
                        id={item.id}
                        icon_url={item.icon_url}
                        status={item.status}
                        name={item.name}
                        showRanking={true}
                        rank={
                          topTenIndex +
                          individualCareerData.careers?.filter(
                            (x) => x.category === CareerCategory.top_three
                          ).length +
                          1
                        }
                        pressed={() => {
                          if (userInfo.user.role === "partner") {
                            navigate(
                              `/partner-careers/careers/career-insights/${item.career}`
                            );
                          }
                        }}
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      )}
      {activeTab === 2 && (
        <div className="flex flex-col gap-3 p-3 w-100 ">
          <div className="text-xl font-bold leading-6 text-headerBlue">
            Completed Video Previews
          </div>
          <div className="flex flex-wrap items-center ">
            {individualCareerData &&
              individualCareerData.careers?.map(
                (item: IIndividualInsightCareer) => {
                  return (
                    <div key={item.id} className="p-2 ">
                      <CareerItem
                        // key={item.id}
                        id={item.id}
                        icon_url={item.icon_url}
                        status={item.status}
                        name={item.name}
                        pressed={() => {
                          if (userInfo.user.role === "partner") {
                            navigate(
                              `/partner-careers/careers/career-insights/${item.career}`
                            );
                          }
                        }}
                      />
                    </div>
                  );
                }
              )}
          </div>
        </div>
      )}
      {activeTab === 3 && (
        <div className="flex flex-col gap-3 p-3 overflow-auto w-100 ">
          <div className="text-xl font-bold leading-6 text-headerBlue">
            Completed Deeper Dives
          </div>
          <div className="flex flex-wrap items-center ">
            {individualCareerData &&
              individualCareerData.careers?.map(
                (item: IIndividualInsightCareer) => {
                  return (
                    <div key={item.id} className="p-2 ">
                      <CareerItem
                        id={item.id}
                        icon_url={item.icon_url}
                        status={item.status}
                        name={item.name}
                        pressed={() => {
                          if (userInfo.user.role === "partner") {
                            navigate(
                              `/partner-careers/careers/career-insights/${item.career}`
                            );
                          }
                        }}
                      />
                    </div>
                  );
                }
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default IndividualInsightScreen;
