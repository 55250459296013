import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "../../services/endpoint-urls";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
});
export const apiSlice = createApi({
  baseQuery,
  tagTypes: ["User"],
  endpoints: (builder) => ({}),
});
